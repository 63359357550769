import QuickSightDashboard from '../../components/QuickSightDashboard';
import Heading from '../../components/Heading';

export default function OperationsDashboardPage() {
    return (
        <>
            <Heading>Operations Dashboard</Heading>
            <QuickSightDashboard dashboardId="2c09b926-aaad-4dd9-96a1-36f7e36b5a8f" />
        </>
    );
}
