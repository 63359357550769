import { Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Card, CardContent, CardHeader } from './CampaignWizardStyles';

import { Campaign } from '../../../../types/Campaign';
import { CampaignWizardStepProps } from './CampaignWizard';
import { Table, TablePrimaryCell } from '../../../../components/Table';
import Column from '../../../../components/Column';
import Utils from '../../../../components/Utils';

type CampaignReviewQuickSummaryProps = {
    onChange?: (value: Partial<Campaign>) => void;
} & Omit<CampaignWizardStepProps, 'onChange'>;

export default function CampaignReviewQuickSummary(props: CampaignReviewQuickSummaryProps) {
    const { campaign } = props;

    const locationCount = campaign?.adGroups?.[0]?.adGroupLocations?.length ?? 0;
    const geoLocationCount = campaign?.adGroups?.[0]?.adGroupGeoLocations?.length ?? 0;
    const audienceSourceCount = campaign?.adGroups?.[0]?.adGroupAudienceSources?.length ?? 0;

    return (
        <Card id="campaign-wizard-review-quick-summary" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Campaign Summary
                        </Typography>
                    </Stack>
                }
            />
            <CardContent
                sx={{
                    '&': { p: 0 },
                    '&:last-child': { pb: 0 },
                }}
            >
                <Table>
                    <TableBody>
                        <TableRow>
                            <TablePrimaryCell>Advertiser Name</TablePrimaryCell>
                            <TableCell colSpan={3}>{campaign?.dealer?.dealerName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TablePrimaryCell>Media Type</TablePrimaryCell>
                            <TableCell colSpan={3}>{campaign.mediaType}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TablePrimaryCell>Campaign Strategy</TablePrimaryCell>
                            <TableCell colSpan={3}>{campaign.strategyType}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TablePrimaryCell>Start & End Date</TablePrimaryCell>
                            <TableCell colSpan={3}>{`${Utils.formatESTDateLong(
                                campaign.startDate
                            )} - ${Utils.formatESTDateLong(campaign.endDate)}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TablePrimaryCell>Budget</TablePrimaryCell>
                            <TableCell colSpan={3}>{Utils.formatCurrency(campaign.budget)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TablePrimaryCell>Targeting</TablePrimaryCell>
                            <TableCell>
                                <Column gap={2}>
                                    <Typography variant="body2">
                                        {locationCount > 1 ? `${locationCount} Locations` : `${locationCount} Location`}
                                    </Typography>
                                </Column>
                            </TableCell>
                            <TableCell>
                                <Column gap={2}>
                                    <Typography variant="body2">
                                        {geoLocationCount > 1
                                            ? `${geoLocationCount} Zip Codes`
                                            : `${geoLocationCount} Zip Code`}
                                    </Typography>
                                </Column>
                            </TableCell>
                            <TableCell>
                                <Column gap={2}>
                                    <Typography variant="body2">
                                        {audienceSourceCount > 1
                                            ? `${audienceSourceCount} Audience Categories`
                                            : `${audienceSourceCount} Audience Category`}
                                    </Typography>
                                </Column>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TablePrimaryCell>Creative</TablePrimaryCell>
                            <TableCell colSpan={3}>
                                {campaign.campaignType === 'DCO' ? 'Dynamic' : campaign.campaignType}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
