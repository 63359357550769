import Heading from '../../components/Heading';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import { Box, Button, Chip, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import { User, USER_GROUP_ADMIN } from '../../types/User';
import { GridActionsCellItem, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { AddOutlined, ClearOutlined, LoginOutlined, SearchOutlined } from '@mui/icons-material';
import { UserContext } from '../../App';
import CDGrid from '../../components/CDGrid';

const UserManagement = () => {
    const [users, setUsers] = useState<User[] | null>(null);
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { setUserContext } = useContext(UserContext);

    useEffect(() => {
        setLoading(true);
        ApiService.getUsers()
            .then(function (response) {
                setUsers(response.data);
                setAllUsers(response.data);
                setLoading(false);
            })
            .catch(() => {
                setUsers([]);
            });
    }, []);

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        const filteredRows = allUsers.filter((row: User) => {
            return (
                row.userName.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
                row.emailAddress.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
                row.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
            );
        });
        setUsers(filteredRows);
    }

    function clearSearch() {
        setSearchText('');
        setUsers(allUsers);
    }

    function CustomToolbar() {
        return (
            <Stack direction="row" spacing={2} sx={{ marginBottom: '16px' }}>
                <TextField
                    inputRef={(input) => input && input.focus()}
                    id="searchText"
                    size="small"
                    value={searchText}
                    label="Search"
                    onChange={search}
                    InputProps={{
                        startAdornment: <SearchOutlined fontSize="small" />,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button startIcon={<ClearOutlined fontSize="small" />} onClick={() => clearSearch()} />
                            </InputAdornment>
                        ),
                    }}
                />
                <div style={{ flexGrow: 1 }} />
                <Link to="new">
                    <Button variant="contained" color="primary" startIcon={<AddOutlined />}>
                        Add
                    </Button>
                </Link>
            </Stack>
        );
    }

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                '& .row': {
                    cursor: 'pointer',
                },
            }}
        >
            <Heading>User Management</Heading>
            <CustomToolbar />
            <CDGrid
                columns={[
                    {
                        width: 110,
                        field: 'id',
                        type: 'actions',
                        headerName: 'Login',
                        headerClassName: 'grid-header',
                        getActions: (params: GridRowParams) => {
                            if (params.row.group === USER_GROUP_ADMIN) {
                                return [];
                            } else {
                                return [
                                    <GridActionsCellItem
                                        icon={
                                            <Tooltip title="Login as this user">
                                                <LoginOutlined color="info" />
                                            </Tooltip>
                                        }
                                        label=""
                                        onClick={() =>
                                            setUserContext(
                                                params.row.id,
                                                params.row.userName,
                                                params.row.name,
                                                params.row.group,
                                                params.row.agencyId,
                                                params.row.agency?.name,
                                                params.row.agency
                                            )
                                        }
                                        showInMenu={undefined}
                                    />,
                                ];
                            }
                        },
                    },
                    {
                        width: 200,
                        field: 'name',
                        headerName: 'Name',
                        headerClassName: 'grid-header',
                        renderCell: (params) => {
                            return params.row.readOnly ? params.value : <b>{params.value}</b>;
                        },
                    },
                    {
                        width: 160,
                        field: 'userName',
                        headerName: 'Username',
                        headerClassName: 'grid-header',
                        renderCell: (params) => {
                            return <Link to={`/useredit/${params.row.id}`}>{params.value}</Link>;
                        },
                    },
                    {
                        width: 150,
                        field: 'group',
                        headerName: 'User Type',
                        headerClassName: 'grid-header',
                        align: 'center',
                        headerAlign: 'center',
                        valueFormatter: (params: GridValueFormatterParams) => {
                            let value = params.value as string;
                            if (value) {
                                value = value.replace('DEALER', 'advertiser');
                                value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
                            }
                            return value;
                        },
                        renderCell: (params) => {
                            let value = params.value as string;
                            if (value) {
                                value = value.replace('DEALER', 'advertiser');
                                value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
                            } else {
                                value = 'Unknown';
                            }
                            return (
                                <Chip
                                    label={value}
                                    size="small"
                                    variant={value === 'Admin' ? 'filled' : 'outlined'}
                                    color={value === 'Admin' ? 'success' : 'info'}
                                />
                            );
                        },
                    },
                    {
                        width: 200,
                        field: 'emailAddress',
                        headerName: 'Email',
                        headerClassName: 'grid-header',
                        flex: 1,
                    },
                ]}
                loading={loading}
                autoHeight={false}
                autoPageSize={true}
                onRowClick={(params) => {
                    navigate('' + params.row.id);
                }}
                rows={users === null ? [] : users}
            />
        </Box>
    );
};

export default UserManagement;
