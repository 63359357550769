import { Badge, BadgeProps, Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import Heading from '../../../components/Heading';
import { AmcInstance } from '../../../types/AmcInstance';
import ApiService from '../../../ApiService';
import TabPanel from '../../../components/TabPanel';
import { Dealer } from '../../../types/Dealer';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import Utils from '../../../components/Utils';
import AmcInstanceForm from './AmcInstanceForm';
import AmcAudienceTab from './AmcAudienceTab';
import AmcDataSetsTab from './AmcDataSetsTab';

export default function AmcInstanceEditPage() {
    const location = useLocation();
    const [amcInstance, setAmcInstance] = useState<AmcInstance>(location.state);
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        setAmcInstance(location.state);
    }, [location.state]);

    useEffect(() => {
        if (amcInstance.id) {
            ApiService.getDealersForAMCInstance(amcInstance.id).then((response) => {
                setDealers(response.data);
            });
        }
    }, [amcInstance.id]);

    function selectTab(_event: SyntheticEvent, value: any) {
        setSelectedTab(value);
    }

    const StyledBadge = styled(Badge)<BadgeProps>(() => ({
        '& .MuiBadge-badge': {
            right: -5,
        },
    }));

    return (
        <>
            <Heading>{Utils.includeSeparator('AMC Instance', ':', amcInstance.name)}</Heading>

            {amcInstance.id && (
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={selectTab}>
                        <Tab label="Instance" />
                        <Tab
                            label={
                                <StyledBadge color="info" badgeContent={dealers.length}>
                                    Advertisers&nbsp;
                                </StyledBadge>
                            }
                        />
                        <Tab label="Audiences" />
                        <Tab label="Data Sets" />
                    </Tabs>
                </Box>
            )}

            <TabPanel value={selectedTab} index={0}>
                <AmcInstanceForm size="md" amcInstance={amcInstance} onSave={(_amcInstance: AmcInstance) => {}} />
            </TabPanel>

            <TabPanel value={selectedTab} index={1}>
                <Box style={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        autoHeight
                        columns={[
                            {
                                width: 180,
                                field: 'dspAdvertiser',
                                headerName: 'DSP Advertiser ID',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 160,
                                field: 'dealerName',
                                headerName: 'Advertiser Name',
                                headerClassName: 'grid-header',
                                flex: 1,
                            },
                        ]}
                        rows={dealers === null ? [] : dealers}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Box>
            </TabPanel>

            <TabPanel value={selectedTab} index={2}>
                <AmcAudienceTab amcInstanceId={amcInstance.id} />
            </TabPanel>

            <TabPanel value={selectedTab} index={3}>
                <AmcDataSetsTab amcInstanceId={amcInstance.id} />
            </TabPanel>

            <Box sx={{ minHeight: '100%', p: 1 }}></Box>
        </>
    );
}
