import { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';

// import ApiService from '../../ApiService';
import Column from '../../components/Column';
import Row from '../../components/Row';
import { Dealer } from '../../types/Dealer';
import { PrintContext } from '../../hooks/usePrint';
import { ReportingDashboardPageContext } from '../../hooks/useReportingDashboardPage';
import { SectionHeading } from '../../components/SectionHeading';
import usePrint from '../../hooks/usePrint';
import useReportingDashboardPage from '../../hooks/useReportingDashboardPage';
import ReportingCriteriaForm from './partials/Reporting/ReportingCriteriaForm';
import ReportingMediaTabs, { TABS as MEDIA_TABS } from './partials/Reporting/Tabs/ReportingMediaTabs';
import ReportingPerformanceTabPanels from './partials/Reporting/Tabs/ReportingPerformanceTabPanels';

import ReportingAdvertiserCountLabel from './partials/Reporting/ReportingAdvertiserCountLabel';
import ReportingCampaignStrategyTypeSelect from './partials/Reporting/ReportingCampaignStrategyTypeSelect';
import ReportingPerformanceExportMenuButton from './partials/Reporting/ReportingPerformanceExportMenuButton';
import ReportingPerformanceMetrics from './partials/Reporting/ReportingPerformanceMetrics';

export default function ReportingDashboardPage() {
    const print = usePrint();
    const reportingDashboardPage = useReportingDashboardPage();
    const {
        isFetchingPerformances,
        fetchPerformances,
        performances,
        reportingCriteria,
        setReportingCriteria,
        mediaTabs,
        activeStrategyTypes,
    } = reportingDashboardPage;

    // const [reportingCriteria, setReportingCriteria] = useState<ReportingCriteria>({
    //     agencyIds: [],
    //     dealerIds: [],
    //     startDate: new Date(),
    //     endDate: new Date(),
    //     mediaType: '',
    //     strategyType: '',
    // });
    const [isFetchingDealers, setIsFetchingDealers] = useState<boolean>(false);
    const [activeAdvertiserCount, setActiveAdvertiserCount] = useState<number>(0);
    const [totalAdvertiserCount, setTotalAdvertiserCount] = useState<number>(0);
    const [totalActiveAdvertiserCount, setTotalActiveAdvertiserCount] = useState<number>(0);

    const handleChangeMediaTab = useCallback(
        (event: SyntheticEvent, newActiveTab: number) => {
            mediaTabs.handleChangeTab(event, newActiveTab);

            let mediaType: string = '';

            switch (newActiveTab) {
                case MEDIA_TABS.ALL:
                    mediaType = '';
                    break;
                case MEDIA_TABS.DISPLAY:
                    mediaType = 'Display';
                    break;
                case MEDIA_TABS.VIDEO:
                    mediaType = 'Video';
                    break;
                case MEDIA_TABS.AUDIO:
                    mediaType = 'Audio';
                    break;
                case MEDIA_TABS.ONLINE_VIDEO:
                    mediaType = 'Online Video';
                    break;
                case MEDIA_TABS.STREAMING_TV:
                    mediaType = 'Streaming TV';
                    break;
            }

            setReportingCriteria({
                ...reportingCriteria,
                mediaType: mediaType,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mediaTabs]
    );

    const isFetchingAdvertiserCount = useMemo(() => {
        return isFetchingPerformances || isFetchingDealers;
    }, [isFetchingPerformances, isFetchingDealers]);

    useEffect(() => {
        fetchPerformances();
    }, [fetchPerformances]);

    const setupAdvertiserCounts = useCallback(() => {
        const distinctDealerIds = [...new Set(performances.map((performance: any) => performance.dealerId))];
        setActiveAdvertiserCount(distinctDealerIds.length);

        setTotalAdvertiserCount(totalActiveAdvertiserCount);

        if (reportingCriteria.dealerIds.length > 0) {
            setTotalAdvertiserCount(reportingCriteria.dealerIds.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingCriteria.dealerIds, performances, totalActiveAdvertiserCount]);

    useEffect(() => {
        setupAdvertiserCounts();
    }, [setupAdvertiserCounts]);

    return (
        <PrintContext.Provider value={print}>
            <ReportingDashboardPageContext.Provider value={reportingDashboardPage}>
                <Column gap={2}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Row spacing={2} alignItems="center">
                            <SectionHeading subheading={''} divider={false}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h6">Reporting</Typography>
                                    <Chip label="Beta" size="small" />
                                </Stack>
                            </SectionHeading>

                            <Box sx={{ width: 960 }}>
                                <ReportingCriteriaForm
                                    reportingCriteria={reportingCriteria}
                                    onChangeReportingCriteria={setReportingCriteria}
                                    onFetchingDealers={() => {
                                        setIsFetchingDealers(true);
                                    }}
                                    onFetchDealers={(dealers: Dealer[]): void => {
                                        setIsFetchingDealers(false);

                                        setTotalActiveAdvertiserCount(
                                            dealers.filter((dealer: { activeStatus: boolean }) => dealer.activeStatus)
                                                .length
                                        );
                                    }}
                                />
                            </Box>
                        </Row>

                        <ReportingPerformanceExportMenuButton disabled={false} />
                    </Row>

                    <Column>
                        <ReportingMediaTabs value={mediaTabs.activeTab} onChange={handleChangeMediaTab} />

                        <Row justifyContent="space-between">
                            <Box>
                                {Object.keys(activeStrategyTypes).length > 0 && (
                                    <ReportingCampaignStrategyTypeSelect
                                        strategyTypes={activeStrategyTypes}
                                        reportingCriteria={reportingCriteria}
                                        onChangeReportingCriteria={setReportingCriteria}
                                    />
                                )}
                            </Box>

                            <ReportingAdvertiserCountLabel
                                loading={isFetchingAdvertiserCount}
                                activeAdvertiserCount={activeAdvertiserCount}
                                totalAdvertiserCount={totalAdvertiserCount}
                            />
                        </Row>
                    </Column>

                    <Column>
                        <Box>
                            <ReportingPerformanceMetrics />
                        </Box>
                    </Column>

                    <Column>
                        <ReportingPerformanceTabPanels />
                    </Column>
                </Column>
            </ReportingDashboardPageContext.Provider>
        </PrintContext.Provider>
    );
}
