import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import Heading from '../../components/Heading';
import { Button, Grid, TextField } from '@mui/material';
import { CloseOutlined, DeleteOutlined, NewspaperOutlined, PublishOutlined, SaveOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import { ReleaseNote } from '../../types/ReleaseNote';
import { InfoMessageContext } from '../../components/GlobalInfoMessage';
import { ReleaseNotesEditor } from './ReleaseNotesEditor';
import { ReleaseNotesDialog } from './ReleaseNotesDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CDPageToolbar from '../../components/CDPageToolbar';

export function ReleaseNoteEditorPage() {
    const navigate = useNavigate();
    const { showMessage } = useContext(InfoMessageContext);

    const { id } = useParams();
    const [releaseNote, setReleaseNote] = useState<ReleaseNote>(new ReleaseNote());
    const descriptionRef = useRef<HTMLInputElement>(null);
    const appVersionRef = useRef<HTMLInputElement>(null);
    const [currentVersion, setCurrentVersion] = useState<string>('');
    const [loaded, setLoaded] = useState(false);
    const [content, setContent] = useState<string>('');
    const [preview, setPreview] = useState(false);
    const [isReleaseDisabled, setIsReleaseDisabled] = useState(true);

    useEffect(() => {
        let idValue = Number(id);
        if (!isNaN(idValue)) {
            ApiService.getReleaseNote(idValue).then((response) => {
                setReleaseNote(response.data);
                setContent(response.data.content);
                setLoaded(true);
            });
        } else {
            setLoaded(true);
        }
        ApiService.getUiBuildConfig().then((response) => {
            setCurrentVersion(response.data.appVersion);
        });
    }, [id]);

    useEffect(() => {
        checkDisable();
    }, [loaded, content]);

    const checkDisable = () => {
        const description = descriptionRef?.current?.value.trim();
        const appVersion = appVersionRef?.current?.value.trim();
        const isContentInValid = content?.trim() === '<p><br></p>' || content?.trim() === '';

        if (description && appVersion && !isContentInValid) {
            setIsReleaseDisabled(false);
        } else {
            setIsReleaseDisabled(true);
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        ApiService.updateReleaseNote(getReleaseNote())
            .then((response) => {
                setReleaseNote(response?.data);
                showMessage('success', 'Release Note saved successfully.');
            })
            .catch(() => {});
    };

    const getReleaseNote = () => {
        let description = descriptionRef?.current ? descriptionRef.current.value : '';
        let appVersion = appVersionRef?.current ? appVersionRef.current.value : '';
        return {
            ...releaseNote,
            content: content,
            description: description,
            appVersion: appVersion,
        };
    };

    function handlePreview() {
        setReleaseNote(getReleaseNote());
        setPreview(!preview);
    }

    function handleDelete() {
        if (releaseNote.id) {
            ApiService.deleteReleaseNote(releaseNote.id).then(() => {
                showMessage('success', 'Release Note deleted successfully.');
                setTimeout(() => {
                    navigate(-1);
                }, 500);
            });
        }
    }

    function handlePublish() {
        let releaseNote = getReleaseNote();
        releaseNote.published = true;
        releaseNote.releaseDate = new Date();
        ApiService.updateReleaseNote(releaseNote)
            .then((response) => {
                setReleaseNote(response.data);
                showMessage('success', 'Release Note released successfully.');
            })
            .catch(() => {});
    }

    const updateContent = (newContent: string) => {
        setContent(newContent);
        checkDisable(); // Ensure the release button is enabled/disabled correctly
    };

    return (
        <>
            <Heading>Release Note Editor</Heading>
            <form onSubmit={handleSubmit}>
                <div className="editor" style={{ width: '100%' }}>
                    {loaded && (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <TextField
                                        sx={{ paddingBottom: '10px' }}
                                        name="description"
                                        label="Description"
                                        inputRef={descriptionRef}
                                        required={true}
                                        defaultValue={releaseNote.description}
                                        fullWidth={true}
                                        size="small"
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={() => checkDisable()} // Check if release button should be disabled whenever description changes
                                    />
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Release Date"
                                            disablePast={true}
                                            value={releaseNote.releaseDate}
                                            onChange={(newValue) => {
                                                if (newValue !== null) {
                                                    setReleaseNote((prev) => ({
                                                        ...prev,
                                                        releaseDate: newValue,
                                                    }));
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField variant="filled" size="small" {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        sx={{ paddingBottom: '10px' }}
                                        label="App Version"
                                        inputRef={appVersionRef}
                                        required={true}
                                        defaultValue={releaseNote.appVersion}
                                        fullWidth={true}
                                        size="small"
                                        variant="filled"
                                        helperText={'Current Version: ' + currentVersion}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={() => checkDisable()} // Check if release button should be disabled whenever app version changes
                                    />
                                </Grid>
                            </Grid>
                            <ReleaseNotesEditor content={content} updateContent={updateContent} />
                        </>
                    )}
                    {preview && <ReleaseNotesDialog onClose={() => setPreview(false)} releaseNote={releaseNote} />}
                </div>

                <CDPageToolbar>
                    <Button color="primary" variant="contained" name="save" type="submit" startIcon={<SaveOutlined />}>
                        Save
                    </Button>

                    <Button variant="outlined" color="error" startIcon={<CloseOutlined />} onClick={() => navigate(-1)}>
                        Cancel
                    </Button>

                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteOutlined />}
                        disabled={!releaseNote.id}
                        onClick={() => handleDelete()}
                    >
                        Delete
                    </Button>

                    <div style={{ flex: 1 }} />
                    <Button
                        sx={{ marginTop: '5px', marginBottom: '10px' }}
                        color="primary"
                        onClick={handlePreview}
                        variant="outlined"
                        startIcon={<NewspaperOutlined />}
                    >
                        Preview
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PublishOutlined />}
                        disabled={isReleaseDisabled || releaseNote?.published}
                        onClick={handlePublish}
                    >
                        Release
                    </Button>
                </CDPageToolbar>
            </form>
        </>
    );
}
