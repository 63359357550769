import { Skeleton } from '@mui/material';

import Column from '../../components/Column';
import Row from '../../components/Row';

export default function SkeletonGroupedBarChartCard() {
    return (
        <Row>
            <Column sx={{ flexGrow: 1, px: 3, pt: 0, pb: 6 }}>
                <Row gap={3} alignItems="center" justifyContent="center">
                    {Array.from({ length: 4 }).map((_, index) => (
                        <Row gap={1} sx={{ width: 'auto' }}>
                            <Skeleton variant="rectangular" width={15} height={15} />

                            <Skeleton width={60} height={15} />
                        </Row>
                    ))}
                </Row>

                <Column gap={1}>
                    <Column
                        gap={4}
                        alignItems="flex-start"
                        justifyContent="center"
                        sx={{
                            borderRightWidth: 0,
                            borderTopWidth: 0,
                            borderLeftWidth: 1,
                            borderBottomWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'divider',

                            py: 3,
                        }}
                    >
                        <Column gap={1}>
                            <Skeleton variant="rectangular" width={600} height={30} />

                            <Skeleton variant="rectangular" width={450} height={30} />

                            <Skeleton variant="rectangular" width={300} height={30} />
                        </Column>

                        <Column gap={1}>
                            <Skeleton variant="rectangular" width={600} height={30} />

                            <Skeleton variant="rectangular" width={450} height={30} />

                            <Skeleton variant="rectangular" width={300} height={30} />
                        </Column>

                        <Column gap={1}>
                            <Skeleton variant="rectangular" width={600} height={30} />

                            <Skeleton variant="rectangular" width={450} height={30} />

                            <Skeleton variant="rectangular" width={300} height={30} />
                        </Column>
                    </Column>

                    <Row justifyContent="space-between">
                        {Array.from({ length: 12 }).map((_, index) => (
                            <Skeleton key={index} width={60} height={20} />
                        ))}
                    </Row>
                </Column>
            </Column>
        </Row>
    );
}
