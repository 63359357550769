import { styled } from '@mui/styles';
import { Card } from '@mui/material';

export const CampaignCard = styled(Card)(() => ({
    '& .MuiCardHeader-root': {
        paddingLeft: 24,
        paddingTop: 24,
        paddingBottom: 0,
    },
    '& .MuiCardContent-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
}));
