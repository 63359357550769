import React, { useCallback, useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import apiService from '../../ApiService';
import {
    DataGrid,
    gridClasses,
    GridFilterItem,
    GridToolbarContainer,
    GridToolbarExport,
} from '@mui/x-data-grid';
import {
    AlertColor,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    InputLabel,
    Stack,
    Tooltip,
} from '@mui/material';
import Heading from '../../components/Heading';
import { TradeInOffer } from '../../types/TradeInOffer';
import { AxiosResponse } from 'axios';
import {
    CancelOutlined,
    CheckOutlined,
    CloudUploadOutlined,
    DirectionsRunOutlined,
    ImageOutlined,
    RequestQuoteOutlined,
} from '@mui/icons-material';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import { Dealer } from '../../types/Dealer';
import { OfferSummaryDTO } from '../../types/OfferSummaryDTO';
import { useParams } from 'react-router-dom';
import Utils from '../../components/Utils';

export default function OfferListPage() {
    const params = useParams();
    const dealerId = params.dealerId ? +params.dealerId : 0;
    const [dealer, setDealer] = useState<Dealer>();
    const [offers, setOffers] = useState<TradeInOffer[] | null>(null);
    const [offerSummary, setOfferSummary] = useState<OfferSummaryDTO | null>(
        null
    );
    const [filter, setFilter] = useState('All');
    const [filterItems, setFilterItems] = useState<GridFilterItem[]>([]);
    const [uploading, setUploading] = useState(false);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function changeFilter(event: React.MouseEvent<HTMLButtonElement>) {
        const button: HTMLButtonElement = event.currentTarget;
        setFilterValue(button.value);
    }

    function setFilterValue(filterName: string) {
        if (filterName === 'All') {
            setFilterItems([
                { columnField: 'tpStatus', operatorValue: 'isNotEmpty' },
            ]);
        } else if (filterName === 'Mismatch') {
            setFilterItems([
                {
                    columnField: 'tpStatus',
                    operatorValue: 'equals',
                    value: 'Mismatch',
                },
            ]);
        }
        if (filterName === 'NoMatch') {
            setFilterItems([
                {
                    columnField: 'tpStatus',
                    operatorValue: 'equals',
                    value: 'No Match',
                },
            ]);
        }
        setFilter(filterName);
    }

    const getOffers = useCallback(() => {
        if (dealerId) {
            ApiService.getOffers(dealerId)
                .then(function (response) {
                    setDealer(response.data.dealer);
                    setOffers(response.data.offers);
                })
                .catch((error) => {
                    showInfoMessage('error', error.message);
                    setOffers([]);
                });
        }
    }, [dealerId]);

    const getOfferSummary = useCallback(() => {
        if (dealerId) {
            ApiService.getOfferSummary(dealerId)
                .then((response) => {
                    setOfferSummary(response.data);
                })
                .catch((error) => {
                    showInfoMessage('error', error.message);
                });
        }
    }, [dealerId]);

    useEffect(() => {
        if (dealerId) {
            getOffers();
            getOfferSummary();
        }
    }, [dealerId, getOffers, getOfferSummary]);

    function onFileChange(event: any) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);
        setUploading(true);
        ApiService.uploadOffers(dealerId, formData)
            .then(function (response: AxiosResponse) {
                setUploading(false);
                getOffers();
                setFilterValue('All');
                showInfoMessage('success', response.data);
                getOfferSummary();
            })
            .catch(function (error) {
                setUploading(false);
                showInfoMessage('error', error.message);
            });
    }

    function toggleSelection(id: number | string) {
        let offer = offers?.find((offer) => offer.id === id);
        if (offer) {
            offer.tpStatus = 'Match';
            apiService.updateOffer(offer);
        }
        setOffers(offers);
        setFilterValue(filter);
    }

    function deleteSelection(id: number | string) {
        let offer = offers?.find((offer) => offer.id === id);
        if (offer) {
            offer.tpStatus = '';
            apiService.deleteOffer(dealerId, offer).then(() => {
                getOfferSummary();
            });
        }
        setOffers(offers);
        setFilterValue(filter);
    }

    function runValuationReport() {
        setUploading(true);
        apiService
            .runValuationReport('new')
            .then((response) => {
                setUploading(false);
                showInfoMessage(
                    'success',
                    'Valuation reports updated for ' +
                        response.data.totalCount +
                        ' Offers'
                );
                getOfferSummary();
                getOffers();
            })
            .catch((error) => {
                setUploading(false);
                showInfoMessage('error', error.response.data);
            });
    }

    function runOfferExport() {
        setUploading(true);
        apiService
            .runOfferExport()
            .then(() => {
                setUploading(false);
                showInfoMessage(
                    'success',
                    'Offer Valuations exported to S3 & Amazon Ad Suite'
                );
            })
            .catch((error) => {
                setUploading(false);
                showInfoMessage('error', error.response.data);
            });
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <Stack direction="row">
                    <label
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        Status Filter :
                    </label>
                    <div style={{ width: '15px' }} />
                    <Tooltip title="Display all Offers" placement="top">
                        <Button
                            variant={filter === 'All' ? 'contained' : 'text'}
                            value="All"
                            onClick={changeFilter}
                        >
                            All
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title="Display Offers with a different Model"
                        placement="top"
                    >
                        <Button
                            variant={
                                filter === 'Mismatch' ? 'contained' : 'text'
                            }
                            value="Mismatch"
                            onClick={changeFilter}
                        >
                            Mismatch
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title="Display Offers that did not have a match"
                        placement="top"
                    >
                        <Button
                            variant={
                                filter === 'NoMatch' ? 'contained' : 'text'
                            }
                            value="NoMatch"
                            onClick={changeFilter}
                        >
                            No Match
                        </Button>
                    </Tooltip>
                </Stack>
                <div style={{ flexGrow: 1 }} />
                <Tooltip
                    title="Runs the Valuation Report for New Offers"
                    placement="top"
                >
                    <Button
                        variant="text"
                        component="span"
                        color="success"
                        onClick={runValuationReport}
                        startIcon={<RequestQuoteOutlined />}
                    >
                        Valuation Report
                    </Button>
                </Tooltip>
                <Tooltip
                    title="Export Valuation Reports to Amazon Ad Suite"
                    placement="top"
                >
                    <Button
                        variant="text"
                        component="span"
                        color="success"
                        onClick={runOfferExport}
                        startIcon={<DirectionsRunOutlined />}
                    >
                        Export Valuations
                    </Button>
                </Tooltip>

                <InputLabel htmlFor="btn-uploadRequest">
                    <input
                        id="btn-uploadRequest"
                        name="btn-uploadRequest"
                        style={{ display: 'none' }}
                        type="file"
                        accept=".xlsx"
                        onChange={onFileChange}
                    />
                    <Tooltip
                        title="Upload Offers from a Spreadsheet"
                        placement="top"
                    >
                        <Button
                            variant="text"
                            component="span"
                            startIcon={<CloudUploadOutlined />}
                        >
                            Upload
                        </Button>
                    </Tooltip>
                </InputLabel>
                <Tooltip title="Export Offers as a CSV" placement="top">
                    <GridToolbarExport
                        csvOptions={{ fileName: 'TradeInOffers' }}
                    />
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    function numFormatter(num: number): string {
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(0) + ''; // convert to K for number from > 1000 < 1 million
        } else {
            return '' + num;
        }
    }

    let uploadMessage;
    if (uploading) {
        uploadMessage = (
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
            >
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </Backdrop>
        );
    }

    return (
        <Box>
            <Heading>
                {Utils.includeSeparator(
                    'Trade-In Offers',
                    ':',
                    dealer?.dealerName
                )}
            </Heading>
            {uploadMessage}
            <InfoMessage {...infoMessage} />

            <div style={{ height: '100%', width: '100%' }}>
                {offerSummary && (
                    <div style={{ height: '40px' }}>
                        {offerSummary.totalOfferCount} Offers /{' '}
                        {offerSummary.valuationReportCount} Valuation Reports
                    </div>
                )}
                <DataGrid
                    autoHeight
                    components={{ Toolbar: CustomToolbar }}
                    columns={[
                        {
                            width: 90,
                            field: 'tpStatus',
                            headerName: 'Status',
                            filterable: false,
                        },
                        {
                            width: 80,
                            field: 'offerImageUrl',
                            headerName: 'Image',
                            filterable: false,
                            renderCell: (params) => {
                                return params.value ? <ImageOutlined /> : '';
                            },
                        },
                        {
                            width: 220,
                            field: 'year',
                            headerName: 'Dealer Offer',
                            valueGetter: (params) => {
                                return (
                                    params.row.year +
                                    ' ' +
                                    params.row.make +
                                    ' ' +
                                    params.row.model +
                                    ' ' +
                                    (params.row.trim ? params.row.trim : '')
                                );
                            },
                        },
                        {
                            width: 400,
                            field: 'tpVehicle',
                            headerName: 'Trade Pending Make & Model',
                            description: 'Trade Pending Make & Model',
                            flex: 1,
                            renderCell: (params) => (
                                <Tooltip title={params.value}>
                                    <span>{params.value}</span>
                                </Tooltip>
                            ),
                        },
                        {
                            width: 100,
                            field: 'minimumTradeValue',
                            headerName: 'Value',
                            renderCell: (params) => {
                                if (params.value === 0) {
                                    return 0;
                                } else {
                                    const value = params.value.split('-');
                                    return (
                                        <Tooltip
                                            title={value[0] + '-' + value[1]}
                                        >
                                            <span>
                                                {numFormatter(+value[0]) +
                                                    '-' +
                                                    numFormatter(+value[1])}
                                                k
                                            </span>
                                        </Tooltip>
                                    );
                                }
                            },
                            valueGetter: (params) => {
                                return params.row.minimumTradeValue > 0
                                    ? params.row.minimumTradeValue +
                                          '-' +
                                          params.row.maximumTradeValue
                                    : 0;
                            },
                        },
                        {
                            width: 100,
                            field: 'id',
                            headerName: 'Actions',
                            sortable: false,
                            filterable: false,
                            disableExport: true,
                            renderCell: (params) => {
                                return (
                                    <>
                                        <Tooltip
                                            title="Remove Offer for this Dealer"
                                            placement="top"
                                        >
                                            <Button
                                                color="error"
                                                startIcon={<CancelOutlined />}
                                                onClick={() =>
                                                    deleteSelection(
                                                        params.row.id
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                        {params.row.tpStatus === 'Mismatch' && (
                                            <Tooltip
                                                title="Mark as Match (All Dealers)"
                                                placement="top"
                                            >
                                                <Button
                                                    color="success"
                                                    startIcon={
                                                        <CheckOutlined />
                                                    }
                                                    onClick={() =>
                                                        toggleSelection(
                                                            params.row.id
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    </>
                                );
                            },
                        },
                    ]}
                    disableSelectionOnClick={true}
                    filterModel={{ items: filterItems }}
                    rows={offers === null ? [] : offers}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                />
            </div>
        </Box>
    );
}
