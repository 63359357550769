import { ChangeEvent, useEffect, useState } from 'react';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { DateRangeOutlined } from '@mui/icons-material';
import { CampaignCriteria } from '../../types/Campaign';
import theme from '../../theme';
import moment from 'moment';

export interface CampaignMonth {
    id: string;
    label: string;
    startDate: Date;
    endDate: Date;
}

export default function CampaignMonthSelect(props: {
    lastNthMonths?: Number;
    campaignCriteria: CampaignCriteria;
    changeSearchCriteria: Function;
}) {
    const { lastNthMonths, campaignCriteria, changeSearchCriteria } = props;
    const [campaignMonth, setCampaignMonth] = useState<string>(moment().format('YYYY-MM'));
    const campaignMonths: CampaignMonth[] = generateCampaignMonths(lastNthMonths);

    useEffect(() => {
        setCampaignMonth(moment(campaignCriteria.startDate).format('YYYY-MM'));
    }, [campaignCriteria]);

    function generateCampaignMonths(lastNthMonths: Number = 6) {
        const months: CampaignMonth[] = [];
        const currentMonth = moment().startOf('month');

        months.push(
            ...[
                {
                    id: moment(currentMonth).add(1, 'months').format('YYYY-MM'),
                    label: moment(currentMonth).add(1, 'months').format('MMMM YYYY'),
                    startDate: moment(currentMonth).add(1, 'months').startOf('month').toDate(),
                    endDate: moment(currentMonth).add(1, 'months').endOf('month').startOf('day').toDate(),
                },
                {
                    id: moment(currentMonth).format('YYYY-MM'),
                    label: moment(currentMonth).format('MMMM YYYY'),
                    startDate: moment(currentMonth).startOf('month').toDate(),
                    endDate: moment(currentMonth).endOf('month').startOf('day').toDate(),
                },
            ]
        );

        for (let nthMonth = 1; nthMonth <= lastNthMonths; nthMonth++) {
            const _nthMonth = moment(currentMonth).subtract(nthMonth, 'months').startOf('month');

            months.push({
                id: _nthMonth.format('YYYY-MM'),
                label: _nthMonth.format('MMMM YYYY'),
                startDate: _nthMonth.startOf('month').toDate(),
                endDate: _nthMonth.endOf('month').startOf('day').toDate(),
            });
        }

        return months;
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const selectedCampaignMonthId = event.target.value as string;
        const selectedCampaignMonth = campaignMonths.find((month) => month.id === selectedCampaignMonthId);

        if (selectedCampaignMonth && selectedCampaignMonthId !== campaignMonth) {
            setCampaignMonth(selectedCampaignMonthId);

            changeSearchCriteria({
                ...campaignCriteria,
                startDate: selectedCampaignMonth.startDate,
                endDate: selectedCampaignMonth.endDate,
            });
        }
    }

    return (
        <TextField
            select
            value={campaignMonth}
            sx={{
                height: 40,
                width: 240,
                fontSize: theme.typography.fontSize,
                '& .MuiInputBase-input': {
                    color: theme.palette.text.secondary,
                    lineHeight: '1.64375em',
                    fontSize: theme.typography.fontSize,
                },
            }}
            label="Campaign Month"
            placeholder="Campaign Month"
            size="small"
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <DateRangeOutlined fontSize="small" color="action" />
                    </InputAdornment>
                ),
            }}
        >
            {campaignMonths.map((_campaignMonth) => {
                return (
                    <MenuItem key={_campaignMonth.id} value={_campaignMonth.id}>
                        {_campaignMonth.label}
                    </MenuItem>
                );
            })}
        </TextField>
    );
}
