import { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';

import { Campaign } from '../../../types/Campaign';
import { CampaignDuplicateValidatorState } from '../../../hooks/useCampaign';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';
import ApiService from '../../../ApiService';
import CampaignDuplicateDialog from './CampaignDuplicateDialog';
import CampaignSummaryDialog from './CampaignSummaryDialog';
import Utils from '../../../components/Utils';

type CampaignDuplicateValidatorProps = {
    campaignDuplicateValidator: CampaignDuplicateValidatorState;
    campaign: Campaign;
    ignoreDuplicates?: boolean;
    onIgnoreDuplicates?: () => void;
};

export default function CampaignDuplicateValidator(props: CampaignDuplicateValidatorProps) {
    const { campaignDuplicateValidator, campaign, ignoreDuplicates } = props;
    const { duplicateCampaigns, setDuplicateCampaigns, setIsFetchingDuplicateCampaigns } = campaignDuplicateValidator;

    // // Audience Sources
    // const { $audienceSources } = useCampaignWizardContext();
    // const { fetchAudienceSources = undefined } = $audienceSources || {};

    // // Locations
    const { $locations } = useCampaignWizardContext();
    const { fetchLocations = undefined, isFetchingLocations = false } = $locations || {};

    const [duplicateCampaignDialogOpen, setDuplicateCampaignDialogOpen] = useState<boolean>(false);

    const [viewCampaign, setViewCampaign] = useState<Campaign | null>(null);
    const [viewCampaignDialogOpen, setViewCampaignDialogOpen] = useState<boolean>(false);

    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

    const handleDuplicateCampaignDialogClose = () => {
        setDuplicateCampaignDialogOpen(false);
    };

    const handleDuplicateCampaignDialogCancel = () => {
        setDuplicateCampaignDialogOpen(false);
    };

    const handleDuplicateCampaignDialogConfirm = () => {
        setDuplicateCampaignDialogOpen(false);

        if (props.onIgnoreDuplicates) {
            props.onIgnoreDuplicates();
        }
    };

    const handleDuplicateCampaignDialogView = (campaign: Campaign) => {
        setViewCampaignDialogOpen(true);
        setViewCampaign(campaign);
    };

    const handleViewCampaignDialogClose = () => {
        setViewCampaignDialogOpen(false);
        setViewCampaign(null);
    };

    // useEffect(() => {
    //     if (fetchAudienceSources) {
    //         fetchAudienceSources();
    //     }
    // }, [fetchAudienceSources]);

    useEffect(() => {
        if (fetchLocations && duplicateCampaignDialogOpen) {
            fetchLocations();
        }
    }, [fetchLocations, duplicateCampaignDialogOpen]);

    useEffect(() => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Operation canceled due to new request.');
        }

        const newCancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        setCancelTokenSource(newCancelTokenSource);

        // Only check for duplicates when creating a new Campaign
        if (!ignoreDuplicates && !campaign.id && !campaign.campaignId && campaign.budget > 0) {
            // && campaign.campaignType
            if (
                campaign.dealerId &&
                campaign.mediaType &&
                campaign.strategyType &&
                campaign.startDate &&
                campaign.endDate
            ) {
                setIsFetchingDuplicateCampaigns(true);

                ApiService.findDuplicateCampaigns(
                    null,
                    campaign.dealerId,
                    campaign.mediaType,
                    campaign.campaignType ?? 'DCO', // @TODO
                    campaign.strategyType,
                    Utils.getESTStartDate(campaign.startDate),
                    Utils.getESTEndDate(campaign.endDate),
                    {
                        cancelToken: newCancelTokenSource.token,
                    }
                )
                    .then((response) => {
                        setDuplicateCampaigns(response.data);
                        // setShowError(false);

                        if (response.data && response.data.length > 0) {
                            setDuplicateCampaignDialogOpen(true);
                        }
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) {
                            console.log('Request canceled', error);
                        } else {
                            console.error(error);
                        }
                    })
                    .finally(() => {
                        setIsFetchingDuplicateCampaigns(false);
                    });
            }
        }

        return () => {
            newCancelTokenSource.cancel('Operation canceled by the user.');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ignoreDuplicates,
        campaign.id,
        campaign.campaignId,
        campaign.dealerId,
        campaign.mediaType,
        campaign.campaignType,
        campaign.strategyType,
        campaign.budget,
        campaign.startDate,
        campaign.endDate,
    ]);

    return (
        <>
            {duplicateCampaigns.length > 0 && !ignoreDuplicates && (
                <CampaignDuplicateDialog
                    loading={isFetchingLocations}
                    campaign={campaign}
                    duplicateCampaigns={duplicateCampaigns}
                    open={duplicateCampaignDialogOpen}
                    onClose={handleDuplicateCampaignDialogClose}
                    onCancel={handleDuplicateCampaignDialogCancel}
                    onConfirm={handleDuplicateCampaignDialogConfirm}
                    onView={handleDuplicateCampaignDialogView}
                />
            )}

            {viewCampaign && (
                <CampaignSummaryDialog
                    open={viewCampaignDialogOpen}
                    campaign={viewCampaign}
                    onClose={handleViewCampaignDialogClose}
                />
            )}
        </>
    );
}
