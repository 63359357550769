import { useCallback, useState } from 'react';

import { Campaign, CampaignChangeRequest } from '../types/Campaign';
import useZipCodes, { ZipCodes, ZipCodesProps } from './useZipCodes';

// Campaign - Zip Codes

export interface CampaignZipCodes extends ZipCodes {
    fetchCampaignZipCodes: () => void;
}

export type CampaignZipCodesProps = {
    campaign: Campaign;
} & ZipCodesProps;

export function useCampaignZipCodes(props: CampaignZipCodesProps): CampaignZipCodes {
    const { campaign, ...zipCodesProps } = props;
    const $zipCodes = useZipCodes(zipCodesProps);

    const fetchCampaignZipCodes = useCallback(() => {
        $zipCodes.setRawZipCodes(campaign.zipcodes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.zipcodes]);

    return { ...$zipCodes, fetchCampaignZipCodes };
}

// Campaign - Geography Targeting

export interface CampaignGeographyTargeting extends ZipCodes {
    fetchCampaignGeographyTargeting: () => void;
}

export type CampaignGeographyTargetingProps = {
    campaign: Campaign;
} & ZipCodesProps;

export function useCampaignGeographyTargeting(props: CampaignGeographyTargetingProps): CampaignGeographyTargeting {
    const { campaign, ...zipCodesProps } = props;
    const $zipCodes = useZipCodes(zipCodesProps);

    const fetchCampaignGeographyTargeting = useCallback(() => {
        $zipCodes.setRawZipCodes(campaign?.geographyTargeting ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.geographyTargeting]);

    return { ...$zipCodes, fetchCampaignGeographyTargeting };
}

interface CampaignUpdatePayloadState {
    toCampaignUpdatePayload: (campaign: CampaignChangeRequest) => CampaignChangeRequest;
}

export function useCampaignUpdatePayload(): CampaignUpdatePayloadState {
    const toCampaignUpdatePayload = (campaign: CampaignChangeRequest): CampaignChangeRequest => {
        campaign.adGroups = campaign.adGroups.map((adGroup: any) => {
            if (adGroup?.adGroupAudienceSources) {
                adGroup.adGroupAudienceSources = adGroup.adGroupAudienceSources.map((adGroupAudienceSource: any) => {
                    // delete adGroupAudienceSource['id'];
                    return adGroupAudienceSource;
                });
            }
            if (adGroup?.adGroupInventorySources) {
                adGroup.adGroupInventorySources = adGroup.adGroupInventorySources.map((adGroupInventorySource: any) => {
                    // delete adGroupInventorySource['id'];
                    return adGroupInventorySource;
                });
            }
            if (adGroup?.adGroupLocations) {
                adGroup.adGroupLocations = adGroup.adGroupLocations.map((adGroupLocation: any) => {
                    // delete adGroupLocation['id'];
                    return adGroupLocation;
                });
            }
            if (adGroup?.adGroupProductCategories) {
                adGroup.adGroupProductCategories = adGroup.adGroupProductCategories.map(
                    (adGroupProductCategory: any) => {
                        // delete adGroupProductCategory['id'];
                        return adGroupProductCategory;
                    }
                );
            }

            return adGroup;
        });

        return {
            ...campaign,
        };
    };

    return {
        toCampaignUpdatePayload,
    };
}

export interface CampaignDuplicateValidatorState {
    duplicateCampaigns: Campaign[];
    setDuplicateCampaigns: (duplicateCampaigns: Campaign[]) => void;
    isFetchingDuplicateCampaigns: boolean;
    setIsFetchingDuplicateCampaigns: (isFetchingDuplicateCampaigns: boolean) => void;
}

export function useCampaignDuplicateValidator(): CampaignDuplicateValidatorState {
    const [duplicateCampaigns, setDuplicateCampaigns] = useState<Campaign[]>([]);
    const [isFetchingDuplicateCampaigns, setIsFetchingDuplicateCampaigns] = useState<boolean>(false);

    return {
        duplicateCampaigns,
        setDuplicateCampaigns,
        isFetchingDuplicateCampaigns,
        setIsFetchingDuplicateCampaigns,
    };
}
