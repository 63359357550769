import { useContext, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { CampaignStatusCount } from '../../types/Campaign';
import { UserContext } from '../../App';
import theme from '../../theme';
import Row from '../../components/Row';

export default function CampaignStatusFilter(props: {
    campaignStatusCounts: CampaignStatusCount[];
    selectedStatus: string;
    onClick(statusId: string): void;
}) {
    const { campaignStatusCounts, selectedStatus, onClick } = props;
    const { userContext } = useContext(UserContext);

    const filteredCampaignStatusCounts = useMemo(() => {
        if (!userContext.isAdmin()) {
            return campaignStatusCounts.filter((campaignStatusCount: CampaignStatusCount) => {
                switch (campaignStatusCount.statusId) {
                    case 'ARCHIVED':
                        return false;
                }

                return true;
            });
        }
        return campaignStatusCounts;
    }, [campaignStatusCounts, userContext]);

    return (
        <Row
            sx={{
                flexWrap: 'wrap',
                gap: 2,
                paddingBottom: '8px',
            }}
        >
            {filteredCampaignStatusCounts.map((campaignStatusCount) => {
                const selected = selectedStatus === campaignStatusCount.statusId;
                let backgroundColor = '';
                let color = selected ? theme.palette.primary.contrastText : theme.palette.text.primary;
                if (selected) {
                    switch (campaignStatusCount.statusId) {
                        case '':
                            backgroundColor = theme.palette.primary.main;
                            break;
                        case 'DELIVERING':
                            backgroundColor = theme.palette.success.main;
                            break;
                        case 'DRAFT':
                        case 'PENDING_COGNITION':
                            backgroundColor = theme.palette.warning.main;
                            color = theme.palette.text.secondary;
                            break;
                        case 'LINEITEMS_NOT_RUNNING':
                        case 'OUT_OF_BUDGET':
                            backgroundColor = theme.palette.error.main;
                            break;
                        case 'INACTIVE':
                        case 'ENDED':
                            backgroundColor = theme.palette.divider;
                            color = theme.palette.text.secondary;
                            break;
                        case 'ARCHIVED':
                            backgroundColor = theme.palette.divider;
                            color = theme.palette.text.secondary;
                            break;
                        default:
                            backgroundColor = theme.palette.divider;
                            break;
                    }
                }
                return (
                    <Box
                        key={campaignStatusCount.statusId}
                        sx={{
                            cursor: 'pointer',
                            paddingX: '24px',
                            paddingY: '16px',
                            height: '125px',
                            width: '215px',
                            borderRadius: '12px',
                            border: `1px solid ${theme.palette.divider}`,
                            backgroundColor: backgroundColor,
                            '&:hover': {
                                boxShadow:
                                    '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
                            },
                            color: color,
                        }}
                        onClick={() => {
                            onClick(campaignStatusCount.statusId);
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{
                                fontWeight: theme.typography.fontWeightMedium,
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '16px',
                                    fontWeight: theme.typography.fontWeightMedium,
                                    lineHeight: '24px',
                                }}
                            >
                                {campaignStatusCount.description}
                            </span>
                            <span
                                style={{
                                    fontSize: '36px',
                                    fontWeight: selected
                                        ? theme.typography.fontWeightBold
                                        : theme.typography.fontWeightMedium,
                                    lineHeight: '40px',
                                }}
                            >
                                {campaignStatusCount.count}
                            </span>
                        </Stack>
                    </Box>
                );
            })}
        </Row>
    );
}
