import { Dealer } from '../../types/Dealer';
import { useContext, useEffect, useMemo, useState } from 'react';
import Utils from '../../components/Utils';
import { AttributionStatus, AttributionStatusSet, PolkSalesWorkflowSet } from '../../types/Polk';
import { popoluteAttributionStatusSets, populatePolkSalesWorkflowSets } from '../../utils/Polk';
import { InfoMessageContext } from '../../components/GlobalInfoMessage';
import ApiService from '../../ApiService';
import Row from '../../components/Row';
import CDButton from '../../components/CDButton';
import CDMonthSelect, {
    CDMonth,
    isAfterFirstSundayOfCurrentMonth,
    generateMonths,
} from '../../components/CDMonthSelect';
import { Alert, Box, Paper } from '@mui/material';
import { RefreshOutlined } from '@mui/icons-material';

import PolkAttributionStatusDataGrid from './PolkAttributionStatusDataGrid';
import PolkWorkflowSetDataGridDialog from './PolkWorkflowSetDataGridDialog';

export default function PolkAttributionStatus(props: { dealer: Dealer }) {
    const { dealer } = props;
    const [attributionDate, setAttributionDate] = useState(Utils.getMonthStart(0));
    const [attributionStatus, setAttributionStatus] = useState<AttributionStatus>({
        salesWorkflows: [],
        inProgress: true,
    });
    const { showMessage } = useContext(InfoMessageContext);
    const [showInfoMessage, setShowInfoMessage] = useState(true);

    const polkSalesWorkflowSets = useMemo((): PolkSalesWorkflowSet[] => {
        return populatePolkSalesWorkflowSets(attributionStatus.salesWorkflows);
    }, [attributionStatus.salesWorkflows]);

    const [attributionStatusSets, setAttributionStatusSets] = useState<AttributionStatusSet[]>([]);
    const [attributionStatusSet, setAttributionStatusSet] = useState<AttributionStatusSet | null>(null);

    useEffect(() => {
        setAttributionStatusSets(popoluteAttributionStatusSets(polkSalesWorkflowSets));
    }, [polkSalesWorkflowSets]);

    useEffect(() => {
        getAttributionStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer]);

    function runAttribution() {
        if (dealer.id) {
            ApiService.runAttribution({ date: Utils.toAbsoluteUTC(attributionDate), dealerId: dealer.id }).then(() => {
                showMessage('success', 'Run Attribution has been queued.');
                getAttributionStatus();
            });
        }
    }

    function getAttributionStatus() {
        if (dealer.id) {
            ApiService.getAttributionStatus(dealer.id).then((response) => {
                const attributionStatus = response.data;
                setAttributionStatus(attributionStatus);
            });
        }
    }

    const getDataGridHeight = () => {
        let height: number = 0;

        const rowPerHeight: number = 275;
        let rows = attributionStatusSets.length;

        if (rows > 0) {
            height = rowPerHeight * rows;
        } else {
            height = rowPerHeight;
        }

        return height;
    };

    function generatePreviousMonths() {
        const months: CDMonth[] = generateMonths(3);

        if (!isAfterFirstSundayOfCurrentMonth()) {
            months.splice(0, 1);
        }

        return months;
    }

    return (
        <>
            {!dealer.amcInstanceId && <Alert severity="error">AMC Instance has not been configured.</Alert>}
            {dealer.amcInstanceId && (
                <>
                    <Paper
                        elevation={4}
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Box sx={{ padding: 3 }}>
                            <Row>
                                <CDMonthSelect
                                    value={attributionDate}
                                    months={generatePreviousMonths()}
                                    disabled={!dealer.amcInstanceId || attributionStatus.inProgress}
                                    change={(selectedMonth: any) => {
                                        if (selectedMonth) {
                                            setAttributionDate(selectedMonth.date);
                                        }
                                    }}
                                    variant="outlined"
                                    label={'Month'}
                                />

                                <CDButton
                                    variant="contained"
                                    tooltip="Run Attribution"
                                    sx={{
                                        height: '100%',
                                        width: '80px',
                                    }}
                                    disabled={!dealer.amcInstanceId || attributionStatus.inProgress}
                                    onClick={() => runAttribution()}
                                >
                                    Run
                                </CDButton>
                                <CDButton
                                    variant="text"
                                    color="primary"
                                    tooltip="Refresh Results"
                                    sx={{
                                        height: '100%',
                                        width: '80px',
                                    }}
                                    disabled={!attributionStatus.inProgress}
                                    onClick={() => getAttributionStatus()}
                                    startIcon={<RefreshOutlined />}
                                >
                                    Refresh
                                </CDButton>
                            </Row>
                            {showInfoMessage ? (
                                <Row sx={{ marginTop: 2 }}>
                                    <Alert severity="info" onClose={() => setShowInfoMessage(false)}>
                                        Runs may take longer than expected depending on time of day or number of
                                        Competitors assigned to an Advertiser. Only use the Run feature as-needed, such
                                        as when a new Advertiser and/or Competitor has been assigned.
                                    </Alert>
                                </Row>
                            ) : (
                                ''
                            )}
                        </Box>
                        <div style={{ height: '40px' }} />
                        <Box
                            sx={{
                                height: getDataGridHeight(),
                                width: '100%',
                                flexGrow: 1,
                            }}
                        >
                            <PolkAttributionStatusDataGrid
                                dealer={dealer}
                                rows={attributionStatusSets}
                                setView={setAttributionStatusSet}
                                // setRows={setAttributionStatusSets}
                            />
                        </Box>
                    </Paper>

                    {attributionStatusSet && (
                        <PolkWorkflowSetDataGridDialog
                            dealer={dealer}
                            attributionStatusSet={attributionStatusSet}
                            open={attributionStatusSet !== null}
                            onClose={() => {
                                setAttributionStatusSet(null);
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
}
