import { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { AudienceSource } from '../../../../types/AudienceSource';
import { toCampaignAdGroupAudienceSources, toAudienceSources } from '../../../../hooks/useAudienceSources';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import AudienceSourceField from '../../../../components/Audience/AudienceSourceField';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';

type CampaignTargetingAudienceCardProps = {} & CampaignWizardStepProps;

export default function CampaignTargetingAudienceCard(props: CampaignTargetingAudienceCardProps) {
    const { campaign, onChange } = props;
    const { $audienceSources, $loader } = useCampaignWizardContext();
    const {
        audienceSources: selections = [],
        fetchAudienceSources: fetchSelections = () => {},
        isFetchingAudienceSources: isFetchingSelections = false,
        hydrateAudienceSources: hydrateSelections = (selections: AudienceSource[]) => [],
    } = $audienceSources || {};

    const [audienceSources, setAudienceSources] = useState<AudienceSource[]>([]);

    const handleChangeAudienceSources = useCallback(
        (items: AudienceSource[]): void => {
            setAudienceSources(hydrateSelections(items));
        },
        [hydrateSelections]
    );

    useEffect(() => {
        if ($loader) {
            if (isFetchingSelections) {
                $loader.loading('fetchAudienceSources');
            } else {
                $loader.loaded('fetchAudienceSources');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingSelections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (campaign.adGroups[0]?.adGroupAudienceSources) {
                handleChangeAudienceSources(toAudienceSources(campaign.adGroups[0].adGroupAudienceSources));
            }
        } else {
            handleChangeAudienceSources([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, selections]);

    useEffect(() => {
        if (onChange) {
            if (campaign?.adGroups?.length) {
                campaign.adGroups[0] = {
                    ...campaign.adGroups[0],
                    adGroupAudienceSources: toCampaignAdGroupAudienceSources(
                        audienceSources,
                        campaign.adGroups[0].adGroupAudienceSources ?? []
                    ),
                };
            } else {
                campaign.adGroups.push({
                    adGroupAudienceSources: toCampaignAdGroupAudienceSources(audienceSources),
                });
            }

            onChange({
                adGroups: campaign.adGroups,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, audienceSources]);

    return (
        <Card id="campaign-wizard-targeting-audience" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Audience
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <Row>
                        <FormControl>
                            <FormLabel required={true} sx={{ mb: 2 }}>
                                Audience Categories and Segments
                            </FormLabel>

                            <Box>
                                <AudienceSourceField
                                    mediaType={campaign.mediaType}
                                    selections={selections}
                                    fetchSelections={fetchSelections}
                                    hydrateSelections={hydrateSelections}
                                    onChange={handleChangeAudienceSources}
                                    items={audienceSources}
                                />
                            </Box>
                        </FormControl>
                    </Row>
                </Column>
            </CardContent>
        </Card>
    );
}
