import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Autocomplete,
    AutocompleteProps,
    Checkbox,
    Chip,
    Paper,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';

import { Agency } from '../types/Agency';
import ApiService from '../ApiService';

// interface AutocompleteOption {
//     id: number;
//     name: string;
//     label: string;
//     value: string;
// }

const icon = <CheckBoxOutlineBlankIcon color="primary" />;
const checkedIcon = <CheckBoxIcon color="primary" />;

export type AgencyAutocompleteProps = {
    onFetch?: (agencies: Agency[]) => void;
    onFetching?: () => void;

    value?: number[];
    error?: boolean;
    label?: string;
    required?: boolean;
    variant?: TextFieldProps['variant'];
    TextFieldProps?: TextFieldProps;
} & Omit<AutocompleteProps<Agency, true, false, false>, 'renderInput' | 'options' | 'value'>;

export default function AgencyAutocomplete(props: AgencyAutocompleteProps) {
    const { onFetch, onFetching, value = [], ...autocompleteProps } = props;

    const [agencies, setAgencies] = useState<Agency[]>([]);

    const fetchAgencies = useCallback(() => {
        if (onFetching) {
            onFetching();
        }
        ApiService.getAgencies()
            .then((response) => {
                if (onFetch) {
                    onFetch(response.data);
                }
                setAgencies(response.data.filter((agency: { activeStatus: boolean }) => agency.activeStatus));
            })
            .catch();
    }, []);

    const selectedAgencies = useMemo((): Agency[] => {
        return agencies.filter((agency: Agency) => value.includes(agency.id as number));
    }, [agencies, value]);

    useEffect(() => {
        fetchAgencies();
    }, [fetchAgencies]);

    return (
        <Autocomplete
            multiple
            size="small"
            fullWidth={false}
            disableCloseOnSelect={true}
            value={selectedAgencies}
            options={agencies}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderTags={(tagValue, getTagProps) => {
                const tagValueCount: number = tagValue.length;

                const getChipLabel = (tagValue: any): string => {
                    return `${tagValueCount} Selected`;
                };

                if (tagValueCount > 1) {
                    const index = 0;
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            key={key}
                            label={getChipLabel(tagValue)}
                            size="small"
                            {...tagProps}
                            onDelete={() => console.log(tagValue)}
                        />
                    );
                }

                return (
                    <Typography sx={{ px: (theme) => theme.spacing(1) }}>
                        {tagValueCount > 0 ? tagValue[0].name : ''}
                    </Typography>
                );
            }}
            renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                    </li>
                );
            }}
            renderInput={(inputProps) => (
                <TextField
                    error={props.error}
                    label={props.label ?? 'Select an Agency'}
                    variant={props.variant ?? 'outlined'}
                    required={props.required !== undefined ? props.required : !props.disabled}
                    {...inputProps}
                    {...props.TextFieldProps}
                />
            )}
            PaperComponent={(paperComponentProps) => (
                <Paper
                    {...paperComponentProps}
                    sx={{
                        boxShadow: 8,
                        borderRadius: '8px',
                    }}
                />
            )}
            {...autocompleteProps}
        />
    );
}
