import { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
// import moment from 'moment-timezone';

import { AttributionPerformance } from '../../../../types/CampaignPerformance';
// import ApiService from '../../../../ApiService';

import { Metric } from '../../../../components/Metrics/MetricCard';
import { setupMetricChange } from '../../../../components/Metrics/MetricCard';
import { useReportingDashboardPageContext } from '../../../../hooks/useReportingDashboardPage';
// import { useAmcReachAndFrequencyList } from '../../../../hooks/useAmcReachAndFrequencyList';
import MetricCard from '../../../../components/Metrics/MetricCard';

const getSumPerformanceMetricValue = (metrics: any[], key: string): number => {
    return metrics.map((metric: any) => metric?.[key] ?? 0).reduce((total, subtotal) => total + subtotal, 0);
};

const getAveragePerformanceMetricValue = (metrics: any[], key: string): number => {
    let value: number = 0;

    if (metrics.length > 0) {
        value = metrics.map((metric: any) => metric?.[key] ?? 0).reduce((total, subtotal) => total + subtotal, 0);
        value /= metrics.length;
    }

    return value;
};

export default function ReportingPerformanceMetrics() {
    const {
        reportingCriteria,

        performances,
        attributionMetrics,
        setAttributionMetrics,
        isFetchingPerformances,

        fetchAttributionPerformances,
        attributionPerformances,
        isFetchingAttributionPerformances,
    } = useReportingDashboardPageContext();

    // const { amcReachAndFrequencyList, fetchAmcReachAndFrequencyList, isFetchingAmcReachAndFrequencyList } =
    //     useAmcReachAndFrequencyList();

    const getMetricByAttributionPerformance = (
        target: string,
        format: 'number' | 'dollar' | 'decimal' | 'percent'
    ): Metric => {
        let _metric: Metric = {
            format: format,
            value: 0,
        };

        if (attributionPerformances && attributionPerformances.length) {
            _metric.value = attributionPerformances
                .map((o: AttributionPerformance) => o[target as keyof AttributionPerformance] as number)
                .reduce((a, b) => a + b, 0);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    };

    const getMetricByCampaignPerformance = (
        target: string,
        format: 'number' | 'dollar' | 'decimal' | 'percent'
    ): Metric => {
        let _metric: Metric = {
            format: format,
            value: 0,
        };

        if (performances.length > 0) {
            switch (target) {
                case 'averageCPM':
                    _metric.value = getAveragePerformanceMetricValue(performances, 'CPM');
                    break;

                case 'averageCTR':
                    _metric.value = getAveragePerformanceMetricValue(performances, 'CTR');
                    break;

                case 'averageViewabilityRate':
                    _metric.value = getAveragePerformanceMetricValue(performances, 'viewabilityRate');
                    break;

                case 'videoCompleteRate':
                case 'costPerCompletedVideo':
                case 'timeSpent':
                    _metric.value = getAveragePerformanceMetricValue(performances, target);
                    break;

                default:
                    _metric.value = getSumPerformanceMetricValue(performances, target);
                    break;
            }
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    };

    if (isFetchingAttributionPerformances) {
    }

    const totalSales = useMemo(() => {
        return getMetricByAttributionPerformance('totalSales', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionPerformances]);

    const attributedSales = useMemo(() => {
        return getMetricByAttributionPerformance('totalAdExposedSales', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionPerformances]);

    const totalSpend = useMemo(() => {
        return getMetricByCampaignPerformance('totalCost', 'dollar');
        // return getMetricByAttributionPerformance('totalInvestment', 'dollar');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const averageCPM = useMemo(() => {
        return getMetricByCampaignPerformance('averageCPM', 'dollar');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const averageCTR = useMemo(() => {
        return getMetricByCampaignPerformance('averageCTR', 'percent');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const averageViewabilityRate = useMemo(() => {
        return getMetricByCampaignPerformance('averageViewabilityRate', 'percent');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const costPerSold = useMemo(() => {
        return getMetricByAttributionPerformance('costPerAdExposedSale', 'dollar');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionPerformances]);

    const totalImpressions = useMemo(() => {
        return getMetricByCampaignPerformance('impressions', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const totalClickthroughs = useMemo(() => {
        return getMetricByCampaignPerformance('clickthroughs', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const totalDailyReach = useMemo(() => {
        return getMetricByCampaignPerformance('dailyReach', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const videoCompleteRate = useMemo(() => {
        return getMetricByCampaignPerformance('videoCompleteRate', 'percent');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const costPerCompletedVideo = useMemo(() => {
        return getMetricByCampaignPerformance('costPerCompletedVideo', 'dollar');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    const timeSpent = useMemo(() => {
        return getMetricByCampaignPerformance('timeSpent', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances]);

    // const totalDailyReach: Metric = useMemo(() => {
    //     let _metric: Metric = {
    //         format: 'number',
    //         value: 0,
    //     };

    //     if (amcReachAndFrequencyList.length > 0) {
    //         _metric.value = amcReachAndFrequencyList
    //             .map((amcReachAndFrequency: any) => amcReachAndFrequency?.reach as number)
    //             .reduce((a: number, b: number) => a + b, 0);
    //     }

    //     _metric = setupMetricChange(_metric);

    //     return _metric;
    // }, [amcReachAndFrequencyList]);

    useEffect(() => {
        fetchAttributionPerformances();
    }, [fetchAttributionPerformances]);

    // useEffect(() => {
    //     if (reportingCriteria !== null) {
    //         const startDate: Date = reportingCriteria.startDate;
    //         const endDate: Date = reportingCriteria.endDate;

    //         fetchAmcReachAndFrequencyList({
    //             // dealerId: dealerId,
    //             dealerId: 279, // @TODO
    //             campaignName: 'Global',
    //             startDate: startDate,
    //             endDate: endDate,
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [reportingCriteria]);

    useEffect(() => {
        setAttributionMetrics({
            ...attributionMetrics,
            ...{
                averageCPM,
                averageCTR,
                averageViewabilityRate,
                totalSales,
                attributedSales,
                totalSpend,
                costPerSold,
                totalImpressions,
                totalClickthroughs,
                totalDailyReach,
                videoCompleteRate,
                costPerCompletedVideo,
                timeSpent,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        averageCPM,
        averageCTR,
        averageViewabilityRate,
        totalSales,
        attributedSales,
        totalSpend,
        costPerSold,
        totalImpressions,
        totalClickthroughs,
        totalDailyReach,
        videoCompleteRate,
        costPerCompletedVideo,
        timeSpent,
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingPerformances}
                    metric={totalSpend}
                    label="Total Investment"
                    tooltip="Total advertiser(s) ad spend for the selected time period"
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingPerformances}
                    metric={totalImpressions}
                    label="Total Exposures"
                    tooltip="Total number of ad exposures or impressions for the selected advertiser(s) for the selected time period"
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingPerformances}
                    metric={averageCPM}
                    label="Average CPM"
                    tooltip="Average CPM represents the average cost per thousand impressions across all media types"
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingPerformances}
                    metric={totalDailyReach}
                    label="Household Reach"
                    tooltip="Total number of households exposed to an advertisement"
                    hideSublabel={true}
                />
            </Grid>

            {reportingCriteria && reportingCriteria.mediaType !== '' && (
                <>
                    <Grid item xs={4}>
                        <MetricCard
                            loading={isFetchingPerformances}
                            metric={averageViewabilityRate}
                            label="Average Viewability"
                            hideSublabel={true}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <MetricCard
                            loading={isFetchingPerformances}
                            metric={totalClickthroughs}
                            label={reportingCriteria.mediaType === 'Audio' ? 'Companion Banner Clicks' : 'Total Clicks'}
                            inverseColor={true}
                            hideSublabel={true}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <MetricCard
                            loading={isFetchingPerformances}
                            metric={averageCTR}
                            label="Average CTR"
                            hideSublabel={true}
                        />
                    </Grid>

                    {['Video', 'Online Video', 'Streaming TV'].includes(reportingCriteria.mediaType) && (
                        <>
                            <Grid item xs={4}>
                                <MetricCard
                                    loading={isFetchingPerformances}
                                    metric={videoCompleteRate}
                                    label="Video Complete Rate"
                                    hideSublabel={true}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <MetricCard
                                    loading={isFetchingPerformances}
                                    metric={costPerCompletedVideo}
                                    label="Cost Per Completed Video"
                                    hideSublabel={true}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <MetricCard
                                    loading={isFetchingPerformances}
                                    metric={timeSpent}
                                    label="Time Spent With Brand"
                                    hideSublabel={true}
                                />
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
