import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Box, FormControl, Grid, Slider, Typography } from '@mui/material';
import { Card, CardContent } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { CDTextField } from '../../../../components/CDTextField';
import { Dealer } from '../../../../types/Dealer';
import { Location } from '../../../../types/Location';
import { toCampaignAdGroupLocations, toLocations } from '../../../../hooks/useLocations';
import { useLocationBoundaries } from '../../../../hooks/useMapBoundaries';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import { useMapToken } from '../../../../hooks/useMapToken';
import CampaignLocationList from './CampaignLocationList';
import Column from '../../../../components/Column';
import { ZipCode } from '../../../../types/Location';
import Map from '../../../../components/Location/LocationMap';

type CampaignLocationTabProps = {
    locations: Location[];
    onChangeLocation?: (items: Location[]) => void;
    onRecenterLocation?: () => void;
} & CampaignWizardStepProps;

export default function CampaignLocationTab(props: CampaignLocationTabProps) {
    const { campaign, onChange, onChangeLocation, onRecenterLocation, locations } = props;
    const { isCompletedCampaign } = useCampaignWizardContext();
    const { fetchMapToken, mapToken } = useMapToken();
    const dealer = campaign.dealer;

    const { $locations } = useCampaignWizardContext();
    const {
        locations: selections = [],
        hydrateLocations = (locations: Location[]) => locations,
        getLocationByRawZipCode = (rawZipCode: string) => undefined,
    } = $locations || {};

    const wizard = useCampaignWizardContext();
    const { $campaignZipCodes, $campaignGeographyTargeting } = useCampaignWizardContext();

    const country = 'United States';
    const countryCode = 'US';
    const baseUrl = '/api/geographicTargeting';

    const $disableDefaultDealerLocation = useRef(false);
    const $populateZipCodesByZipCodeRadius = useRef(false);

    const getCenterPoint = useCallback(
        async (country: string, zipCode: string) => {
            const response = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipCode}.json?country=${countryCode}&limit=1&proximity=ip&access_token=${mapToken}`
            );
            const data = await response.json();

            if (data.features.length > 0) {
                return { center: data.features[0].center };
            }

            // USA
            return { center: [-97.9222112121185, 39.3812661305678] };
        },
        [mapToken]
    );

    const $locationBoundaries = useLocationBoundaries({
        baseUrl: baseUrl,
        country: country,
        getCenterPoint: getCenterPoint,
    });

    const [centerPoint, setCenterPoint] = useState('');
    const [mapCenter, setMapCenter] = useState('');

    const minRadius: number = 10;
    const maxRadius: number = 75;
    const stepRadius: number = 5;

    const handleAttachLocations = useCallback(
        async (items: Location[]): Promise<void> => {
            if (items.length) {
                if (wizard.$loader) {
                    wizard.$loader.loading('campaignAttachLocations');
                }
                const zipCodes: ZipCode[] = await $locationBoundaries.getLocationBoundaryZipCodes(
                    items[0],
                    campaign.zipcodeRadius
                );

                if (zipCodes.length) {
                    if ($campaignZipCodes) {
                        $campaignZipCodes.attachZipCodes(zipCodes);

                        if (onChange) {
                            onChange({
                                zipcodes: $campaignZipCodes.toRawZipCodes(),
                            });
                        }
                    }
                }
                if (wizard.$loader) {
                    wizard.$loader.loaded('campaignAttachLocations');
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [campaign]
    );

    const handleDetachLocations = useCallback(
        async (items: Location[]): Promise<void> => {
            $disableDefaultDealerLocation.current = true;

            if (items.length) {
                if (wizard.$loader) {
                    wizard.$loader.loading('campaignDetachLocations');
                }
                const zipCodes: ZipCode[] = await $locationBoundaries.getLocationBoundaryZipCodes(
                    items[0],
                    campaign.zipcodeRadius
                );

                if (zipCodes.length) {
                    if ($campaignZipCodes) {
                        $campaignZipCodes.detachZipCodes(zipCodes);

                        if (onChange) {
                            onChange({
                                zipcodes: $campaignZipCodes.toRawZipCodes(),
                            });
                        }
                    }

                    if ($campaignGeographyTargeting) {
                        $campaignGeographyTargeting.detachZipCodes(zipCodes);

                        if (onChange) {
                            onChange({
                                geographyTargeting: $campaignGeographyTargeting.toRawZipCodes(),
                            });
                        }
                    }
                }
                if (wizard.$loader) {
                    wizard.$loader.loaded('campaignDetachLocations');
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [campaign]
    );

    useEffect(() => {
        fetchMapToken();
    }, [fetchMapToken]);

    useEffect(() => {
        let rawCenterPoint = '';

        if (locations.length > 0) {
            const lastLocation: Location | undefined = locations?.[locations.length - 1];
            rawCenterPoint = lastLocation?.name ?? '';
        }

        if (!centerPoint.length) {
            if (dealer) {
                rawCenterPoint = getAddress(dealer, campaign.zipcodes);
            }

            setCenterPoint(rawCenterPoint);
            setMapCenter(rawCenterPoint);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer, campaign.zipcodes, locations]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            onChange({
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        if (event?.target?.name) {
            if (event.target.name === 'zipcodeRadius') {
                $populateZipCodesByZipCodeRadius.current = true;
            }
            onChange({
                [event.target.name]: event.target.value as number,
            });
        }
    };

    function getAddress(dealer: Dealer, zipcodes?: string): string {
        let address = '';
        if (dealer) {
            if (dealer.address) address = dealer.address;
            if (dealer.city) address += ' ' + dealer.city;
            if (dealer.state) address += ' ' + dealer.state;
            if (dealer.zip) address += ' ' + dealer.zip;
        }
        if (address === '' && zipcodes && zipcodes.length > 0) {
            address = zipcodes.split(',')[0];
        }
        return address;
    }

    function zipCodesLoaded(results: ZipCode[]) {
        // const zipCodeString = results.map(({ code }) => code).join(',');
        // const population = results.reduce((acc, r) => acc + r.population, 0);
        // onChange({
        //     zipcodes: zipCodeString,
        //     population: Math.round(population / 1000) * 1000,
        // });
    }

    function zipCodeClick(zip: ZipCode) {
        // let zips = campaign.zipcodes?.split(',') || [];
        // let population = campaign.population ? campaign.population : 0;
        // const index = zips.indexOf(zip.code);
        // if (index !== -1) {
        //     zips = [...zips.slice(0, index), ...zips.slice(index + 1)];
        //     population -= zip.population;
        // } else {
        //     zips = [...zips, zip.code];
        //     population += zip.population;
        // }
        // onChange({
        //     zipcodes: zips.join(),
        //     population: population,
        // });
    }

    const handleRecenterLocation = useCallback((): void => {
        const lastLocation: Location | undefined = locations?.[locations.length - 1];
        const rawCenterPoint: string = lastLocation?.name ?? '';

        setMapCenter(rawCenterPoint ? rawCenterPoint : centerPoint);

        if (onRecenterLocation) {
            onRecenterLocation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, onChange, onRecenterLocation]);

    const handleChangeLocations = useCallback(
        (items: Location[]): void => {
            if (onChangeLocation) {
                onChangeLocation(hydrateLocations(items));
            }
        },
        [onChangeLocation, hydrateLocations]
    );

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (campaign.adGroups[0]?.adGroupLocations) {
                handleChangeLocations(toLocations(campaign.adGroups[0].adGroupLocations));
            }
        } else {
            handleChangeLocations([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, selections]);

    useEffect(() => {
        if ($populateZipCodesByZipCodeRadius.current === true) {
            $populateZipCodesByZipCodeRadius.current = false;

            if ($campaignZipCodes) {
                if (locations.length) {
                    $campaignZipCodes.resetZipCodes();

                    locations.forEach((location: Location) => {
                        $campaignZipCodes.resetZipCodes();

                        handleAttachLocations([location]);
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.zipcodeRadius]);

    useEffect(() => {
        if ($disableDefaultDealerLocation.current === false) {
            if (dealer && selections.length) {
                if (!locations.length && !campaign.zipcodes) {
                    const location: Location | undefined = getLocationByRawZipCode(dealer.zip);

                    if (location) {
                        handleChangeLocations([location]);
                        handleAttachLocations([location]);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign, dealer, selections]);

    useEffect(() => {
        if (onChange) {
            if (campaign?.adGroups?.length) {
                campaign.adGroups[0] = {
                    ...campaign.adGroups[0],
                    adGroupLocations: toCampaignAdGroupLocations(
                        locations,
                        campaign.adGroups[0].adGroupLocations ?? []
                    ),
                };
            } else {
                campaign.adGroups.push({
                    adGroupLocations: toCampaignAdGroupLocations(locations),
                });
            }

            onChange({
                adGroups: campaign.adGroups,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, locations]);

    useEffect(() => {
        handleRecenterLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderTopWidth: 0,
                }}
            >
                <CampaignLocationList
                    loading={false}
                    campaign={campaign}
                    onChange={onChange}
                    items={locations}
                    onChangeMany={handleChangeLocations}
                    onAttachMany={handleAttachLocations}
                    onDetachMany={handleDetachLocations}
                />
            </Card>

            <Column>
                <Typography variant="caption" color="text.primary" sx={{ mb: 6 }}>
                    Select Targeting Radius (Miles)
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <FormControl fullWidth size="small">
                            <CDTextField
                                type="number"
                                variant="outlined"
                                label="Radius (mi)"
                                name="zipcodeRadius"
                                onBlur={handleRecenterLocation}
                                value={campaign.zipcodeRadius ?? ''}
                                onChange={handleChange}
                                disabled={isCompletedCampaign}
                                InputProps={{
                                    inputProps: { min: minRadius, max: maxRadius },
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                            <Slider
                                name="zipcodeRadius"
                                defaultValue={minRadius}
                                value={campaign.zipcodeRadius ?? minRadius}
                                getAriaValueText={(value: number) => `${value}`}
                                step={stepRadius}
                                min={minRadius}
                                max={maxRadius}
                                valueLabelDisplay="on"
                                marks={[
                                    { value: 10, label: '10' },
                                    { value: 75, label: '75' },
                                ]}
                                onChange={handleSliderChange}
                                disabled={isCompletedCampaign}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Column>

            <Card variant="outlined">
                <CardContent sx={{ p: 2 }}>
                    <Column gap={2}>
                        <Typography variant="body2">Map View for Search Location</Typography>

                        <Box
                            sx={{
                                height: '55vh',
                            }}
                        >
                            <Map
                                locations={locations}
                                radius={campaign.zipcodeRadius ? campaign.zipcodeRadius : 30}
                                onZipClick={zipCodeClick}
                                onZipsLoaded={zipCodesLoaded}
                                zipCode={mapCenter}
                                zipCodes={''}
                            />
                        </Box>
                    </Column>
                </CardContent>
            </Card>
        </>
    );
}
