import { Divider, Typography } from '@mui/material';
import theme from '../theme';

export default function Heading(props: any) {
    return (
        <div>
            <Typography variant={props.variant ? props.variant : 'h6'} color="text.secondary">
                {props.children}
            </Typography>
            <Divider style={{ marginTop: '10px', marginBottom: props.margin ? props.margin : '12px' }} />
        </div>
    );
}

export function SubHeading(props: any) {
    return (
        <div
            {...props}
            style={{
                fontSize: '14px',
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.text.secondary,
                paddingTop: '8px',
                paddingBottom: '8px',
            }}
        >
            {props.children}
        </div>
    );
}
