import { gridClasses, GridColumns, GridRowParams, GridToolbarContainer } from '@mui/x-data-grid';
import { AlertColor, Button, IconButton, Tooltip } from '@mui/material';
import { AddOutlined, DoNotDisturbOutlined, DownloadOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { DealerExportSite } from '../../types/ExportSite';
import ApiService from '../../ApiService';
import { Dealer } from '../../types/Dealer';
import CDGrid from '../../components/CDGrid';
import DealerExportSiteEditDialog from '../importexport/DealerExportSiteEditDialog';
import Utils from '../../components/Utils';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';

export interface DealerExportSitesGridProps {
    dealer: Dealer;
}

export default function DealerExportSitesGrid({ dealer }: DealerExportSitesGridProps) {
    const { id } = dealer;

    const [dealerExportSites, setDealerExportSites] = useState<DealerExportSite[]>([]);
    const [dealerExportSite, setDealerExportSite] = useState<DealerExportSite | null>(null);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });

    useEffect(() => {
        if (id) {
            ApiService.getExportSitesForDealer(id).then((response) => {
                loadDealerExportSites(response.data);
            });
        }
    }, [id]);

    function loadDealerExportSites(dealerExportSites: DealerExportSite[]) {
        let id = 0;
        const exportSites = dealerExportSites;
        exportSites.forEach((i) => (i.id = id++));
        setDealerExportSites(exportSites);
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function addExport() {
        let dealerExportSite = new DealerExportSite();
        if (dealer) {
            dealerExportSite.dealer = dealer;
            dealerExportSite.dealerId = dealer.id;
        }
        setDealerExportSite(dealerExportSite);
    }

    function handleEdit(params: GridRowParams) {
        setDealerExportSite(params as DealerExportSite);
    }

    function getExportSites() {
        if (dealer?.id) {
            ApiService.getExportSitesForDealer(dealer.id).then((response) => {
                loadDealerExportSites(response.data);
            });
        } else {
            ApiService.getDealerExportSites().then((response) => {
                loadDealerExportSites(response.data);
            });
        }
    }

    function closeDialog(saved: boolean) {
        setDealerExportSite(null);
        if (saved) {
            getExportSites();
        }
    }

    function downloadExportData(dealerExportSite: DealerExportSite) {
        const dealerId = dealerExportSite.dealerId;
        const exportSiteId = dealerExportSite.exportSiteId;
        if (dealerId && exportSiteId && dealerExportSite.dealer) {
            const dealer = dealerExportSite.dealer;
            ApiService.getAutoExportCount(
                dealerId,
                dealer.ageExportFilterOperator,
                dealer.ageExportFilterValue,
                dealer.newUsedFilter,
                dealer.certifiedFilter,
                dealer.customLabelFilter
            ).then((response) => {
                if (response.data > 0) {
                    ApiService.getExportData(dealerId, exportSiteId).then((response) => {
                        Utils.downloadFile(response, 'text/csv');
                    });
                } else {
                    showInfoMessage('error', 'No Autos to export.');
                }
            });
        }
    }

    const columns: GridColumns = [
        {
            width: 120,
            field: 'active',
            headerName: 'Active',
            renderCell: (params) => {
                if (params.row.active) {
                    return <></>;
                } else {
                    return (
                        <Tooltip title="Not Active">
                            <DoNotDisturbOutlined />
                        </Tooltip>
                    );
                }
            },
        },
        {
            width: 250,
            field: 'exportName',
            headerName: 'Export Site',
            flex: 1,
            valueGetter: (params) => {
                return params.row.exportSite.exportName;
            },
            renderCell: (params) => {
                return (
                    <Button
                        sx={{ textDecoration: 'underline' }}
                        onClick={() => {
                            handleEdit(params.row);
                        }}
                    >
                        {params.row.exportSite.exportName}
                    </Button>
                );
            },
        },
        {
            width: 120,
            field: 'exportType',
            headerName: 'Export Type',
            valueGetter: (params) => {
                return params.row.exportSite.exportType;
            },
        },
        {
            width: 120,
            field: 'exportSiteId',
            headerName: 'Download',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton
                            color="primary"
                            size="small"
                            aria-label="download"
                            onClick={() => {
                                downloadExportData(params.row);
                            }}
                        >
                            <DownloadOutlined />
                        </IconButton>
                    </>
                );
            },
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <div style={{ flexGrow: 1 }} />
                <Tooltip title="Add a new Export Site">
                    <Button
                        sx={{ margin: '5px' }}
                        onClick={addExport}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddOutlined />}
                    >
                        Add
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    return (
        <>
            {dealerExportSite != null && (
                <DealerExportSiteEditDialog dealerExportSite={dealerExportSite} onClose={closeDialog} />
            )}
            <InfoMessage {...infoMessage} />
            <CDGrid rowHeight={70} columns={columns} rows={dealerExportSites} components={{ Toolbar: CustomToolbar }} />
        </>
    );
}
