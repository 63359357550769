import { Box, Button, Chip, Stack } from '@mui/material';
import Heading from '../../components/Heading';
import { useContext, useEffect, useState } from 'react';
import { AmcAttribution } from '../../types/AmcAttribution';
import { AddOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../ApiService';
import CDGrid from '../../components/CDGrid';
import AmcAttributionCreateFormDialog from './AmcAttributionCreateFormDialog';
import { AdvertiserContext } from '../../AdvertiserContext';

export default function AmcAttributionList() {
    const [amcAttributions, setAmcAttributions] = useState<AmcAttribution[]>([]);
    const navigate = useNavigate();
    const { advertiserContext } = useContext(AdvertiserContext);
    const [amcAttributionFormDialogOpen, setAmcAttributionFormDialogOpen] = useState<boolean>(false);

    const refresh = () => {
        ApiService.getAmcAttributions(
            advertiserContext.agencyId,
            advertiserContext.dealer ? advertiserContext.dealer.id : null
        )
            .then(function (response) {
                setAmcAttributions(response.data);
            })
            .catch(() => {
                setAmcAttributions([]);
            });
    };

    useEffect(() => {
        ApiService.getAmcAttributions(
            advertiserContext.agencyId,
            advertiserContext.dealer ? advertiserContext.dealer.id : null
        )
            .then(function (response) {
                setAmcAttributions(response.data);
            })
            .catch(() => {
                setAmcAttributions([]);
            });
    }, [advertiserContext]);

    function CustomToolbar() {
        return (
            <Box sx={{ padding: '10px' }}>
                <Stack direction="row" spacing={2}>
                    <div style={{ flexGrow: 1 }} />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddOutlined />}
                        onClick={() => {
                            setAmcAttributionFormDialogOpen(true);
                        }}
                    >
                        Add
                    </Button>
                </Stack>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Heading>AMC Offline Attribution</Heading>
                <CDGrid
                    components={{ Toolbar: CustomToolbar }}
                    columns={[
                        {
                            width: 110,
                            field: 'date',
                            headerName: 'Date',
                            headerClassName: 'grid-header',
                            valueGetter: (params) => {
                                return new Date(params.value).toLocaleDateString('en-US', {
                                    month: 'short',
                                    year: 'numeric',
                                });
                            },
                        },
                        {
                            width: 120,
                            field: 'status',
                            headerName: 'Status',
                            headerClassName: 'grid-header',
                            renderCell: (params) => (
                                <Chip
                                    label={params.value}
                                    size="small"
                                    variant={params.value === 'Published' ? 'filled' : 'outlined'}
                                    color={params.value === 'Published' ? 'success' : 'warning'}
                                />
                            ),
                        },
                        {
                            width: 160,
                            field: 'dealer',
                            headerName: 'Advertiser',
                            headerClassName: 'grid-header',
                            valueGetter: (params) => {
                                return params.value.dealerName;
                            },
                        },
                        {
                            width: 150,
                            field: 'campaignName',
                            headerName: 'Campaign Name',
                            headerClassName: 'grid-header',
                        },
                        {
                            width: 110,
                            field: 'dataSetName',
                            headerName: 'Data Type',
                            headerClassName: 'grid-header',
                        },

                        {
                            width: 150,
                            field: 'recordsSubmitted',
                            headerName: 'Records Submitted',
                            headerClassName: 'grid-header',
                        },
                        {
                            width: 150,
                            field: 'matchedAmazonRecords',
                            headerName: 'Matched Amazon Records',
                            headerClassName: 'grid-header',
                        },
                        {
                            width: 150,
                            field: 'attributedUserRecords',
                            headerName: 'Attributed User Records',
                            headerClassName: 'grid-header',
                        },
                        {
                            width: 110,
                            field: 'adBudget',
                            type: 'number',
                            headerName: 'Ad Budget',
                            headerClassName: 'grid-header',
                        },
                        {
                            width: 110,
                            field: 'adSpend',
                            type: 'number',
                            headerName: 'Ad Spend',
                            headerClassName: 'grid-header',
                        },
                        {
                            width: 110,
                            field: 'gross',
                            type: 'number',
                            headerName: 'Gross',
                            headerClassName: 'grid-header',
                        },
                    ]}
                    autoHeight={false}
                    autoPageSize={true}
                    rows={amcAttributions}
                    onRowClick={(params) => {
                        navigate('' + params.row.id);
                    }}
                />
            </Box>

            {amcAttributionFormDialogOpen && (
                <AmcAttributionCreateFormDialog
                    open={amcAttributionFormDialogOpen}
                    onClose={() => {
                        refresh();
                        setAmcAttributionFormDialogOpen(false);
                    }}
                />
            )}
        </>
    );
}
