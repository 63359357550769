import { useCallback, useContext, useEffect, useState } from 'react';
import { FormControl, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { Card, CardContent, CardHeader } from './CampaignWizardStyles';

import { AdvertiserContext } from '../../../../AdvertiserContext';
import { CampaignWizardStepProps } from './CampaignWizard';
import { Dealer } from '../../../../types/Dealer';
import { ProductCategory } from '../../../../components/ProductCategoryAutocomplete';
import { toCampaignAdGroupProductCategories, toProductCategories } from '../../../../hooks/useProductCategories';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import DealerSelector from '../../../../components/DealerSelector';
import ProductCategoryAutocomplete from '../../../../components/ProductCategoryAutocomplete';
import Column from '../../../../components/Column';

type CampaignSetupAdvertiserCardProps = {} & CampaignWizardStepProps;

export default function CampaignSetupAdvertiserCard(props: CampaignSetupAdvertiserCardProps) {
    const { campaign, onChange } = props;
    const { advertiserContext, setDealer } = useContext(AdvertiserContext);
    const { hasError, isDraftCampaign, isNewCampaign } = useCampaignWizardContext();

    const { $loader, $productCategories } = useCampaignWizardContext();
    const {
        productCategories: selections = [],
        fetchProductCategories: fetchSelections = () => {},
        isFetchingProductCategories: isFetchingSelections = false,
        hydrateProductCategories: hydrateSelections = (selection: ProductCategory[]) => [],
    } = $productCategories || {};

    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

    const selectDealer = useCallback(
        (dealerId: number | null, dealer: Dealer) => {
            if (dealerId) {
                onChange({
                    dealerId: dealerId,
                    dealer: dealer,
                });
            }
            setDealer(dealer);
        },
        [setDealer, onChange]
    );

    useEffect(() => {
        if (advertiserContext.dealer) {
            onChange({
                dealerId: advertiserContext.dealer.id,
                dealer: advertiserContext.dealer,
            });
        }
    }, [advertiserContext, onChange]);

    useEffect(() => {
        if ($loader) {
            if (isFetchingSelections) {
                $loader.loading('fetchProductCategories');
            } else {
                $loader.loaded('fetchProductCategories');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingSelections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (campaign.adGroups[0]?.adGroupProductCategories) {
                let hydratedProductCategories: ProductCategory[] = hydrateSelections(
                    toProductCategories(campaign.adGroups[0].adGroupProductCategories)
                );

                let parentIds: string[] = hydratedProductCategories
                    .map((productCategory: ProductCategory) => productCategory.parentId as string)
                    .filter((id: string) => id != null)
                    .filter((id: string, index: number, self: string[]) => self.indexOf(id) === index);

                parentIds.forEach((parentId: string) => {
                    if (!hydratedProductCategories.find((parent: ProductCategory) => parent.id === parentId)) {
                        const productCategory: ProductCategory | undefined = selections.find(
                            (selection: ProductCategory) => selection.id === parentId
                        );

                        if (productCategory) {
                            hydratedProductCategories.unshift(productCategory);
                        }
                    }
                });

                setProductCategories(hydratedProductCategories);
            }
        } else {
            setProductCategories([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, selections]);

    useEffect(() => {
        if (onChange) {
            if (campaign?.adGroups?.length) {
                campaign.adGroups[0] = {
                    ...campaign.adGroups[0],
                    adGroupProductCategories: toCampaignAdGroupProductCategories(
                        productCategories,
                        campaign.adGroups[0].adGroupProductCategories ?? []
                    ),
                };
            } else {
                campaign.adGroups.push({
                    adGroupProductCategories: toCampaignAdGroupProductCategories(productCategories),
                });
            }

            onChange({
                adGroups: campaign.adGroups,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, productCategories]);

    return (
        <Card id="campaign-wizard-setup-advertiser" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Advertiser Info
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl
                                sx={{
                                    '&': {
                                        width: '100%',
                                    },
                                    '& .MuiAutocomplete-root': {
                                        width: '100%',
                                    },
                                }}
                            >
                                <DealerSelector
                                    required={true}
                                    label="Advertiser"
                                    showLabel={true}
                                    error={hasError('dealerId')}
                                    disabled={!isNewCampaign || isDraftCampaign}
                                    agencyId={advertiserContext.agencyId}
                                    dealerId={campaign.dealerId}
                                    dealerSelected={selectDealer}
                                    variant="outlined"
                                    // @TODO Development
                                    filter={(dealers: Dealer[] | null): Dealer[] | null => {
                                        if (dealers) {
                                            // console.log(dealers.filter((o: Dealer) => o.id === 10));
                                            // return dealers.filter((o: Dealer) => o.id === 10);
                                            return dealers.filter((o: Dealer) => o.dspAdvertiser === '8761120690201');
                                        }

                                        return dealers;
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            {campaign.dealer && campaign.dealer.logoUrl && (
                                <Tooltip title={campaign.dealer.url}>
                                    <Paper elevation={4}>
                                        <a
                                            href={campaign.dealer.url}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                            }}
                                            target="new"
                                        >
                                            <img alt="" height="40px" src={campaign.dealer.logoUrl} />
                                        </a>
                                    </Paper>
                                </Tooltip>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                sx={{
                                    '&': {
                                        width: '100%',
                                    },
                                    '& .MuiAutocomplete-root': {
                                        width: '100%',
                                    },
                                }}
                            >
                                <ProductCategoryAutocomplete
                                    hideCount={true}
                                    hideDepthOption={true}
                                    disabled={!campaign.dealer}
                                    disableCloseOnSelect
                                    label="Product Category"
                                    selectedCategories={productCategories.filter(
                                        (productCategory: ProductCategory) => productCategory.name.length > 0
                                    )}
                                    onSelectedCategories={(categories, reason) => {
                                        setProductCategories(categories);
                                    }}
                                    selections={selections}
                                    fetchSelections={fetchSelections}
                                    // filterSelections={(_selections: ProductCategory[]): ProductCategory[] => {
                                    //     return _selections.filter((_selection: ProductCategory) => {
                                    //         return !_selection.parentId;
                                    //     });
                                    // }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Column>
            </CardContent>
        </Card>
    );
}
