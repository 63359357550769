import { Agency } from '../../types/Agency';
import { CDTextField } from '../../components/CDTextField';
import { ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import CDGrid from '../../components/CDGrid';

export default function FirstPartyDataIntegration(props: { agency: Agency; onChange(value: Partial<Agency>): void }) {
    const { agency, onChange } = props;

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        let agency = { [event.target.name]: event.target.value };
        onChange(agency);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <CDTextField
                        fullWidth
                        label="FTP User"
                        name="firstPartyDataFtpUser"
                        autoFocus={true}
                        onChange={handleChange}
                        value={agency.firstPartyDataFtpUser}
                    />
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={4}>
                    <CDTextField
                        fullWidth
                        label="Failure Email Notifications"
                        name="firstPartyDataFailureEmail"
                        autoFocus={true}
                        onChange={handleChange}
                        value={agency.firstPartyDataFailureEmail}
                    />
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={4}>
                    <CDTextField
                        fullWidth
                        label="Results Email Notifications"
                        name="firstPartyDataResultsEmail"
                        autoFocus={true}
                        onChange={handleChange}
                        value={agency.firstPartyDataResultsEmail}
                    />
                </Grid>
                <Grid item xs={8} />

                <Grid item xs={12}>
                    <CDGrid
                        columns={[
                            {
                                field: 'dealer',
                                headerName: 'Advertiser',
                                width: 170,
                                valueGetter: (params) => params.value.dealerName,
                            },
                            {
                                field: 'fileName',
                                headerName: 'File Name',
                                width: 170,
                            },
                            {
                                field: 'dataSetName',
                                headerName: 'Data Type',
                                width: 170,
                            },
                        ]}
                        rows={agency.agencyFirstPartyDealers ? agency.agencyFirstPartyDealers : []}
                    />
                </Grid>
            </Grid>
        </>
    );
}
