import { useCallback, useMemo, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';

import CountChip from '../CountChip';
import Row from '../Row';

import { AudienceSource } from '../../types/AudienceSource';
import { AudienceSourceCategoryGroup } from '../../types/AudienceSourceCategory';
import AudienceSourceDrawer from './AudienceSourceDrawer';

export type AudienceSourceFieldProps = {
    mediaType?: string;
    selections?: AudienceSource[];
    fetchSelections?: () => void;
    hydrateSelections?: (selections: AudienceSource[]) => AudienceSource[];
    onChange?: (items: AudienceSource[]) => void;
    items?: AudienceSource[];

    limit?: number;
    disableLimit?: boolean;
};

export default function AudienceSourceField(props: AudienceSourceFieldProps) {
    const { items, mediaType, onChange, selections = [], fetchSelections, hydrateSelections } = props;
    const { limit = 5, disableLimit = false } = props;

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [showLimit, setShowLimit] = useState<boolean>(false);

    const audienceCategoryGroups = useMemo(() => {
        const groups: AudienceSourceCategoryGroup[] = [];
        const group: { [key: string]: AudienceSourceCategoryGroup } = {};

        if (items) {
            items.forEach((item: AudienceSource) => {
                let code: string =
                    item.category && item.subCategory ? `${item.category} > ${item.subCategory}` : item.category;
                if (code) {
                    if (!group[code]) {
                        group[code] = new AudienceSourceCategoryGroup();
                        group[code].category = code;
                        groups.push(group[code]);
                    }
                    group[code].audienceSources.push(item);
                }
            });
        }

        return groups;
    }, [items]);

    const limitedAudienceCategoryGroups = useMemo(() => {
        if (!showLimit) {
            return audienceCategoryGroups.slice(0, limit);
        }
        return audienceCategoryGroups;
    }, [audienceCategoryGroups, showLimit, limit]);

    const showMoreLimit = useMemo((): boolean => {
        if (audienceCategoryGroups.length > limit) {
            return !showLimit;
        }
        return false;
    }, [audienceCategoryGroups, showLimit, limit]);

    const showLessLimit = useMemo((): boolean => {
        if (audienceCategoryGroups.length > limit) {
            return showLimit;
        }
        return false;
    }, [audienceCategoryGroups, showLimit, limit]);

    const handleDrawerChange = (items: AudienceSource[]): void => {
        if (onChange) {
            onChange(items);
        }
    };

    const handleDrawerClose = useCallback(
        (items: AudienceSource[], reason: string) => {
            setDrawerOpen(false);

            if (['save'].includes(reason)) {
                if (onChange) {
                    onChange(items);
                }
            }
        },
        [onChange]
    );

    return (
        <Stack gap={3}>
            <Box>
                <Row useFlexGap flexWrap="wrap" gap={1} sx={{ width: '100%' }}>
                    {limitedAudienceCategoryGroups.map((audienceCategoryGroup: AudienceSourceCategoryGroup) => {
                        return (
                            <CountChip
                                key={audienceCategoryGroup.category}
                                variant="outlined"
                                label={audienceCategoryGroup.category}
                                count={audienceCategoryGroup.audienceSources.length}
                                // hideZeroCount={true}
                            />
                        );
                    })}
                    {!disableLimit && (
                        <>
                            {showMoreLimit && (
                                <Button variant="text" onClick={() => setShowLimit(true)}>
                                    Show More
                                </Button>
                            )}
                            {showLessLimit && (
                                <Button variant="text" onClick={() => setShowLimit(false)}>
                                    Show Less
                                </Button>
                            )}
                        </>
                    )}
                </Row>
            </Box>

            {onChange && (
                <Box>
                    <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                        {audienceCategoryGroups.length > 0 ? 'Edit' : 'Select'}
                    </Button>
                </Box>
            )}

            {fetchSelections !== undefined && (
                <AudienceSourceDrawer
                    mediaType={mediaType}
                    selections={selections}
                    fetchSelections={fetchSelections}
                    hydrateSelections={hydrateSelections}
                    open={drawerOpen}
                    items={items}
                    onChange={handleDrawerChange}
                    onClose={handleDrawerClose}
                />
            )}
        </Stack>
    );
}
