import { useContext, useEffect } from 'react';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import {
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    UpdateOutlined as UpdateOutlinedIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { SectionHeading } from '../../components/SectionHeading';
import { UserContext } from '../../App';
import Column from '../../components/Column';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import useAdvertiserAttribution from '../../hooks/useAdvertiserAttribution';
import usePolkAttribution from '../../hooks/usePolkAttribution';
import PolkMatchbackMetrics from './PolkMatchbackMetrics';
import PolkMatchbackDataTabs from './PolkMatchbackDataTabs';

export default function PolkMatchbackTab() {
    const { userContext } = useContext(UserContext);
    const {
        dealer,
        attributionDate,
        attributionStatusUpdateDateFormatted,
        // attributionStatus,
        fetchAttributionStatus,
    } = useAttributionPageContext();

    const { fetchPerformances, fetchPreviousPerformances } = useAdvertiserAttribution();
    const { fetchPolkSalesWorkflows } = usePolkAttribution();

    useEffect(() => {
        fetchAttributionStatus();
    }, [fetchAttributionStatus]);

    useEffect(() => {
        fetchPolkSalesWorkflows();
    }, [fetchPolkSalesWorkflows]);

    useEffect(() => {
        fetchPerformances();
    }, [fetchPerformances]);

    useEffect(() => {
        fetchPreviousPerformances();
    }, [fetchPreviousPerformances]);

    return (
        <Column gap={3} sx={{ mt: (theme) => theme.spacing(1) }}>
            {attributionStatusUpdateDateFormatted && (
                <Box>
                    <Row gap={0.5}>
                        <UpdateOutlinedIcon color="success" fontSize="small" />
                        <Typography variant="body2" color="text.secondary">
                            Last updated {attributionStatusUpdateDateFormatted}
                        </Typography>
                    </Row>
                </Box>
            )}

            <Printable show={true}>
                <>
                    {dealer && dealer.logoUrl && dealer.logoUrl.length > 0 && (
                        <Row justifyContent="center" alignItems="center">
                            <img src={dealer.logoUrl} alt={`${dealer.dealerName}'s Logo`} style={{ maxHeight: 128 }} />
                        </Row>
                    )}
                    <Row justifyContent="center" alignItems="center">
                        <Typography variant="h5">
                            {moment(Utils.getDate(attributionDate)).format('MMMM YYYY')}
                        </Typography>
                    </Row>
                </>
            </Printable>

            <div>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Column gap={0.5}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <SectionHeading divider={false}>Overview</SectionHeading>

                            <Printable show={false}>
                                <Tooltip title="Sales data provided by S&P Global Mobility aka Polk Automotive Solutions">
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            </Printable>
                        </Stack>
                    </Column>

                    <Printable show={false}>
                        {userContext.isAdvertiser() ? (
                            <>
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="medium"
                                    component={Link}
                                    to={`mailto:support@cognitionads.com?subject=Request to Map Polk Data for Advertiser`}
                                >
                                    Update Advertisers & Competitors
                                </Button>
                            </>
                        ) : (
                            <>
                                {dealer && (
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="medium"
                                        component={Link}
                                        to={`/advertisers/${dealer.id}/#polkSales`}
                                    >
                                        Update Advertisers & Competitors
                                    </Button>
                                )}
                            </>
                        )}
                    </Printable>
                </Stack>
            </div>

            <div>
                <PolkMatchbackMetrics />
            </div>

            <Box>
                <PolkMatchbackDataTabs />
            </Box>

            <Box
                sx={{
                    borderTop: 1,
                    borderColor: 'divider',
                    py: 2,
                }}
            >
                <Stack direction="row" spacing={1.5} justifyContent="center" alignItems="center">
                    <Typography variant="body2" color="text.primary">
                        Powered By
                    </Typography>

                    <img
                        src="/S&P_Global_Mobility.png"
                        alt="Powered By S&P Global Mobility"
                        style={{
                            height: 30,
                        }}
                    />
                </Stack>
            </Box>
        </Column>
    );
}
