import { useMemo } from 'react';

import Utils from '../../../../components/Utils';
import CDDateRangePicker from '../../../../components/CDDateRangePicker';
import { isSameDay, isSameMonth } from 'date-fns';
import { Range, StaticRange } from 'react-date-range';

export type ReportingCriteriaDataRangePickerProps = {
    onChange(startDate?: Date, endDate?: Date): void;
    startDate: Date;
    endDate: Date;
    minDate?: Date;
    maxDate?: Date;
};

export default function ReportingCriteriaDataRangePicker(props: ReportingCriteriaDataRangePickerProps) {
    const { onChange, startDate, endDate, minDate = new Date(), maxDate = new Date() } = props;

    const ranges = useMemo(() => {
        const _ranges: StaticRange[] = [
            {
                label: 'Last Month',
                range() {
                    return {
                        startDate: Utils.getMonthStart(-1),
                        endDate: Utils.getMonthEnd(-1),
                    };
                },
                isSelected: (range: Range) => {
                    if (range.startDate && range.endDate) {
                        return (
                            isSameDay(range.startDate, Utils.getMonthStart(-1)) &&
                            isSameDay(range.endDate, Utils.getMonthEnd(-1))
                        );
                    } else {
                        return false;
                    }
                },
            },
        ];

        if (isSameMonth(maxDate, new Date())) {
            _ranges.push({
                label: 'This Month',
                range() {
                    return {
                        startDate: Utils.getMonthStart(0),
                        endDate: new Date(),
                    };
                },
                isSelected: (range: Range) => {
                    if (range.startDate && range.endDate) {
                        return (
                            isSameDay(range.startDate, Utils.getMonthStart(0)) && isSameDay(range.endDate, new Date())
                        );
                    } else {
                        return false;
                    }
                },
            });
        }

        return _ranges;
    }, [maxDate]);

    return (
        <CDDateRangePicker
            label="Date Range"
            ranges={ranges}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            disableFuture={true}
            sx={{ mb: 1 }}
            onChange={onChange}
        />
    );
}
