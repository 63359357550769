import { useCallback } from 'react';
import moment from 'moment-timezone';

import ApiService from '../ApiService';

import { useAttributionPageContext } from './useAttributionPage';

export default function useAdvertiserAttribution() {
    const {
        dealer,
        attributionDate,

        setPerformances,
        setPreviousPerformances,

        setIsFetchingPerformances,
        setIsFetchingPreviousPerformances,
    } = useAttributionPageContext();

    const fetchPerformances = useCallback(() => {
        let ignore = false;

        const dealerId = dealer?.id ?? 0;

        if (dealerId > 0 && attributionDate) {
            setIsFetchingPerformances(true);

            ApiService.getCampaignPerformanceList({
                dealerId: dealerId,
                startDate: moment.utc(moment(attributionDate).format('YYYY-MM-DD HH:mm:ss')).toDate(),
                endDate: moment.utc(moment(attributionDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')).toDate(),
            }).then((response) => {
                if (!ignore) {
                    setIsFetchingPerformances(false);
                    setPerformances(response.data);
                }
            });
        }

        return () => {
            ignore = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer, attributionDate]);

    const fetchPreviousPerformances = useCallback(() => {
        let ignore = false;

        const dealerId = dealer?.id ?? 0;

        if (dealerId > 0 && attributionDate) {
            setIsFetchingPreviousPerformances(true);

            ApiService.getCampaignPerformanceList({
                dealerId: dealerId,
                startDate: moment
                    .utc(moment(attributionDate).subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'))
                    .toDate(),
                endDate: moment
                    .utc(moment(attributionDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'))
                    .toDate(),
            }).then((response) => {
                if (!ignore) {
                    setIsFetchingPreviousPerformances(false);
                    setPreviousPerformances(response.data);
                }
            });
        }

        return () => {
            ignore = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer, attributionDate]);

    return {
        fetchPerformances,
        fetchPreviousPerformances,
    };
}
