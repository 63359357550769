import { gridClasses, GridColumns, GridRowId, GridRowParams, GridToolbarContainer } from '@mui/x-data-grid';
import { Box, Button, Link, Tooltip } from '@mui/material';
import CDGrid from '../../components/CDGrid';
import { OemOffer } from '../../types/OemOffer';
import Utils from '../../components/Utils';
import theme from '../../theme';
import { OemOfferStatus } from './OemOfferStatus';
import { useContext, useState } from 'react';
import ApiService from '../../ApiService';
import OfferMediaLink from './OfferMediaLink';
import CDButton from '../../components/CDButton';
import { InfoMessageContext } from '../../components/GlobalInfoMessage';

export default function OemOffersGrid(props: {
    oemOffers: OemOffer[];
    offerStatus: string;
    onClick: (oemOffer: OemOffer) => void;
    refresh: () => void;
}) {
    const { oemOffers, offerStatus, onClick, refresh } = props;
    const [selectedItems, setSelectedItems] = useState<GridRowId[]>([]);
    const { showMessage } = useContext(InfoMessageContext);

    const columns: GridColumns = [
        {
            field: 'offerStatusDate',
            headerName: 'Status Date',
            flex: 1,
            minWidth: 145,
            valueGetter: (params) => {
                return Utils.formatDateTime(params.value);
            },
            renderCell: (params) => {
                let diff = new Date().getTime() - params.row.offerStatusDate;
                diff = diff / (1000 * 60 * 60 * 24);
                return (
                    <Box
                        sx={{
                            color:
                                offerStatus !== 'Complete' && diff > 1
                                    ? theme.palette.error.main
                                    : theme.palette.text.primary,
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
    ];
    if (offerStatus === OemOfferStatus.NEW || offerStatus === OemOfferStatus.NO_ADVERTISER) {
        columns.push({
            field: 'pcAdvertiserName',
            headerName: 'PureCars Advertiser',
            description: 'PureCars Advertiser Name',
            flex: 1,
            minWidth: 120,
        });
    }
    columns.push(
        {
            field: 'dealer',
            headerName: 'Advertiser',
            description: 'Cognition Advertiser',
            flex: 1,
            minWidth: 120,
            valueGetter: (params) => {
                return params.value ? params.value.dealerName : null;
            },
        },
        {
            field: 'pcOutputName',
            headerName: 'Output Name',
            flex: 3,
        },
        {
            field: 'pcMake',
            headerName: 'Make / Model',
            valueGetter: (params) => {
                return params.row.pcMake + ' ' + params.row.pcModel;
            },
            flex: 1,
            minWidth: 120,
        }
    );

    if (offerStatus === 'Video Received' || offerStatus === 'Video Error' || offerStatus === 'Complete') {
        columns.push({
            field: 'ctRenderStatus',
            headerName: 'Render Status',
            flex: 1,
            minWidth: 120,
        });
    }

    if (offerStatus === 'Video Error') {
        columns.push({
            field: 'ctStatusMessage',
            headerName: 'Error Message',
            flex: 1,
            minWidth: 120,
        });
    }

    columns.push(
        {
            field: 'pcVideo',
            headerName: 'Video',
            renderCell: (params) => {
                return <OfferMediaLink offerMedia={params.value} />;
            },
        },
        {
            field: 'pcAudio',
            headerName: 'Audio',
            renderCell: (params) => {
                return <OfferMediaLink offerMedia={params.value} />;
            },
        },
        {
            field: 'pcVehicleImage',
            headerName: 'Vehicle Image',
            renderCell: (params) => {
                return <OfferMediaLink offerMedia={params.value} />;
            },
        },
        {
            field: 'pcSalesLogo',
            headerName: 'Sales Logo',
            renderCell: (params) => {
                return <OfferMediaLink offerMedia={params.value} />;
            },
        },

        {
            field: 'ctVideoUrl',
            headerName: 'Rendered Video',
            renderCell: (params) => {
                return (
                    <Link target="new" href={params.value}>
                        {params.value}
                    </Link>
                );
            },
        }
    );

    function updateStatus(status: string): void {
        ApiService.updateOfferStatus(status, selectedItems).then((response) => {
            refresh();
        });
    }

    function deleteOemOffers(): void {
        ApiService.deleteOemOffers(selectedItems).then((response) => {
            refresh();
        });
    }

    function importOffers(): void {
        ApiService.importOemOffers().then(() => {
            showMessage('success', 'Import offers in progress');
        });
    }

    function exportOffers(): void {
        ApiService.exportOemOffers().then(() => {
            showMessage('success', 'Export offers in progress');
        });
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <Tooltip title="Import offers from PureCars">
                    <span>
                        <CDButton sx={{ margin: '5px' }} onClick={importOffers}>
                            Import
                        </CDButton>
                    </span>
                </Tooltip>
                <Tooltip title="Export offers to Cartender">
                    <span>
                        <CDButton sx={{ margin: '5px' }} onClick={exportOffers}>
                            Export
                        </CDButton>
                    </span>
                </Tooltip>

                <div style={{ flexGrow: 1 }} />
                <Tooltip title="Ready for Export to Cartender">
                    <span>
                        <Button
                            sx={{ margin: '5px' }}
                            onClick={() => updateStatus(OemOfferStatus.READY)}
                            color="primary"
                            variant="outlined"
                            disabled={
                                selectedItems.length === 0 ||
                                [
                                    OemOfferStatus.COMPLETE,
                                    OemOfferStatus.NO_ADVERTISER,
                                    OemOfferStatus.READY,
                                    OemOfferStatus.VIDEO_REQUESTED,
                                    OemOfferStatus.VIDEO_RECEIVED,
                                ].includes(offerStatus)
                            }
                        >
                            Ready
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title="Mark as Complete">
                    <span>
                        <Button
                            sx={{ margin: '5px' }}
                            onClick={() => updateStatus(OemOfferStatus.COMPLETE)}
                            color="primary"
                            variant="outlined"
                            disabled={
                                selectedItems.length === 0 ||
                                [
                                    OemOfferStatus.COMPLETE,
                                    OemOfferStatus.NEW,
                                    OemOfferStatus.UPDATED,
                                    OemOfferStatus.NO_ADVERTISER,
                                    OemOfferStatus.READY,
                                    OemOfferStatus.VIDEO_REQUESTED,
                                ].includes(offerStatus)
                            }
                        >
                            Complete
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title="Delete Offers">
                    <span>
                        <Button
                            sx={{ margin: '5px' }}
                            onClick={() => deleteOemOffers()}
                            color="error"
                            variant="outlined"
                            disabled={selectedItems.length === 0 || [OemOfferStatus.COMPLETE].includes(offerStatus)}
                        >
                            Delete
                        </Button>
                    </span>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <CDGrid
                columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    pcDealershipName: false,
                }}
                onRowClick={(params: GridRowParams) => onClick(params.row)}
                rows={oemOffers}
                columns={columns}
                selectionEnabled={[
                    OemOfferStatus.NEW,
                    OemOfferStatus.UPDATED,
                    OemOfferStatus.VIDEO_RECEIVED,
                    OemOfferStatus.VIDEO_ERROR,
                ].includes(offerStatus)}
                selectionsChanged={(selectionModel) => {
                    setSelectedItems(selectionModel);
                }}
                disableSelectionOnclick={true}
                components={{ Toolbar: CustomToolbar }}
            />
        </>
    );
}
