import Heading from '../../../components/Heading';
import { useEffect, useState } from 'react';
import { Autocomplete, Box, Button } from '@mui/material';
import { AMCWorkflow } from '../../../types/AMCData';
import ApiService from '../../../ApiService';
import { AddOutlined } from '@mui/icons-material';
import { gridClasses, GridRowParams, GridToolbarContainer } from '@mui/x-data-grid';
import { AmcInstance } from '../../../types/AmcInstance';
import { useNavigate } from 'react-router-dom';
import CDGrid from '../../../components/CDGrid';
import { CDTextField } from '../../../components/CDTextField';

export default function AmcWorkflowsPage() {
    const navigate = useNavigate();
    let id = '';
    if (localStorage.getItem('amcInstanceId')) {
        id = localStorage.getItem('amcInstanceId') as string;
    }
    const [amcInstanceId, setAmcInstanceId] = useState<string>(id);
    const [amcInstance, setAmcInstance] = useState<AmcInstance | undefined>();
    const [amcInstances, setAmcInstances] = useState<AmcInstance[]>([]);
    const [workflows, setWorkflows] = useState<AMCWorkflow[] | null>([]);

    useEffect(() => {
        ApiService.getAmcInstances()
            .then((response) => {
                setAmcInstances(response.data);
                setAmcInstance(response.data.find((i) => i.id + '' === id));
            })
            .catch(() => {});
        if (amcInstanceId !== '') {
            getWorkflows(amcInstanceId);
        }
    }, [amcInstanceId, id]);

    function onSelectInstance(_event: any, newValue: AmcInstance | null) {
        if (newValue) {
            let amcInstanceId = newValue.id + '';
            let amcInstance = amcInstances.find((i) => i.id + '' === amcInstanceId);
            setAmcInstance(amcInstance);
            localStorage.setItem('amcInstanceId', amcInstanceId);
            setAmcInstanceId(amcInstanceId);
            getWorkflows(amcInstanceId);
        }
    }

    function getWorkflows(amcInstanceId: string) {
        setWorkflows(null);
        ApiService.getAmcWorkflows(+amcInstanceId)
            .then((response) => {
                setWorkflows(response.data);
            })
            .catch(() => {
                setWorkflows([]);
            });
    }

    function handleAdd() {
        navigate('new', {
            state: {
                id: null,
                sqlQuery: '',
                isNew: true,
                amcInstance: amcInstance,
            },
        });
    }

    function handleEditWorkflow(params: GridRowParams) {
        if (params && params.row) {
            navigate(params.row.workflowId, {
                state: {
                    ...params.row,
                    isNew: false,
                    amcInstance: amcInstance,
                },
            });
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <Autocomplete
                    options={amcInstances}
                    value={amcInstance}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    isOptionEqualToValue={(option, value) => option?.id + '' === value?.id + ''}
                    size="small"
                    fullWidth={false}
                    onChange={onSelectInstance}
                    renderInput={(params) => (
                        <CDTextField
                            style={{
                                width: '400px',
                                marginLeft: '10px',
                                marginTop: '10px',
                            }}
                            color="primary"
                            shrinkLabel={false}
                            required={true}
                            variant="outlined"
                            {...params}
                            label="AMC Instance"
                            value={amcInstanceId}
                        />
                    )}
                />
                <div style={{ flexGrow: 1 }} />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAdd}
                    disabled={amcInstanceId === ''}
                    startIcon={<AddOutlined />}
                >
                    Add
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Box
            sx={{
                '& .row': {
                    cursor: 'pointer',
                },
            }}
        >
            <Heading>AMC Workflows</Heading>
            <div style={{ height: '100%', width: '100%' }}>
                <CDGrid
                    components={{ Toolbar: CustomToolbar }}
                    getRowId={(row) => row.workflowId}
                    columns={[
                        {
                            width: 400,
                            field: 'workflowId',
                            headerName: 'Workflow ID',
                            renderCell: (params) => (
                                <Button
                                    onClick={() => {
                                        handleEditWorkflow(params.value);
                                    }}
                                >
                                    {params.value}
                                </Button>
                            ),
                        },
                        {
                            width: 200,
                            field: 'sqlQuery',
                            headerName: 'SQL',
                            flex: 1,
                        },
                    ]}
                    rows={workflows === null ? [] : workflows}
                    onRowClick={handleEditWorkflow}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </div>
        </Box>
    );
}
