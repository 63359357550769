import { useCallback, useRef, useState } from 'react';
import axios from 'axios';

interface MapTokenState {
    mapToken: string | null;
    setMapToken: (mapToken: string | null) => void;
    fetchMapToken: () => void;
}

export function useMapToken(): MapTokenState {
    const [mapToken, setMapToken] = useState<string | null>(null);
    const isFetched = useRef<boolean>(false);

    const fetchMapToken = useCallback(() => {
        if (isFetched.current === false) {
            isFetched.current = true;

            axios.get('/api/geographicTargeting/token').then((response) => {
                setMapToken(response.data);
            });
        }
    }, []);

    return {
        mapToken,
        setMapToken,
        fetchMapToken,
    };
}
