import { useCallback, useEffect } from 'react';
import { Stepper, Step as BaseStep, Typography } from '@mui/material';

import { Stepper as StepperObject } from '../../../../hooks/useStepper';
import { StepButton, StepContent, StepLabel } from './CampaignWizardStyles';
import CampaignWizardGeographyStepNav from './CampaignWizardGeographyStepNav';
import CampaignWizardSetupStepNav from './CampaignWizardSetupStepNav';

type Step = {
    number: number;
    label: string;
    description: string;
};

const steps: Step[] = [
    {
        number: 0,
        label: 'Campaign Setup',
        description: ``,
    },
    {
        number: 1,
        label: 'Targeting',
        description: '',
    },
    {
        number: 2,
        label: 'Creative',
        description: ``,
    },
    {
        number: 3,
        label: 'Review & Submit',
        description: ``,
    },
];

type CampaignWizardStepNavProps = {
    // step: number;
    stepper: StepperObject;
    onStep?: (step: number) => void;
};

export default function CampaignWizardStepNav(props: CampaignWizardStepNavProps) {
    const { onStep, stepper } = props;
    const { activeStep, toStep } = stepper;

    useEffect(() => {
        if (onStep) {
            onStep(activeStep);
        }
    }, [activeStep, onStep]);

    const handleStep = (step: Step): void => {
        toStep(step.number);
    };

    const handleCampaignWizardSetupStepChange = useCallback((step: number): void => {
        let elementId: string = '';
        let element;

        switch (step) {
            case 1:
                elementId = 'campaign-wizard-setup-advertiser';
                break;
            case 2:
                elementId = 'campaign-wizard-setup-plan';
                break;
            case 3:
                elementId = 'campaign-wizard-setup-inventory';
                break;
            case 4:
                elementId = 'campaign-wizard-setup-delivery';
                break;
            case 5:
                elementId = 'campaign-wizard-setup-optimization';
                break;
            case 6:
                elementId = 'campaign-wizard-setup-frequency';
                break;
        }

        if (elementId) {
            element = document.getElementById(elementId);

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, []);

    const handleCampaignWizardGeograhpyStepChange = useCallback((step: number): void => {
        let elementId: string = '';
        let element;

        switch (step) {
            case 1:
                elementId = 'campaign-wizard-targeting-location';
                break;
            case 2:
                elementId = 'campaign-wizard-targeting-audience';
                break;
        }

        if (elementId) {
            element = document.getElementById(elementId);

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, []);

    return (
        <Stepper activeStep={activeStep} connector={<></>} orientation="vertical">
            {steps.map((step: Step, index) => (
                <BaseStep key={step.label}>
                    <StepButton
                        onClick={() => handleStep(step)}
                        sx={{
                            boxSizing: 'inherit',
                            margin: 0,
                            py: 0,
                        }}
                    >
                        <StepLabel>{step.label}</StepLabel>
                    </StepButton>
                    <StepContent>
                        <Typography>{step.description}</Typography>

                        {index === 0 && <CampaignWizardSetupStepNav onStep={handleCampaignWizardSetupStepChange} />}

                        {index === 1 && (
                            <CampaignWizardGeographyStepNav onStep={handleCampaignWizardGeograhpyStepChange} />
                        )}
                    </StepContent>
                </BaseStep>
            ))}
        </Stepper>
    );
}
