import { DataGrid, gridClasses, GridToolbarContainer, GridValueFormatterParams } from '@mui/x-data-grid';
import Utils from '../../../components/Utils';
import { AlertColor, Backdrop, Box, Button, Chip, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import { AMCWorkflowExecution } from '../../../types/AMCData';
import { RefreshOutlined } from '@mui/icons-material';
import { InfoMessage, InfoMessageProps } from '../../../components/InfoMessage';

export interface WorkflowExecutionsGridProps {
    workflowId: string;
    amcInstanceId: number;
}

export default function AmcWorkflowExecutionsGrid(props: WorkflowExecutionsGridProps) {
    const [workflowExecutions, setWorkflowExecutions] = useState<AMCWorkflowExecution[] | null>(null);
    const [busy, setBusy] = useState(false);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleDownload(workflowExecutionId: string) {
        setBusy(true);
        ApiService.getWorkflowExecutionResultFile(props.amcInstanceId, workflowExecutionId)
            .then((response) => {
                Utils.downloadFile(response, 'text/csv');
                setBusy(false);
            })
            .catch((error) => {
                setBusy(false);
                console.log(error);
                showInfoMessage('error', error.response.data);
            });
    }

    function refresh() {
        setWorkflowExecutions(null);
        ApiService.getWorkflowExecutions(props.amcInstanceId, props.workflowId)
            .then((response) => {
                setWorkflowExecutions(response.data);
            })
            .catch((error) => {
                showInfoMessage('error', error.response.data);
            });
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <div style={{ flexGrow: 1 }} />
                <Button variant="text" startIcon={<RefreshOutlined />} onClick={refresh}>
                    Refresh
                </Button>
            </GridToolbarContainer>
        );
    }

    let uploadMessage;
    if (busy) {
        uploadMessage = (
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
            >
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </Backdrop>
        );
    }

    return (
        <>
            {uploadMessage}
            <InfoMessage {...infoMessage} />
            <Box>
                <DataGrid
                    autoHeight
                    components={{ Toolbar: CustomToolbar }}
                    getRowId={(row) => row.workflowExecutionId}
                    columns={[
                        {
                            width: 170,
                            field: 'lastUpdatedTime',
                            headerName: 'Date Executed',
                            headerClassName: 'grid-header',
                            valueFormatter: (params: GridValueFormatterParams) => {
                                const date = new Date(params.value as string);
                                return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
                            },
                        },
                        {
                            width: 120,
                            field: 'status',
                            headerName: 'Status',
                            headerClassName: 'grid-header',
                            valueFormatter: (params) => Utils.convertCase(params.value as string),
                            renderCell: (params) => {
                                const value = Utils.convertCase(params.value as string);
                                return (
                                    <Chip
                                        label={value}
                                        size="small"
                                        color={
                                            value === 'Succeeded' ? 'success' : value === 'Failed' ? 'error' : 'info'
                                        }
                                    />
                                );
                            },
                        },
                        {
                            width: 100,
                            field: 'timeWindowStart',
                            headerName: 'Start Date',
                            headerClassName: 'grid-header',
                            valueFormatter: (params: GridValueFormatterParams) => {
                                return Utils.formatDateUTC(params.value as Date);
                            },
                        },
                        {
                            width: 100,
                            field: 'timeWindowEnd',
                            headerName: 'End Date',
                            headerClassName: 'grid-header',
                            valueFormatter: (params: GridValueFormatterParams) => {
                                return Utils.formatDateUTC(params.value as Date);
                            },
                        },
                        {
                            field: 'outputS3URI',
                            headerName: 'Results',
                            headerClassName: 'grid-header',
                            flex: 1,
                            renderCell: (params) => {
                                if (
                                    params.row.status === 'PENDING' ||
                                    params.row.status === 'RUNNING' ||
                                    params.row.status === 'PUBLISHING'
                                ) {
                                    return (
                                        <>
                                            <CircularProgress size="20px" />
                                        </>
                                    );
                                }
                                if (params.row.status === 'SUCCEEDED') {
                                    let fileName = params.value as string;
                                    fileName = fileName.substr(fileName.lastIndexOf('/') + 1);
                                    return (
                                        <Button
                                            onClick={() => {
                                                handleDownload(params.row.workflowExecutionId);
                                            }}
                                        >
                                            {fileName}
                                        </Button>
                                    );
                                } else {
                                    return <span style={{ overflow: 'scroll' }}>{params.row.statusReason}</span>;
                                }
                            },
                        },
                    ]}
                    rows={workflowExecutions === null ? [] : workflowExecutions}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </Box>
        </>
    );
}
