import { useState, SyntheticEvent, useContext } from 'react';
import { Box, Tabs, Tab } from '@mui/material';

import { hasAccess } from '../../components/AccessGuard';
import { UserContext } from '../../App';
import { USER_GROUP_ADMIN } from '../../types/User';
import DspAdGroupDictsTab from './dspAdGroupDicts';
import Heading from '../../components/Heading';

const DataMappingPage = () => {
    const [value, setValue] = useState(0);
    const { userContext } = useContext(UserContext);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const TABS = [
        {
            id: 0,
            label: 'Ad Group Mapping',
            component: (
                <DspAdGroupDictsTab
                    activeTab={value}
                    tab={{
                        id: 0,
                        description:
                            'Map ad group name values to inventory name values based on the type of targeting strategy',
                    }}
                />
            ),
        },
    ];

    return (
        <>
            <Heading>Data Mapping</Heading>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    {TABS?.map((tab, index) => {
                        return (
                            hasAccess(userContext, USER_GROUP_ADMIN) && (
                                <Tab key={index} value={tab.id} label={tab.label} style={{ textTransform: 'none' }} />
                            )
                        );
                    })}
                </Tabs>
            </Box>

            {TABS?.map((tab, index) => {
                return <Box key={index}>{tab.component} </Box>;
            })}
        </>
    );
};

export default DataMappingPage;
