import { Stack, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AgencyContext, UserContext } from '../App';
import NotificationsButton from './NotificationsButton';
import AccessGuard from './AccessGuard';
import { USER_GROUP_ADMIN } from '../types/User';
import DealerSelector from './DealerSelector';
import { AdvertiserContext } from '../AdvertiserContext';
import { Dealer } from '../types/Dealer';
import AgencySelector from './AgencySelector';
import UserMenu from './UserMenu';

export interface CDToolBarProps {
    logout: Function;
    toggleMenu: Function;
    showMenu: Function;
}

const CDToolbar = function (props: CDToolBarProps) {
    const { userContext } = useContext(UserContext);
    const agency = useContext(AgencyContext);
    const { advertiserContext, setAdvertiserContext, setDealer } = useContext(AdvertiserContext);

    const location = useLocation();
    const { pathname } = location;

    const dealerSelected = useCallback(
        (dealerId, dealer: Dealer | null) => {
            setDealer(dealer === null ? new Dealer() : dealer);
        },
        [setDealer]
    );

    return (
        <Toolbar
            disableGutters
            className="App-toolbar"
            sx={{
                height: '64px',
                color: 'rgba(255, 255, 255, 0.7)',
                // paddingLeft: '22px',
                paddingRight: '22px',
                borderBottom: '1px solid #4D66A64D',
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
                sx={{
                    width: '100%',
                }}
            >
                <div
                    style={{
                        borderRight: '1px solid #4D66A64D',
                    }}
                >
                    {!agency ||
                        (!agency.logoUrl && (
                            <Link
                                to="campaignManager"
                                style={{
                                    height: '64px',
                                    width: '88px',
                                    paddingLeft: '22px',
                                    paddingRight: '22px',
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src="/Logomark.png" style={{ height: '40px', width: '40px' }} />
                            </Link>
                        ))}
                    {agency && agency.logoUrl && (
                        <img
                            style={{
                                margin: '0px 20px',
                                height: '40px',
                                width: '130px',
                            }}
                            alt="cognitionads.com"
                            src={agency && agency.logoUrl ? agency?.logoUrl : '/logo_mark.png'}
                        />
                    )}
                </div>

                <Stack direction="row" gap={2}>
                    {userContext.isAdmin() && (
                        <div
                            style={{
                                minWidth: '280px',
                            }}
                        >
                            <AgencySelector
                                isHeader={true}
                                agencySelected={(agencyId) => {
                                    setAdvertiserContext(null, agencyId);
                                }}
                                agencyId={advertiserContext.agencyId}
                                label={'Select an Agency View'}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            minWidth: '280px',
                        }}
                    >
                        <DealerSelector
                            isHeader={true}
                            agencyId={advertiserContext.agencyId}
                            required={false}
                            label={'Select an Advertiser View'}
                            variant="outlined"
                            dealerId={advertiserContext.dealer ? advertiserContext.dealer.id : null}
                            dealerSelected={dealerSelected}
                            dealerDefaultSelect={pathname === '/attribution' ? true : false}
                        />
                    </div>
                </Stack>

                <div style={{ flexGrow: 1 }} />

                <Stack direction="row" gap={1}>
                    <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                        <NotificationsButton />
                    </AccessGuard>

                    <UserMenu toolbarProps={props} />
                </Stack>

                {/*<AccessGuard accessGroup={USER_GROUP_ADMIN}>*/}
                {/*    <BuildInfoComponent />*/}
                {/*</AccessGuard>*/}
            </Stack>
        </Toolbar>
    );
};

export default CDToolbar;
