import {
    Alert,
    AlertColor,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Stack,
    TextField,
} from '@mui/material';
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { CloseOutlined, DeleteOutlined, SaveOutlined } from '@mui/icons-material';
import { InfoMessage, InfoMessageProps } from '../../../components/InfoMessage';
import { AmcInstance, ProvisionDetails } from '../../../types/AmcInstance';
import { AmcOwningEntity } from '../../../types/AmcOwningEntity';
import ApiService from '../../../ApiService';
import ConfirmDialog, { ConfirmDialogProps } from '../../../components/ConfirmDialog';
import { useLocation, useNavigate } from 'react-router-dom';

type AmcInstanceFormProps = {
    save?: boolean;
    size?: 'lg' | 'md' | 'sm';
    hideActions?: boolean;
    amcInstance: AmcInstance;
    // onSubmit: () => void;
    onValidate?: (validated: boolean) => void;
    onSave: (amcInstance: AmcInstance) => void;
};

export default function AmcInstanceForm(props: AmcInstanceFormProps) {
    const { onValidate } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const [amcInstance, setAmcInstance] = useState<AmcInstance>(props.amcInstance ?? location.state);
    const [amcOwningEntities, setAmcOwningEntities] = useState<AmcOwningEntity[]>([]);
    const [busy, setBusy] = useState(false);
    const [invalidDealer, setInvalidDealer] = useState(false);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [isDirty, setDirty] = useState(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const validated: boolean | undefined = useMemo(() => {
        if (amcInstance) {
            // if (!amcInstance?.owningEntityId) {
            //     return false;
            // }

            if (!amcInstance?.name) {
                return false;
            }

            if (!amcInstance?.instanceName) {
                return false;
            }

            if (!amcInstance?.instanceId) {
                return false;
            }

            if (!isDirty) {
                return false;
            }

            if (invalidDealer) {
                return false;
            }

            return true;
        }

        return false;
    }, [amcInstance, isDirty, invalidDealer]);

    useEffect(() => {
        ApiService.getAmcOwningEntities().then((response) => {
            setAmcOwningEntities(response.data);
        });
    }, []);

    useEffect(() => {
        if (props.save === true) {
            submit();
        }
    }, [props.save]);

    useEffect(() => {
        if (onValidate) {
            onValidate(validated);
        }
    }, [onValidate, validated]);

    const showActions: boolean | undefined = useMemo(() => {
        return props.hideActions ? false : true;
    }, [props.hideActions]);

    // const isEditing: boolean | undefined = useMemo(() => {
    //     return amcInstance.id > 0;
    // }, [amcInstance]);

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const field = event.target.name;
        let value = event.target.value;

        switch (field) {
            case 'instanceName':
            case 'instanceId':
                value = value.replace(/\s/g, ''); // Remove spaces
                break;
        }

        setAmcInstance({ ...amcInstance, [field]: value });
        setDirty(true);
        if (field === 'name') {
        }
    }

    function validateAdvertiser() {
        if (!amcInstance.name) return;
        ApiService.getDealerByName(amcInstance.name)
            .then((result) => {
                if (result.data?.dealerName) {
                    setInvalidDealer(false);
                } else {
                    setInvalidDealer(true);
                }
            })
            .catch(() => {
                setInvalidDealer(true);
            });
    }

    function provisionInstance() {
        const provisionDetails = new ProvisionDetails();
        provisionDetails.advertiserName = amcInstance.name;
        provisionDetails.instanceId = amcInstance.instanceId;
        provisionDetails.instanceName = amcInstance.instanceName;
        ApiService.provisionAmcInstance(provisionDetails)
            .then((response) => {
                showInfoMessage('success', 'AMC Instance has been provisioned.');
                setAmcInstance({ ...response.data, isNew: false });
                setDirty(false);

                props.onSave({ ...response.data, isNew: false });
            })
            .catch((error) => {
                showInfoMessage('error', error.message);
            });
    }

    function submit() {
        if (!amcInstance.id) {
            provisionInstance();
        } else {
            ApiService.updateAmcInstance(amcInstance)
                .then((response) => {
                    showInfoMessage('success', 'AMC Instance has been updated.');
                    setAmcInstance({ ...response.data, isNew: false });
                    setDirty(false);

                    props.onSave({ ...response.data, isNew: false });
                })
                .catch((error) => {
                    showInfoMessage('error', error.message);
                });
        }
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        submit();
    }

    function showDeleteConfirm() {
        setConfirmDialogProps({
            title: 'Delete AMC Instance ' + amcInstance.name + ' ?',
            text: '',
            onOk: () => deleteAmcInstance(),
            okText: 'Delete',
            okColor: 'error',
            okIcon: <DeleteOutlined />,
            onCancel: () => hideConfirm(),
        });
    }

    function hideConfirm() {
        setConfirmDialogProps(null);
    }

    function deleteAmcInstance() {
        hideConfirm();
        setBusy(true);
        if (amcInstance.id != null) {
            ApiService.deleteAmcInstance(amcInstance.id)
                .then(() => {
                    setBusy(false);
                    showInfoMessage('success', 'AMC Instance has been deleted.');
                    setTimeout(() => {
                        navigate('/amcInstances');
                    }, 2000);
                })
                .catch((error) => {
                    showInfoMessage('error', error.message);
                });
        }
    }

    let uploadMessage;
    if (busy) {
        uploadMessage = (
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
            >
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </Backdrop>
        );
    }

    return (
        <>
            <form method="post" onSubmit={handleSubmit}>
                {uploadMessage}

                {confirmDialogProps && (
                    <>
                        <ConfirmDialog
                            title={confirmDialogProps.title}
                            text={confirmDialogProps.text}
                            okText={confirmDialogProps.okText}
                            okIcon={confirmDialogProps.okIcon}
                            okColor={confirmDialogProps.okColor}
                            onCancel={confirmDialogProps.onCancel}
                            onOk={confirmDialogProps.onOk}
                        />
                    </>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={props.size === 'md' ? 5 : 7}>
                        <TextField
                            fullWidth
                            name="owningEntityId"
                            label="AMC Owning Entity"
                            select
                            required={true}
                            size="small"
                            SelectProps={{ native: false }}
                            value={amcInstance.owningEntityId}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        >
                            {amcOwningEntities.map((amcOwningEntity) => {
                                return <MenuItem value={amcOwningEntity.amcEntityId}>{amcOwningEntity.name}</MenuItem>;
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={props.size === 'md' ? 7 : 5} />

                    <Grid item xs={props.size === 'md' ? 5 : 7}>
                        <TextField
                            name="name"
                            label="Advertiser Name"
                            value={amcInstance.name}
                            required={true}
                            fullWidth={true}
                            size="small"
                            error={invalidDealer}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={validateAdvertiser}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={props.size === 'md' ? 7 : 5}>
                        {invalidDealer && <Alert severity="error">No Advertiser Found with that name</Alert>}
                    </Grid>

                    <Grid item xs={props.size === 'md' ? 5 : 7}>
                        <TextField
                            name="instanceName"
                            label="Instance Name"
                            value={amcInstance.instanceName}
                            required={true}
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={props.size === 'md' ? 7 : 5} />

                    <Grid item xs={props.size === 'md' ? 5 : 7}>
                        <TextField
                            name="instanceId"
                            label="Instance ID"
                            value={amcInstance.instanceId}
                            required={true}
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={props.size === 'md' ? 7 : 5} />

                    {amcInstance.id && (
                        <>
                            <Grid item xs={props.size === 'md' ? 5 : 7}>
                                <TextField
                                    name="awsAccountId"
                                    label="AWS Account ID"
                                    value={amcInstance.awsAccountId}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    disabled={true}
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={props.size === 'md' ? 7 : 5} />
                        </>
                    )}

                    <Grid item xs={12}></Grid>
                </Grid>

                {showActions && (
                    <>
                        <Divider />

                        <Grid container spacing={2}>
                            <Grid item md={12} style={{ marginTop: '30px' }}></Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        disabled={!isDirty}
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        startIcon={<SaveOutlined />}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate('/amcInstances')}
                                        color="error"
                                        startIcon={<CloseOutlined />}
                                    >
                                        Cancel
                                    </Button>
                                    <div style={{ flex: 1 }} />
                                    {!amcInstance.isNew && (
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            startIcon={<DeleteOutlined />}
                                            onClick={() => showDeleteConfirm()}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                )}
            </form>

            <Box sx={{ minHeight: '100%', p: 1 }}></Box>
            <InfoMessage {...infoMessage} />
        </>
    );
}
