import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Autocomplete,
    AutocompleteProps,
    Checkbox,
    Chip,
    Paper,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';

import { Dealer } from '../types/Dealer';
import ApiService from '../ApiService';

// interface AutocompleteOption {
//     id: number;
//     name: string;
//     label: string;
//     value: string;
// }

export type DealerAutocompleteProps = {
    agencies?: number[];
    onFetch?: (dealers: Dealer[]) => void;
    onFetching?: () => void;

    value?: number[];
    error?: boolean;
    label?: string;
    required?: boolean;
    variant?: TextFieldProps['variant'];
    TextFieldProps?: TextFieldProps;
} & Omit<AutocompleteProps<Dealer, true, false, false>, 'renderInput' | 'options' | 'value'>;

export default function DealerAutocomplete(props: DealerAutocompleteProps) {
    const { agencies = [], onFetch, onFetching, value = [], ...autocompleteProps } = props;

    const [dealers, setDealers] = useState<Dealer[]>([]);

    const fetchDealers = useCallback(() => {
        if (onFetching) {
            onFetching();
        }
        ApiService.getDealerList({ agencyIds: agencies, page: 1, pageSize: -1 })
            .then((response) => {
                if (onFetch) {
                    onFetch(response.data);
                }
                setDealers(response.data.filter((dealer: { activeStatus: boolean }) => dealer.activeStatus));
            })
            .catch();
    }, [agencies]);

    const selectedDealers = useMemo((): Dealer[] => {
        return dealers.filter((dealer: Dealer) => value.includes(dealer.id as number));
    }, [dealers, value]);

    const getOptionId = (dealer: Dealer): number => dealer.id as number;

    const getOptionLabel = (dealer: Dealer): string => dealer.dealerName;

    useEffect(() => {
        fetchDealers();
    }, [fetchDealers]);

    return (
        <Autocomplete
            multiple
            size="small"
            fullWidth={false}
            disableCloseOnSelect={true}
            value={selectedDealers}
            options={dealers}
            getOptionLabel={(option) => getOptionLabel(option)}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderTags={(tagValue, getTagProps) => {
                const tagValueCount: number = tagValue.length;

                const getChipLabel = (tagValue: any): string => {
                    return `${tagValueCount} Selected`;
                };

                if (tagValueCount > 1) {
                    const index = 0;
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            key={key}
                            label={getChipLabel(tagValue)}
                            size="small"
                            {...tagProps}
                            onDelete={() => console.log(tagValue)}
                        />
                    );
                }

                return (
                    <Typography sx={{ px: (theme) => theme.spacing(1) }}>
                        {tagValueCount > 0 ? getOptionLabel(tagValue[0]) : ''}
                    </Typography>
                );
            }}
            renderOption={(props, option, { selected }) => {
                const { ...optionProps } = props;
                return (
                    <li {...optionProps} key={getOptionId(option)}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon color="primary" />}
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {getOptionLabel(option)}
                    </li>
                );
            }}
            renderInput={(inputProps) => (
                <TextField
                    error={props.error}
                    label={props.label ?? 'Select an Advertiser'}
                    variant={props.variant ?? 'outlined'}
                    required={props.required !== undefined ? props.required : !props.disabled}
                    {...inputProps}
                    {...props.TextFieldProps}
                />
            )}
            PaperComponent={(paperComponentProps) => (
                <Paper
                    {...paperComponentProps}
                    sx={{
                        boxShadow: 8,
                        borderRadius: '8px',
                    }}
                />
            )}
            {...autocompleteProps}
        />
    );
}
