import { Dealer } from './Dealer';
import { DspEntity } from './DspEntity';
import { SasAccount } from './SasAccount';
import { CampaignFee } from './Campaign';

export class Agency {
    id?: number;
    name: string = '';
    hostname?: string = '';
    logoUrl: string = '';
    iconUrl?: string = '';
    dealers: Dealer[] = [];
    dashboardId: string | null = null;
    apiKey?: string | null;
    apiIncludeCostMetrics?: boolean = false;
    cpTemplate?: string = '';
    cpSource?: string = '';
    cpMedium?: string = '';
    cpCampaign?: string = '';
    cpTerm?: string = '';
    cpContent?: string = '';
    dspEntities: DspEntity[] = [];
    sasAccounts: SasAccount[] = [];
    crmDataNotifications?: string | null = null;
    activeStatus: boolean = true;
    activeCampaigns: boolean = true;
    activeReporting: boolean = true;
    activeAttribution: boolean = true;
    activeData: boolean = false;
    activeApiDocs: boolean = true;
    activeAdvertisers: boolean = true;
    activeCrmUpload: boolean = true;
    activeInsights: boolean = true;
    polkSalesIsActive: boolean = false;
    campaignFee?: CampaignFee = new CampaignFee();
    needsPolkAudiences: boolean = true;
    firstPartyDataFtpUser: string | null = null;
    firstPartyDataFailureEmail: string | null = null;
    firstPartyDataResultsEmail: string | null = null;
    timezone?: string = '';
    agencyFirstPartyDealers: AgencyFirstPartyDealer[] = [];
    gatewayAmazonDSPIsActive: number = 0;
}

export interface AgencyFirstPartyDealer {
    id?: number;
    agencyId?: number;
    dealerId?: number;
    dealer?: Dealer;
    fileName?: string;
    dataSetName?: string;
}
