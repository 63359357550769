import { useEffect, useMemo, useState } from 'react';
import { FormControl, MenuItem, TextField, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';

import { Performance } from '../../../../../types/CampaignPerformance';
import Printable from '../../../../../components/Printable';
import Row from '../../../../../components/Row';
import Utils from '../../../../../components/Utils';

import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import GroupedBarChartCard from '../../../../../components/Metrics/GroupedBarChartCard';

type PerformanceGroupedBarChartCardProps = {
    loading?: boolean;
};

export default function PerformanceGroupedBarChartCard(props: PerformanceGroupedBarChartCardProps) {
    const { loading = false } = props;
    const { performances, hasDisplayMediaType, hasVideoMediaType, hasAudioMediaType } =
        useReportingDashboardPageContext();

    const [mediaType, setMediaType] = useState<string>(() => {
        return Utils.localStorage('analytics.reporting.performanceGroupedBarChart', '');
    });

    const mediaTypes = useMemo((): string[] => {
        return ['Display', 'Video', 'Audio'].filter((_mediaType: string) => {
            switch (_mediaType) {
                case 'Display':
                    return hasDisplayMediaType;
                case 'Video':
                    return hasVideoMediaType;
                case 'Audio':
                    return hasAudioMediaType;
            }
            return false;
        });
    }, [hasDisplayMediaType, hasVideoMediaType, hasAudioMediaType]);

    const filteredPerformances = useMemo(() => {
        if (mediaType) {
            return performances.filter((p: Performance) => p.mediaType === mediaType);
        }
        return performances;
    }, [mediaType, performances]);

    const filteredMediaTypes = useMemo(() => {
        return mediaTypes.filter((_mediaType: string, _index: number) => {
            const total: number = filteredPerformances
                .filter((p: Performance) => p.mediaType === _mediaType)
                .map((p: Performance) => {
                    const total: number = p?.impressions + p?.dailyReach + p?.clickthroughs + p?.offAmazonConversions;
                    return total;
                })
                .reduce((total: number, value: number) => total + value, 0);

            return total > 0;
        });
    }, [filteredPerformances, mediaTypes]);

    const seriesData = useMemo(() => {
        if (filteredPerformances && filteredPerformances.length) {
            return [
                {
                    name: 'Exposures',
                    data: filteredMediaTypes.map((m: string) => {
                        return filteredPerformances
                            .filter((p: Performance) => p.mediaType === m)
                            .map((p: Performance) => p?.impressions)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Household Reach',
                    data: filteredMediaTypes.map((m: string) => {
                        return filteredPerformances
                            .filter((p: Performance) => p.mediaType === m)
                            .map((p: Performance) => p?.dailyReach)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Clicks',
                    data: filteredMediaTypes.map((m: string) => {
                        return filteredPerformances
                            .filter((p: Performance) => p.mediaType === m)
                            .map((p: Performance) => p?.clickthroughs)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Conversions',
                    data: filteredMediaTypes.map((m: string) => {
                        return filteredPerformances
                            .filter((p: Performance) => p.mediaType === m)
                            .map((p: Performance) => p?.offAmazonConversions)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
            ];
        }

        return [];
    }, [filteredPerformances, filteredMediaTypes]);

    const seriesDataLabels = useMemo(() => {
        return filteredMediaTypes;
    }, [filteredMediaTypes]);

    const seriesOptions = useMemo((): ApexOptions => {
        return {
            legend: {
                offsetY: 12,
                position: 'top',
            },
            xaxis: {
                categories: seriesDataLabels,
            },
        };
    }, [seriesDataLabels]);

    useEffect(() => {
        localStorage.setItem('analytics.reporting.performanceGroupedBarChart', mediaType);
    }, [mediaType]);

    // useEffect(() => {
    //     if (mediaTypes && mediaTypes.length) {
    //         if (!mediaTypes.includes(mediaType)) {
    //             setMediaType(mediaTypes[0]);
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [mediaTypes]);

    // useEffect(() => {
    //     if (mediaType) {
    //         setPerformances(getPerformances(mediaType));
    //     } else {
    //         setPerformances([]);
    //     }
    // }, [getPerformances, mediaType]);

    return (
        <GroupedBarChartCard
            loading={loading}
            label={
                <Row gap={1} alignItems="center" sx={{ mr: 0.5 }} width="auto">
                    <Printable show={true}>
                        <Typography variant="body1" color="text.primary">
                            Performance Metrics by {mediaType}
                        </Typography>
                    </Printable>
                    <Printable show={false}>
                        <>
                            <Typography variant="body1" color="text.primary">
                                Performance Metrics by
                            </Typography>
                            {mediaTypes && mediaTypes.length > 0 && (
                                <FormControl sx={{ width: '150px', my: -1 }}>
                                    <TextField
                                        // label="Data Type"
                                        select
                                        required={true}
                                        size="small"
                                        SelectProps={{ native: false, displayEmpty: true }}
                                        value={mediaType}
                                        onChange={(event) => {
                                            setMediaType(event.target.value);
                                        }}
                                    >
                                        <MenuItem key="" value="">
                                            All
                                        </MenuItem>
                                        {mediaTypes.map((_mediaType: string) => {
                                            return (
                                                <MenuItem key={_mediaType} value={_mediaType}>
                                                    {_mediaType}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </FormControl>
                            )}
                        </>
                    </Printable>
                </Row>
            }
            tooltip="An event as defined by advertiser, by Media Type."
            chartOptions={seriesOptions}
            chartSeries={seriesData}
        />
    );
}
