import {
    BarChart as BarChartIcon,
    Campaign as CampaignIcon,
    Description as DescriptionIcon,
    Settings as SettingsIcon,
    Storage as StorageIcon,
    SyncAlt as SyncAltIcon,
    AutoGraph as AutoGraphIcon,
} from '@mui/icons-material';

import { List } from '@mui/material';
import { useState } from 'react';
import {
    AGENCY_ADVERTISERS,
    AGENCY_API_DOCS,
    AGENCY_ATTRIBUTION,
    AGENCY_CAMPAIGNS,
    AGENCY_CRM_UPLOAD,
    AGENCY_DATA,
    AGENCY_INSIGHTS,
    AGENCY_REPORTING,
    USER_GROUP_ADMIN,
    USER_GROUP_ADVERTISER,
    USER_GROUP_AGENCY,
} from '../../types/User';
import AccessGuard from '../AccessGuard';
import NavigationMenu from './NavigationMenu';
import NavigationItem from './NavigationItem';

interface MenuState {
    name: string;
    expanded: boolean;
}

export default function Navigation(props: { forceOpen(): void; fullWidth: boolean }) {
    const { fullWidth } = props;
    const [activeMenu, setActiveMenu] = useState('');
    const [menuState] = useState<MenuState[]>([
        {
            name: 'Measurement',
            expanded: true,
        },
        {
            name: 'Data',
            expanded: false,
        },
        {
            name: 'Admin',
            expanded: false,
        },
        {
            name: 'Reporting',
            expanded: false,
        },
    ]);

    const fontSize = 'medium';
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    marginTop: '24px',
                    width: fullWidth ? '220px' : '',
                }}
            >
                <List component="nav" sx={{ padding: 0 }} dense>
                    <AccessGuard
                        accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                        accessAgency={AGENCY_CAMPAIGNS}
                    >
                        <NavigationItem
                            label="Campaigns"
                            to="/campaignManager"
                            icon={<CampaignIcon fontSize={fontSize} />}
                            onNavigate={(name) => setActiveMenu(name)}
                        />
                    </AccessGuard>

                    <AccessGuard
                        accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}
                        accessAgency={AGENCY_INSIGHTS}
                    >
                        <NavigationItem
                            label="Insights"
                            to="/insights"
                            icon={<AutoGraphIcon fontSize={fontSize} />}
                            onNavigate={(name) => setActiveMenu(name)}
                        />
                    </AccessGuard>

                    <AccessGuard
                        accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                        accessAgency={AGENCY_REPORTING}
                    >
                        <NavigationItem
                            label="Reporting"
                            to="/quicksight"
                            icon={<BarChartIcon fontSize={fontSize} />}
                            onNavigate={(name) => setActiveMenu(name)}
                        />
                    </AccessGuard>

                    <AccessGuard accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`} accessAgency={AGENCY_DATA}>
                        <NavigationMenu
                            hasActiveItem={activeMenu === menuState[1].name}
                            onNavigate={(name) => setActiveMenu(name)}
                            name={menuState[1].name}
                            icon={<StorageIcon fontSize={fontSize} />}
                            items={[
                                {
                                    label: 'AMC Instances',
                                    to: '/amcInstances',
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'AMC Data Sets',
                                    to: '/amcDataSets',
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'AMC Global Workflows',
                                    to: '/workflows',
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'AMC Workflows',
                                    to: '/amcworkflows',
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'AMC Offline Attributions',
                                    to: '/amcAttributionList',
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'DSP Audiences',
                                    to: '/dspAudiences',
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'Data Mapping',
                                    to: '/dataMapping',
                                    onNavigate: (name) => setActiveMenu(name),
                                    accessGroup: `${USER_GROUP_ADMIN}`,
                                },
                                // {
                                //     label: 'Polk Audiences',
                                //     to: '/polkAudiences',
                                //     onNavigate: (name) => setActiveMenu(name),
                                // },
                            ]}
                        ></NavigationMenu>
                    </AccessGuard>
                    <AccessGuard accessAgency={AGENCY_ATTRIBUTION}>
                        <NavigationItem
                            label="Attribution"
                            to="/attribution"
                            icon={<SyncAltIcon fontSize={fontSize} />}
                            onNavigate={(name) => setActiveMenu(name)}
                        />
                    </AccessGuard>
                    <AccessGuard
                        accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}
                        accessAgency={`${AGENCY_ADVERTISERS},${AGENCY_CRM_UPLOAD}`}
                    >
                        <NavigationMenu
                            hasActiveItem={activeMenu === menuState[2].name}
                            onNavigate={(name) => setActiveMenu(name)}
                            name={menuState[2].name}
                            icon={<SettingsIcon fontSize={fontSize} />}
                            items={[
                                {
                                    label: 'Agencies',
                                    to: '/agencies',
                                    accessGroup: `${USER_GROUP_ADMIN}`,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'User Management',
                                    to: '/users',
                                    accessGroup: `${USER_GROUP_ADMIN}`,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'Analytics',
                                    to: '/analyticsSettings',
                                    accessGroup: `${USER_GROUP_ADMIN}`,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'Advertisers',
                                    to: '/advertisers',
                                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`,
                                    accessAgency: AGENCY_ADVERTISERS,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'CRM Upload',
                                    to: '/crmUpload',
                                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`,
                                    accessAgency: AGENCY_CRM_UPLOAD,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'Import Manager',
                                    to: '/importManager',
                                    accessGroup: `${USER_GROUP_ADMIN}`,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'Release Notes',
                                    to: '/releaseNotes',
                                    accessGroup: `${USER_GROUP_ADMIN}`,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'Ops Dashboard',
                                    to: '/opsDashboard',
                                    accessGroup: `${USER_GROUP_ADMIN}`,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                                {
                                    label: 'Ad Tag Preview',
                                    to: '/adTagPreview',
                                    accessGroup: `${USER_GROUP_ADMIN}`,
                                    onNavigate: (name) => setActiveMenu(name),
                                },
                            ]}
                        ></NavigationMenu>
                    </AccessGuard>
                </List>
                <div style={{ flex: 1 }}>&nbsp;</div>

                <AccessGuard
                    accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                    accessAgency={AGENCY_API_DOCS}
                >
                    <List component="nav" sx={{ padding: 0 }} dense>
                        <NavigationItem
                            label="API"
                            to="/analyticsapi"
                            icon={<DescriptionIcon fontSize={fontSize} />}
                            onNavigate={(name) => setActiveMenu(name)}
                        />
                    </List>
                </AccessGuard>
            </div>
        </>
    );
}
