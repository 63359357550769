import Heading from '../../components/Heading';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataGrid, gridClasses, GridToolbarContainer, GridValueFormatterParams } from '@mui/x-data-grid';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import { EmailOutlined, MarkEmailUnreadOutlined, RefreshOutlined } from '@mui/icons-material';
import ApiService from '../../ApiService';
import { JobStatus } from '../../types/JobStatus';

export default function JobStatusListPage() {
    const [jobStatus, setJobStatus] = useState<JobStatus[] | null>(null);
    const [filteredJobs, setFilteredJobs] = useState<JobStatus[] | null>(null);
    const filterRef = useRef('Failed');

    const refresh = useCallback(() => {
        ApiService.getJobStatus()
            .then(function (response) {
                setJobStatus(response.data);
                filterJobs(response.data);
            })
            .catch(() => {
                setJobStatus([]);
            });
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    function markRead(jobId: number) {
        ApiService.acknowledgeFailedJob(jobId).then(() => {
            refresh();
        });
    }

    function changeFilter(event: React.MouseEvent<HTMLButtonElement>) {
        const button: HTMLButtonElement = event.currentTarget;
        filterRef.current = button.value;
        filterJobs(jobStatus);
    }

    function filterJobs(jobStatus: JobStatus[] | null) {
        const status = filterRef.current;
        if (status === 'All') {
            setFilteredJobs(jobStatus);
        } else {
            // @ts-ignore
            setFilteredJobs(jobStatus.filter((js) => js.status === status));
        }
    }

    function CustomToolbar() {
        const { current: filter } = filterRef;
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <Stack direction="row">
                    <label
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        Status Filter :
                    </label>
                    <div style={{ width: '15px' }} />
                    <Button variant={filter === 'All' ? 'contained' : 'text'} value="All" onClick={changeFilter}>
                        All
                    </Button>
                    <Button variant={filter === 'Failed' ? 'contained' : 'text'} value="Failed" onClick={changeFilter}>
                        Failed
                    </Button>
                    <Button
                        variant={filter === 'Success' ? 'contained' : 'text'}
                        value="Success"
                        onClick={changeFilter}
                    >
                        Success
                    </Button>
                </Stack>
                <div style={{ flexGrow: 1 }} />
                <Button variant="text" startIcon={<RefreshOutlined />} onClick={refresh}>
                    Refresh
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Box
            sx={{
                '& .status-Failed': {
                    color: 'rgb(211, 47, 47)',
                },
                '& .status-Success': {
                    color: 'green',
                },
            }}
        >
            <Heading>Job Status</Heading>
            <div style={{ height: '100%', width: '100%' }}>
                <DataGrid
                    autoHeight
                    disableSelectionOnClick={true}
                    density="compact"
                    getRowClassName={(params) => 'status-' + params.row.status}
                    components={{ Toolbar: CustomToolbar }}
                    columns={[
                        {
                            width: 50,
                            field: 'acknowledged',
                            headerName: '',
                            filterable: false,
                            renderCell: (params) => {
                                return params.value ? (
                                    <Button color="inherit" startIcon={<EmailOutlined fontSize="small" />} />
                                ) : (
                                    <Tooltip title="Mark as Read">
                                        <Button
                                            color="inherit"
                                            startIcon={<MarkEmailUnreadOutlined fontSize="small" />}
                                            onClick={() => markRead(params.row.id)}
                                        />
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            width: 170,
                            flex: 1,
                            field: 'jobDate',
                            headerName: 'Date',
                            filterable: false,
                            valueFormatter: (params: GridValueFormatterParams) => {
                                return (
                                    new Date(params.value as string).toLocaleDateString() +
                                    ' ' +
                                    new Date(params.value as string).toLocaleTimeString()
                                );
                            },
                        },

                        { width: 90, field: 'status', headerName: 'Status' },
                        {
                            width: 190,
                            field: 'jobName',
                            headerName: 'Job Name',
                        },
                        {
                            width: 320,
                            field: 'message',
                            headerName: 'Message',
                            flex: 3,
                            renderCell: (params) => (
                                <Tooltip placement="top" title={params.value}>
                                    <span>{params.value}</span>
                                </Tooltip>
                            ),
                        },
                        {
                            width: 300,
                            field: 'stackTrace',
                            headerName: 'Details',
                            renderCell: (params) => (
                                <Tooltip placement="left" title={params.value}>
                                    <span>{params.value}</span>
                                </Tooltip>
                            ),
                        },
                    ]}
                    rows={filteredJobs === null ? [] : filteredJobs}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                />
            </div>
        </Box>
    );
}
