//
// To be able to use the Map component you must ensure the prepared data is
// available at the path `data/` relative to the path hosting the containing
// application.
//
// To prepare the source data, first install the Admin-UI project dependencies
// (`npm install`), then run the `prepare_map_data.sh` script in
// `Admin-UI/scripts`. This script will build boundary files in the expected
// format and compress them for efficiency. Files will be written to
// `Admin-UI/build/data` by default.
//

import tcontains from '@turf/boolean-intersects';
import GeoJsonGeometries from 'geojson-geometries';
import polygonToBoundariesData from './boundaries.json';
import codeToCenterPointData from './centers.json';

const FEATURE_COLLECTION = 'FeatureCollection';

export type ZipFeatureProperties = {
    [key: string]: string | number;
};
export type ZipFeature = GeoJSON.Feature<GeoJSON.Polygon, ZipFeatureProperties>;
export type ZipFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Polygon, ZipFeatureProperties>;

/**
 * Return the set of GeoJSON features in a collection that intersect a geometry
 */
function findIntersectingFeatures(features: ZipFeatureCollection, geometry: GeoJSON.Polygon) {
    const result: {
        type: typeof FEATURE_COLLECTION;
        features: ZipFeature[];
    } = {
        type: FEATURE_COLLECTION,
        features: [],
    };

    if (geometry.type !== 'Polygon') {
        return result;
    }

    const extracted = new GeoJsonGeometries(features, {});
    const polygons = extracted.polygons.features as ZipFeature[];
    if (polygons.length === 0) {
        return result;
    }

    for (let i = 0; i < polygons.length; i++) {
        if (tcontains(polygons[i], geometry)) {
            result.features.push(polygons[i]);
        }
    }

    return result;
}

type Boundary<S extends ZipFeatureCollection[] | string[] = ZipFeatureCollection[] | string[]> = {
    name: string;
    key?: string;
    shapes: S;
    children?: Boundary[];
};

type Match = { type: 'match'; item: Boundary } | Resolved;

function matcher(data: Boundary<ZipFeatureCollection[]>, polygon: GeoJSON.Polygon) {
    const matches: Match[] = [];

    for (let i = 0; i < data.shapes.length; i++) {
        const features = data.shapes[i];
        const containers = findIntersectingFeatures(features, polygon);

        for (let n = 0; n < containers.features.length; n++) {
            const feature = containers.features[n];

            if (data.children) {
                for (let s = 0; s < data.children.length; s++) {
                    const child = data.children[s];
                    if (feature.properties?.name === child.name || feature.properties?.NAME === child.name) {
                        matches.push({ type: 'match', item: child });
                    }
                }
            } else {
                matches.push({
                    type: 'resolved',
                    feature,
                    key: data.key || 'name',
                });
            }
        }
    }

    return matches;
}

type Resolved = {
    type: 'resolved';
    feature: ZipFeature;
    key: string;
};

/** Cache shapes that are fetched during the boundary matching process */
const cachedShapes = new Map<string, ZipFeatureCollection>();

/**
 * Return the boundaries for the postal codes that intersect a given polygon
 *
 * @param polygon - check for intersections with this polygon
 * @param reader - a function that can be used to retrieve GeoJSON shapes
 * @returns the intersecting boundaries
 */
export const polygonToBoundaries = async (
    polygon: GeoJSON.Polygon,
    reader: (path: string) => Promise<ZipFeatureCollection>
): Promise<{
    boundaries: ZipFeatureCollection;
}> => {
    const data = polygonToBoundariesData;
    const matches: Match[] = [];
    const resolved: Resolved[] = [];
    matches.push({ type: 'match', item: data });

    let match = matches.shift();
    while (match) {
        if (match.type === 'resolved') {
            resolved.push(match);
        } else {
            const item = {
                ...match.item,
                shapes: await Promise.all(
                    match.item.shapes.map(async (shapeOrPath) => {
                        if (typeof shapeOrPath === 'string') {
                            let shape = cachedShapes.get(shapeOrPath);
                            if (!shape) {
                                shape = await reader(shapeOrPath);
                                cachedShapes.set(shapeOrPath, shape);
                            }
                            return shape;
                        }
                        return shapeOrPath;
                    })
                ),
            };
            matches.push(...matcher(item, polygon));
        }
        match = matches.shift();
    }

    return {
        boundaries: {
            type: FEATURE_COLLECTION,
            features: resolved.map((item, id) => {
                const properties = {
                    ...item.feature.properties,
                    key: item.key,
                };
                return { ...item.feature, id, properties };
            }),
        },
    };
};

interface CodeToCenters {
    [code: string]: {
        center: [string, string];
    };
}

/**
 * Return the geographic center of a postal code area
 *
 * @param country - the two-letter country code
 * @param zode - a postal code
 * @param reader - a function used to load data files for the postal code
 *  centers
 *  @return a center point or undefined
 */
export const codeToCenterPoint = async (
    country: string,
    code: string,
    reader: (path: string) => Promise<CodeToCenters>
): Promise<{ center: [number, number] } | undefined> => {
    const data = codeToCenterPointData;
    const item = data.find((item) => item.name === country);
    if (item) {
        const codes = await reader(item.data);
        const [lon, lat] = codes[code].center;
        return { center: [parseFloat(lon), parseFloat(lat)] };
    }
};

export const toUniqueZipFeatures = (features: ZipFeature[]): ZipFeature[] => {
    let uniqueGroupFeatures: Record<string, ZipFeature[]> = {};
    const idGapThreshold: number = 50;

    features.forEach((feature: ZipFeature) => {
        if (feature.properties.GEOID10 in uniqueGroupFeatures) {
            // This is to check for related groups that have close increments.
            const isBelowThreshold: boolean = uniqueGroupFeatures[feature.properties.GEOID10].every(
                (_feature: ZipFeature) => {
                    return Math.abs(parseInt(_feature?.id as any) - parseInt(feature?.id as any)) < idGapThreshold;
                }
            );

            if (isBelowThreshold) {
                uniqueGroupFeatures[feature.properties.GEOID10].push(feature);
            }
        } else {
            uniqueGroupFeatures[feature.properties.GEOID10] = [feature];
        }
    });

    return Object.keys(uniqueGroupFeatures)
        .map((GEOID10: string) => uniqueGroupFeatures[GEOID10])
        .flat();
};
