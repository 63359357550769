import { useEffect, useState } from 'react';
import { styled, tableCellClasses, tableRowClasses } from '@mui/material';
import { Box, Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Card, CardContent as BaseCardContent, CardHeader, CapitalizeTypography } from './CampaignWizardStyles';

import { AudienceSource } from '../../../../types/AudienceSource';
import { Campaign } from '../../../../types/Campaign';
import { CampaignWizardStepProps } from './CampaignWizard';
import { InventorySource } from '../../../../types/Inventory';
import { Location } from '../../../../types/Location';
import { ProductCategory } from '../../../../components/ProductCategoryAutocomplete';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import { Table, TablePrimaryCell as BaseTablePrimaryCell } from '../../../../components/Table';
import AudienceSourceField from '../../../../components/Audience/AudienceSourceField';
import Column from '../../../../components/Column';
import InventorySourceField from '../../../../components/Inventory/InventorySourceField';
import LocationList from '../../../../components/Location/LocationList';
import Utils from '../../../../components/Utils';

const CardContent = styled(BaseCardContent)(({ theme }) => ({
    '&': { padding: 0 },
    '&:last-child': { paddingBottom: 0 },
    [`&:last-child .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]: { borderBottomWidth: 0 },
}));

const TablePrimaryCell = styled(BaseTablePrimaryCell)(({ theme }) => ({
    '&': { width: '30%' },
}));

type CampaignReviewDetailedSummaryProps = {
    onChange?: (value: Partial<Campaign>) => void;
} & Omit<CampaignWizardStepProps, 'onChange'>;

export default function CampaignReviewDetailedSummary(props: CampaignReviewDetailedSummaryProps) {
    const { campaign } = props;
    const { campaignBidStrategyLabel, campaignBudgetManagementStrategyLabel, files } = useCampaignWizardContext();

    const { $productCategories } = useCampaignWizardContext();
    const { getCampaignProductCategory = (campaign: Campaign): ProductCategory | null => null } =
        $productCategories || {};
    const productCategory: ProductCategory | null = getCampaignProductCategory(campaign);

    const locationCount = campaign?.adGroups?.[0]?.adGroupLocations?.length ?? 0;
    const geoLocationCount = campaign?.adGroups?.[0]?.adGroupGeoLocations?.length ?? 0;
    const audienceSourceCount = campaign?.adGroups?.[0]?.adGroupAudienceSources?.length ?? 0;

    const { $audienceSources } = useCampaignWizardContext();
    const { audienceSources: selections = [], getCampaignAudienceSources = undefined } = $audienceSources || {};
    const [audienceSources, setAudienceSources] = useState<AudienceSource[]>([]);

    const { $inventorySources } = useCampaignWizardContext();
    const { inventorySources: inventorySourceSelections = [], getCampaignInventorySources = undefined } =
        $inventorySources || {};
    const [inventorySources, setInventorySources] = useState<InventorySource[]>([]);

    const { $locations } = useCampaignWizardContext();
    const {
        locations: locationSelections = [],
        getCampaignLocations = undefined,
        getCampaignGeoLocations = undefined,
    } = $locations || {};
    const [locations, setLocations] = useState<Location[]>([]);
    const [geoLocations, setGeoLocations] = useState<Location[]>([]);

    const { $campaignTypeCodes, $goalTypeCodes, $kpiTypeCodes } = useCampaignWizardContext();

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (getCampaignAudienceSources) {
                setAudienceSources(getCampaignAudienceSources(campaign));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, selections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (getCampaignInventorySources) {
                setInventorySources(getCampaignInventorySources(campaign));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, inventorySourceSelections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (getCampaignLocations) {
                setLocations(getCampaignLocations(campaign));
            }
            if (getCampaignGeoLocations) {
                setGeoLocations(getCampaignGeoLocations(campaign));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, locationSelections]);

    return (
        <Column>
            <Card id="campaign-wizard-review-detailed-summary-setup" variant="outlined">
                <CardHeader
                    title={
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography variant="body1" color="text.primary">
                                Campaign Summary
                            </Typography>
                        </Stack>
                    }
                />
                <CardContent>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TablePrimaryCell>Advertiser Name</TablePrimaryCell>
                                <TableCell>{campaign?.dealer?.dealerName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePrimaryCell>Product Category</TablePrimaryCell>
                                <TableCell>{productCategory?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePrimaryCell>Media Type</TablePrimaryCell>
                                <TableCell>{campaign.mediaType}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePrimaryCell>Campaign Strategy</TablePrimaryCell>
                                <TableCell>{campaign.strategyType}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePrimaryCell>Start & End Date</TablePrimaryCell>
                                <TableCell>{`${Utils.formatESTDateLong(campaign.startDate)} - ${Utils.formatESTDateLong(
                                    campaign.endDate
                                )}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePrimaryCell>Budget</TablePrimaryCell>
                                <TableCell>{Utils.formatCurrency(campaign.budget)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePrimaryCell>Budget Cap</TablePrimaryCell>
                                <TableCell>
                                    {campaign.budgetCapType !== 'CUSTOM' && (
                                        <CapitalizeTypography>
                                            {Utils.convertCase(campaign.budgetCapType)}
                                        </CapitalizeTypography>
                                    )}
                                    {campaign.budgetCapType === 'CUSTOM' && (
                                        <>
                                            <CapitalizeTypography component="span">
                                                {Utils.convertCase(campaign.budgetCapRecurrenceTimePeriod)}
                                            </CapitalizeTypography>
                                            {', '}
                                            <Typography component="span" variant="body2">
                                                {Utils.formatCurrency(campaign.budgetCapAmount, 2)}
                                            </Typography>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                            {false && (
                                <TableRow>
                                    <TablePrimaryCell>Campaign Goal</TablePrimaryCell>
                                    <TableCell>{$goalTypeCodes?.getCodeDescription(campaign.goal)}</TableCell>
                                </TableRow>
                            )}
                            {false && (
                                <TableRow>
                                    <TablePrimaryCell>KPI</TablePrimaryCell>
                                    <TableCell>{$kpiTypeCodes?.getCodeDescription(campaign.kpi)}</TableCell>
                                </TableRow>
                            )}
                            {false && (
                                <TableRow>
                                    <TablePrimaryCell>KPI Target</TablePrimaryCell>
                                    <TableCell>
                                        {campaign.targetKpiDisabled ? (
                                            <>Not set</>
                                        ) : (
                                            <>{Utils.formatValue(campaign.targetKpi, 'percent')}</>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                            {false && (
                                <TableRow>
                                    <TablePrimaryCell>Bidding Priority</TablePrimaryCell>
                                    <TableCell>{campaignBidStrategyLabel ?? '-'}</TableCell>
                                </TableRow>
                            )}
                            {false && (
                                <TableRow>
                                    <TablePrimaryCell>Budget Management Strategy</TablePrimaryCell>
                                    <TableCell>{campaignBudgetManagementStrategyLabel ?? '-'}</TableCell>
                                </TableRow>
                            )}
                            {false && (
                                <TableRow>
                                    <TablePrimaryCell>Inventory Source</TablePrimaryCell>
                                    <TableCell>
                                        <Column>
                                            <InventorySourceField items={inventorySources} />
                                        </Column>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TablePrimaryCell>Frequency</TablePrimaryCell>
                                <TableCell>
                                    <Column gap={0}>
                                        Frequency cap
                                        <Typography variant="caption" color="text.secondary">
                                            Show no more than {campaign.frequencyMaxImpressions} times per{' '}
                                            {campaign.frequencyTimeUnitCount}{' '}
                                            {campaign?.frequencyTimeUnit?.toLowerCase()}
                                        </Typography>
                                    </Column>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

            <Card id="campaign-wizard-review-detailed-summary-targeting" variant="outlined">
                <CardHeader
                    title={
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography variant="body1" color="text.primary">
                                Targeting
                            </Typography>
                        </Stack>
                    }
                />
                <CardContent>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TablePrimaryCell>Locations</TablePrimaryCell>
                                <TableCell>
                                    <Column gap={2}>
                                        <Typography variant="body2">
                                            {locationCount > 1
                                                ? `${locationCount} Locations`
                                                : `${locationCount} Location`}
                                        </Typography>

                                        <Card variant="outlined">
                                            <Box
                                                sx={{
                                                    height: 200,
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <LocationList items={locations} />
                                            </Box>
                                        </Card>
                                    </Column>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePrimaryCell>Zip Codes</TablePrimaryCell>
                                <TableCell>
                                    <Column gap={2}>
                                        <Typography variant="body2">
                                            {geoLocationCount > 1
                                                ? `${geoLocationCount} Zip Codes`
                                                : `${geoLocationCount} Zip Code`}
                                        </Typography>

                                        <Card variant="outlined">
                                            <Box
                                                sx={{
                                                    height: 200,
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <LocationList items={geoLocations} />
                                            </Box>
                                        </Card>
                                    </Column>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePrimaryCell>Audience</TablePrimaryCell>
                                <TableCell>
                                    <Column gap={2}>
                                        <Typography variant="body2">
                                            {audienceSourceCount > 1
                                                ? `${audienceSourceCount} Audience Categories`
                                                : `${audienceSourceCount} Audience Category`}
                                        </Typography>

                                        <AudienceSourceField items={audienceSources} />
                                    </Column>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

            <Card id="campaign-wizard-review-detailed-summary-creative" variant="outlined">
                <CardHeader
                    title={
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography variant="body1" color="text.primary">
                                Creative
                            </Typography>
                        </Stack>
                    }
                />
                <CardContent>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TablePrimaryCell>Creative Type</TablePrimaryCell>
                                <TableCell>{$campaignTypeCodes?.getCodeDescription(campaign.campaignType)}</TableCell>
                            </TableRow>
                            {campaign.campaignType === 'Static' && (
                                <>
                                    {files && files.length > 0 && (
                                        <TableRow>
                                            <TablePrimaryCell>Creative</TablePrimaryCell>
                                            <TableCell>
                                                <Column gap={0.5}>
                                                    {files.map((file: File) => {
                                                        return (
                                                            <Typography
                                                                key={file.name}
                                                                variant="body2"
                                                                color="text.secondary"
                                                            >
                                                                {file.name}
                                                            </Typography>
                                                        );
                                                    })}
                                                </Column>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    <TableRow>
                                        <TablePrimaryCell>Destination URL</TablePrimaryCell>
                                        <TableCell>{campaign.landingPage ?? 'Not set'}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TablePrimaryCell>Call to Actions</TablePrimaryCell>
                                        <TableCell>{campaign.callToActions ?? 'Not set'}</TableCell>
                                    </TableRow>
                                </>
                            )}
                            {campaign.campaignType === 'AdTag' && (
                                <>
                                    <TableRow>
                                        <TablePrimaryCell>Ad Tag URL</TablePrimaryCell>
                                        <TableCell>{campaign.adTagUrl ?? 'Not set'}</TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Column>
    );
}
