import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { PerformanceListCriteria } from '../../../../../types/CampaignPerformance';
import { ReportingCriteria } from '../../../../../types/Analytics/Reporting/ReportingCriteria';
import ApiService from '../../../../../ApiService';
import ReportingPerformanceAgencyDataGrid from '../ReportingPerformanceAgencyDataGrid';

type ReportingPerformanceMonitorTabProps = {
    reportingCriteria: ReportingCriteria;
};

export default function ReportingPerformanceMonitorTab(props: ReportingPerformanceMonitorTabProps) {
    const { reportingCriteria } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [campaignPerformances, setCampaignPerformances] = useState<any[]>([]);
    const [performanceListCriteria, setPerformanceListCriteria] = useState<PerformanceListCriteria>({
        view: 'agency',
        agencyIds: [],
        dealerIds: [],
        mediaType: '',
        strategyType: '',
        dataSet: 'Sales',
        startDate: new Date(),
        endDate: new Date(),
    });

    useEffect(() => {
        setPerformanceListCriteria({
            ...performanceListCriteria,
            ...reportingCriteria,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingCriteria]);

    useEffect(() => {
        setIsLoading(true);

        ApiService.getCampaignPerformanceList(performanceListCriteria).then((response) => {
            setIsLoading(false);
            setCampaignPerformances(response.data);
        });
    }, [performanceListCriteria]);

    return (
        <Box sx={{ width: '100%', maxWidth: '100vw', overflowX: 'auto' }}>
            <ReportingPerformanceAgencyDataGrid
                loading={isLoading}
                reportingCriteria={reportingCriteria}
                campaignPerformances={campaignPerformances}
            />
        </Box>
    );
}
