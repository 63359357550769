import { alpha, styled, tableCellClasses } from '@mui/material';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    // Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { StyledTable } from '../../components/StyledTable';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.palette.text.primary,
    },
}));

const StyledTableSecondaryCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        color: alpha('#000000', 0.6),
    },
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    display: 'inline',
}));

export default function CRMDataUploadRequirementDialog(props: { onClose(): void; open: boolean }) {
    const { onClose, open } = props;

    function highlight(text: string) {
        return <StyledTypography variant="body2" children={<>{text}</>} />;
    }

    return (
        <Dialog maxWidth="xl" open={open} onClose={() => onClose()}>
            <DialogTitle>CSV Data & Format Requirements</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => onClose()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ pt: 3, pb: 5 }}>
                <TableContainer>
                    <StyledTable
                        sx={{
                            borderCollapse: 'inherit',
                            borderColor: (theme) => theme.palette.divider,
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderRadius: '8px',
                            overflow: 'hidden',
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell>Field/Column</StyledTableHeadCell>
                                <StyledTableHeadCell>Required/Recommended</StyledTableHeadCell>
                                <StyledTableHeadCell>Formatting</StyledTableHeadCell>
                                <StyledTableHeadCell>Notes</StyledTableHeadCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell>Sale/Activity Date</StyledTableCell>
                                <StyledTableSecondaryCell>Required</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>mm/dd/yy</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>
                                    <div>Numeric values separated by / or - only.</div>
                                    <div>Attribution is performed on a 60-day lookback from this date.</div>
                                </StyledTableSecondaryCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableSecondaryCell>
                                    Required if {highlight('last name')} or {highlight('email')} or phone not provided.
                                </StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>John</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>
                                    Can be combined into one Name column (Format: John Doe)
                                </StyledTableSecondaryCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Last Name</StyledTableCell>
                                <StyledTableSecondaryCell>
                                    Required if {highlight('first name')} or {highlight('email')} or{' '}
                                    {highlight('phone')} not provided.
                                </StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>John</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>
                                    Can be combined into one Name column (Format: John Doe)
                                </StyledTableSecondaryCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableSecondaryCell>
                                    Required if {highlight('first name')} or {highlight('last name')} or{' '}
                                    {highlight('phone')} not provided.
                                </StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>email@domain.com</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>One value per record</StyledTableSecondaryCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Phone</StyledTableCell>
                                <StyledTableSecondaryCell>
                                    Required if {highlight('first name')} or {highlight('last name')} or{' '}
                                    {highlight('email')} not provided.
                                </StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>000-000-0000</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>
                                    One value per cell, multiple phone columns can be added (Home, Cell, etc.)
                                </StyledTableSecondaryCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Street Address</StyledTableCell>
                                <StyledTableSecondaryCell>Recommended</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>123 Main St</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>One value per record</StyledTableSecondaryCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>City</StyledTableCell>
                                <StyledTableSecondaryCell>Recommended</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>Ponte Vedra</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>-</StyledTableSecondaryCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>State</StyledTableCell>
                                <StyledTableSecondaryCell>Recommended</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>FL</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>-</StyledTableSecondaryCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Zip</StyledTableCell>
                                <StyledTableSecondaryCell>Recommended</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>12345</StyledTableSecondaryCell>
                                <StyledTableSecondaryCell>Numeric, 5 digits only.</StyledTableSecondaryCell>
                            </TableRow>
                        </TableBody>
                    </StyledTable>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
}
