import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Divider, FormControl, Grid, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel, FormControlLabel } from './CampaignWizardStyles';
import moment from 'moment-timezone';

import { CampaignWizardStepProps } from './CampaignWizard';
import { CDCurrencyField } from '../../../../components/CDCurrencyField';
import { CDTextField } from '../../../../components/CDTextField';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import CampaignDuplicateLoader from '../CampaignDuplicateLoader';
import CDButton from '../../../../components/CDButton';
import CDDatePicker from '../../../../components/CDDatePicker';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';
import Utils from '../../../../components/Utils';

type CampaignSetupDeliveryCardProps = {} & CampaignWizardStepProps;

export default function CampaignSetupDeliveryCard(props: CampaignSetupDeliveryCardProps) {
    const { campaign, onChange } = props;
    const { hasError, isCompletedCampaign } = useCampaignWizardContext();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            onChange({
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleDateChange = (dateKey: string, dateValue: Date | null) => {
        if (dateValue !== null) {
            if (dateKey === 'startDate') {
                if (moment(campaign.endDate).diff(moment(dateValue)) < 0) {
                    onChange({
                        endDate: null,
                    });
                }
            }

            onChange({
                [dateKey]: dateValue,
            });
        }
    };

    const campaignRef = useRef(campaign);
    campaignRef.current = campaign;

    const startDateMinDate = useMemo(() => {
        return moment().subtract(1, 'days').toDate();
    }, []);

    const [campaignBudget, setCampaignBudget] = useState<string>('');
    const [isChangingCampaignBudget, setIsChangingCampaignBudget] = useState<boolean>(false);
    const [debouncedCampaignBudget, setDebouncedCampaignBudget] = useState<string>(campaignBudget);
    const debouncedCampaignBudgetTimeout = useRef<any | null>(null);

    const handleChangeBudget = (event: ChangeEvent<HTMLInputElement>) => {
        setCampaignBudget(event.target.value);
    };

    useEffect(() => {
        setIsChangingCampaignBudget(true);

        debouncedCampaignBudgetTimeout.current = setTimeout(() => {
            setDebouncedCampaignBudget(campaignBudget);
            setIsChangingCampaignBudget(false);
        }, 2000);

        return () => {
            clearTimeout(debouncedCampaignBudgetTimeout.current);
        };
    }, [campaignBudget]);

    useEffect(() => {
        if (debouncedCampaignBudget) {
            onChange({
                budget: parseFloat(debouncedCampaignBudget),
            });
        }
    }, [debouncedCampaignBudget, onChange]);

    useEffect(() => {
        setCampaignBudget(campaign.budget ? (campaign.budget as any as string) : '');
    }, [campaign.budget]);

    return (
        <Card id="campaign-wizard-setup-delivery" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Schedule & Budget
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormLabel required={true}>Select a Date Range</FormLabel>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl error={hasError('startDate')} fullWidth size="small">
                                <CDDatePicker
                                    disablePast
                                    value={campaign.startDate}
                                    onChange={(dateValue: Date | null) => handleDateChange('startDate', dateValue)}
                                    showError={hasError('startDate')}
                                    label="Start Date"
                                    disabled={isCompletedCampaign}
                                    minDate={startDateMinDate}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl error={hasError('endDate')} fullWidth size="small">
                                <CDDatePicker
                                    disablePast
                                    value={campaign.endDate}
                                    onChange={(dateValue: Date | null) => handleDateChange('endDate', dateValue)}
                                    showError={hasError('endDate')}
                                    label="End Date"
                                    disabled={isCompletedCampaign}
                                    minDate={campaign.startDate}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            {!isCompletedCampaign && (
                                <>
                                    <CDButton
                                        variant="text"
                                        onClick={() => {
                                            let endDate = Utils.getMonthEnd(1);
                                            if (campaign.endDate && campaign.endDate > endDate) {
                                                endDate = campaign.endDate;
                                            }

                                            props.onChange({
                                                startDate: Utils.getMonthStart(1),
                                                endDate: endDate,
                                            });
                                        }}
                                    >
                                        Next Month
                                    </CDButton>
                                </>
                            )}
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <FormControl error={hasError('budget')} fullWidth size="small">
                                <CDCurrencyField
                                    variant="outlined"
                                    label="Budget"
                                    name="budget"
                                    required={true}
                                    error={hasError('budget')}
                                    // value={campaign.budget > 0 ? campaign.budget : ''}
                                    value={campaignBudget}
                                    onChange={handleChangeBudget}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <CampaignDuplicateLoader isLoading={isChangingCampaignBudget} />
                        </Grid>
                    </Grid>

                    <Divider />

                    <FormControl>
                        <FormLabel error={hasError('budgetCapType')} required={true} sx={{ mb: 2 }}>
                            Select a Budget Cap
                        </FormLabel>

                        <RadioGroup
                            name="budgetCapType"
                            defaultValue="UNCAPPED"
                            value={campaign.budgetCapType}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="UNCAPPED" control={<Radio />} label="Uncapped" />

                            <Row gap={0}>
                                <FormControlLabel
                                    value="CUSTOM"
                                    control={<Radio />}
                                    label=""
                                    labelPlacement="end"
                                    sx={{
                                        '&': {
                                            marginRight: 0,
                                        },
                                    }}
                                />
                                <Row>
                                    <CDTextField
                                        select
                                        variant="outlined"
                                        name="budgetCapRecurrenceTimePeriod"
                                        required={campaign.budgetCapType === 'CUSTOM'}
                                        disabled={campaign.budgetCapType !== 'CUSTOM'}
                                        error={hasError('budgetCapRecurrenceTimePeriod')}
                                        value={campaign.budgetCapRecurrenceTimePeriod ?? 'DAILY'}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                paddingLeft: 0,
                                            },
                                            width: 124,
                                        }}
                                    >
                                        <MenuItem value="DAILY">Daily</MenuItem>
                                        <MenuItem value="MONTHLY">Monthly</MenuItem>
                                    </CDTextField>

                                    {campaign.budgetCapType === 'CUSTOM' && (
                                        <CDCurrencyField
                                            variant="outlined"
                                            label="Amount"
                                            name="budgetCapAmount"
                                            required={campaign.budgetCapType === 'CUSTOM'}
                                            disabled={campaign.budgetCapType !== 'CUSTOM'}
                                            error={hasError('budgetCapAmount')}
                                            value={campaign.budgetCapAmount ?? ''}
                                            onChange={handleChange}
                                            sx={{ width: 104 }}
                                        />
                                    )}
                                </Row>
                            </Row>
                        </RadioGroup>
                    </FormControl>
                </Column>
            </CardContent>
        </Card>
    );
}
