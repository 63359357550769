import { useCallback, useContext, useEffect, useRef, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    InputLabelProps,
    Link,
    MenuItem,
    Stack,
    Step,
    StepContent,
    StepButton,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import {
    ArrowDropDownOutlined as ArrowDropDownOutlinedIcon,
    DeleteOutline as DeleteOutlineIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    ModeOutlined as ModeOutlinedIcon,
} from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DataSet } from '../../types/DataSet';
import { Dealer } from '../../types/Dealer';
import { DataSetValue } from '../../types/DataSetValue';
import { MappedDataSummary } from '../../types/MappedDataSummary';
import { SalesDataMapping } from '../../types/SalesDataMapping';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import { SalesDataValidations } from '../../types/UploadResponse';
import { AdvertiserContext } from '../../AdvertiserContext';
import DealerSelector from '../../components/DealerSelector';
import CRMDataUploadMapping from './CRMDataUploadMapping';
import CRMDataUploadMappingReview from './CRMDataUploadMappingReview';
import CRMDataUploadRequirementDialog from './CRMDataUploadRequirementDialog';
import CRMDataUploadSetupSuccessful from './CRMDataUploadSetupSuccessful';
import ApiService from '../../ApiService';
import theme from '../../theme';
import Utils from '../../components/Utils';

export const STEP_SELECT_DEALER: number = 0;
export const STEP_SELECT_FILE: number = 1;
export const STEP_MAP: number = 2;
export const STEP_REVIEW: number = 3;

export const steps = [
    {
        number: STEP_SELECT_DEALER,
        label: 'Select Advertiser',
        description: '',
    },
    {
        number: STEP_SELECT_FILE,
        label: 'Select CSV File',
        description: '',
    },
    {
        number: STEP_MAP,
        label: 'Map CSV Fields to AMC Fields',
        description: '',
    },
    {
        number: STEP_REVIEW,
        label: 'Review CRM Data Before Upload',
        description: '',
    },
];

const StyledInputLabel = styled(InputLabel)<InputLabelProps>(() => ({
    '&.MuiInputLabel-root': {
        fontSize: '14px',
        fontWeight: 500,
    },
}));

export default function CRMDataUpload(props: {
    agencyId?: number | null;
    dealer?: Dealer | null;
    dealerDefaultSelect?: boolean;
    showDealerSelector?: boolean;
    showHeading?: boolean;
}) {
    const { agencyId, dealer, dealerDefaultSelect, showDealerSelector, showHeading } = props;

    const { advertiserContext, setDealer } = useContext(AdvertiserContext);

    useEffect(() => {
        if (showDealerSelector) {
            if (advertiserContext.dealer) {
                setDealerId(advertiserContext.dealer.id);
            } else {
                setDealerId(null);
            }
        }
    }, [advertiserContext, showDealerSelector]);

    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [dealerId, setDealerId] = useState<any>(dealer ? dealer.id : 0);
    const [dealersLoaded, setDealersLoaded] = useState(false);

    const [crmDataType, setCrmDataType] = useState('');
    const [beginDate, setBeginDate] = useState<Date | null>(Utils.getLastMonthStart());
    const [endDate, setEndDate] = useState<Date | null>(Utils.getLastMonthEnd());
    const [file, setFile] = useState<File | null>(null);

    const [dataSets, setDataSets] = useState<DataSet[]>([]);
    const [validationResponse, setValidationResponse] = useState<SalesDataValidations | null>();
    const [salesDataUpload, setSalesDataUpload] = useState<SalesDataUpload | null>(null);

    const [salesDataMapping, setSalesDataMapping] = useState<SalesDataMapping>({
        dealerId: 0,
        dateFormat: '',
        fields: [],
    });
    const [mappedDataSummary, setMappedDataSummary] = useState<MappedDataSummary>();
    const [sampleMappedData, setSampleMappedData] = useState<DataSetValue[]>([]);

    const [activeStep, setActiveStep] = useState(-1);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [requirementDialogOpen, setRequirementDialogOpen] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const [setupCompleted, setSetupCompleted] = useState<boolean>(false);

    const fileName: string = useMemo(() => {
        if (file && file?.name) {
            return file.name;
        }

        if (salesDataUpload && salesDataUpload?.originalFileName) {
            return salesDataUpload.originalFileName;
        }

        return '';
    }, [file, salesDataUpload]);

    const filteredSteps = useMemo(() => {
        if (showDealerSelector) {
            return steps;
        }

        return steps.filter((s: any) => {
            if (s.number === STEP_SELECT_DEALER) {
                return false;
            }

            return true;
        });
    }, [showDealerSelector]);

    useEffect(() => {
        if (showDealerSelector) {
            setActiveStep(STEP_SELECT_DEALER);
        } else {
            setActiveStep(STEP_SELECT_FILE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const $file = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (showDealerSelector && !dealersLoaded) {
            ApiService.getAssignedDealers()
                .then((response) => {
                    setDealersLoaded(true);
                    let dealers = response.data;
                    if (dealerDefaultSelect === true) {
                        if (dealers.length > 0 && !dealer?.id) {
                            if (showDealerSelector) {
                                setDealer(dealers[0]);
                            } else {
                                setDealerId(dealers[0].id);
                            }
                        }
                    }
                    setDealers(dealers);
                })
                .catch(() => {});
        }

        ApiService.getDataSets()
            .then((response) => {
                setDataSets(response.data);
            })
            .catch(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealers, dealer, dealersLoaded, file]);

    useEffect(() => {
        if (file) {
            let request;

            if (salesDataUpload) {
                deleteSalesDataUpload().then(() => {
                    request = Promise.resolve();

                    setSalesDataUpload(null);

                    resetStep(STEP_MAP);
                    resetStep(STEP_REVIEW);
                });
            } else {
                request = Promise.resolve();
            }

            if (request) {
                request.then((response) => {
                    validateStep(STEP_SELECT_FILE);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    const handleSave = () => {
        setSaveInProgress(true);

        switch (activeStep) {
            case STEP_SELECT_DEALER:
                handleOnSave();
                break;

            case STEP_SELECT_FILE:
                onFileUpload().then(handleOnSave);
                break;

            case STEP_MAP:
                break;

            case STEP_REVIEW:
                setSetupCompleted(true);
                break;
        }
    };

    const handleOnSave = (savedProps?: any) => {
        setSaveInProgress(false);

        switch (activeStep) {
            case STEP_SELECT_DEALER:
                goToStep(STEP_SELECT_FILE);
                break;

            case STEP_SELECT_FILE:
                goToStep(STEP_MAP);
                break;

            case STEP_MAP:
                if (savedProps) {
                    if (savedProps?.salesDataMapping) {
                        setSalesDataMapping(savedProps.salesDataMapping);
                    }

                    if (savedProps?.sampleMappedData) {
                        setSampleMappedData(savedProps.sampleMappedData);
                    }

                    if (savedProps?.mappedDataSummary) {
                        setMappedDataSummary(savedProps.mappedDataSummary);
                    }
                }

                goToStep(STEP_REVIEW);
                break;
        }
    };

    const deleteSalesDataUpload = () => {
        if (salesDataUpload && salesDataUpload.status === 'Uploaded') {
            return ApiService.deleteSalesData(salesDataUpload.id);
        }

        return Promise.resolve();
    };

    const handleCancel = () => {
        setExpanded(false);
        resetStep(activeStep);

        if ([STEP_SELECT_DEALER, STEP_SELECT_FILE].includes(activeStep)) {
            if (salesDataUpload && salesDataUpload.status === 'Uploaded') {
                ApiService.deleteSalesData(salesDataUpload.id).then(() => {});
            }
        }
    };

    const handleCancelSetup = () => {
        let promise = new Promise((resolve, reject): void => {
            resolve(null);
        });

        if (salesDataUpload && salesDataUpload.status === 'Uploaded') {
            promise = ApiService.deleteSalesData(salesDataUpload.id);
        }

        promise.then(() => {
            setExpanded(false);
            resetSetup();
        });
    };

    const handleDetachFile = (): void => {
        const response = new Promise<void>((resolve, reject) => {
            if (salesDataUpload) {
                ApiService.deleteSalesData(salesDataUpload.id)
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });

        response.then(() => {
            setSalesDataUpload(null);
            resetFile();
        });
    };

    function goToStep(step: number): void {
        setActiveStep(step);
    }

    // function nextStep(): void {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }

    // function previousStep(): void {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // }

    function onFileUpload(): Promise<any> {
        if (salesDataUpload) {
            return ApiService.updateSalesDataUpload(salesDataUpload);
        }

        const formData = getFormData();
        return ApiService.uploadSalesData(formData)
            .then((response) => {
                if (response?.data) {
                    setSalesDataUpload({
                        ...response.data,
                        campaignIds: [],
                    });
                }

                setFile(null);
            })
            .catch(function () {});
    }

    function getFormData(): FormData {
        const formData = new FormData();
        formData.append('dealerId', dealerId);
        formData.append('crmDataType', crmDataType);
        if (beginDate != null && endDate != null) {
            formData.append('beginDate', Utils.toAbsoluteUTC(beginDate).toISOString());
            formData.append('endDate', Utils.toAbsoluteUTC(endDate).toISOString());
        }
        if (file !== null) {
            formData.append('file', file, file.name);
        }
        return formData;
    }

    function onFileChange(event: any) {
        setFile(event.target.files[0]);
    }

    function resetFile() {
        setFile(null);
        setValidationResponse(null);

        if ($file && $file?.current) {
            $file.current.value = '';
        }
    }

    const isSelectDealerStepValidated = useMemo((): boolean => {
        return dealerId && dealerId > 0;
    }, [dealerId]);

    const isSelectFileStepValidated: boolean = useMemo(() => {
        if (!crmDataType) {
            return false;
        }

        if (!beginDate || !endDate) {
            return false;
        }

        if (!file && !fileName) {
            return false;
        }

        if (file) {
            if (validationResponse) {
                if (!validationResponse?.validCsv) {
                    return false;
                }

                if (validationResponse?.duplicate) {
                    return false;
                }

                if (validationResponse?.validCsv && validationResponse?.dateRanges.length === 0) {
                    return false;
                }
            }
        }

        return true;
    }, [crmDataType, beginDate, endDate, file, fileName, validationResponse]);

    // const isMapStepValidated: boolean = useMemo(() => {
    //     return salesDataUpload ? true : false;
    // }, [salesDataUpload]);

    const [isMapStepValidated, setIsMapStepValidated] = useState<boolean>(false);

    const isReviewStepValidated: boolean = useMemo(() => {
        return salesDataUpload !== null && isMapStepValidated;
    }, [salesDataUpload, isMapStepValidated]);

    const isStepValidated = useCallback(
        (step: number): boolean => {
            switch (step) {
                case STEP_SELECT_DEALER:
                    return isSelectDealerStepValidated;

                case STEP_SELECT_FILE:
                    return isSelectFileStepValidated;

                case STEP_MAP:
                    return isMapStepValidated;

                case STEP_REVIEW:
                    return isReviewStepValidated;
            }

            return false;
        },
        [isSelectDealerStepValidated, isSelectFileStepValidated, isMapStepValidated, isReviewStepValidated]
    );

    function isActiveStep(step: number): boolean {
        return activeStep === step;
    }

    function isStepEditable(step: number): boolean {
        return activeStep > step ? true : false;
    }

    function isStepExpandable(step: number): boolean {
        if (isStepValidated(step)) {
            return true;
        }

        return activeStep >= step;
    }

    function isStepExpanded(step: number): boolean {
        return activeStep === step && expanded === true;
    }

    function isStepCancellable(step: number): boolean {
        return true;
    }

    const isStepSaveable = useCallback(
        (step: number): boolean => {
            return isStepValidated(step);
        },
        [isStepValidated]
    );

    // function isStartStep(step: number): boolean {
    //     return STEP_SELECT_FILE === step;
    // }

    function isEndStep(step: number): boolean {
        return STEP_REVIEW === step;
    }

    function validateStep(step: number) {
        setValidationResponse(null);

        switch (step) {
            case STEP_SELECT_DEALER:
                break;

            case STEP_SELECT_FILE:
                const formData = getFormData();
                formData.append('validateOnly', 'true');
                ApiService.uploadSalesData(formData)
                    .then((response) => {
                        const validationResponse = response.data;
                        setValidationResponse(validationResponse);
                    })
                    .finally(() => {});
                break;

            case STEP_MAP:
                break;

            case STEP_REVIEW:
                break;
        }
    }

    function resetStep(step?: number) {
        switch (step) {
            case STEP_SELECT_DEALER:
                if (showDealerSelector) {
                    setDealer(null);
                } else {
                    setDealerId(0);
                }
                break;

            case STEP_SELECT_FILE:
                setCrmDataType('');
                setValidationResponse(null);
                setSalesDataUpload(null);

                resetFile();
                break;

            case STEP_MAP:
                break;

            case STEP_REVIEW:
                break;
        }
    }

    function resetSetup(): void {
        if (showDealerSelector) {
            resetStep(STEP_SELECT_DEALER);
        }
        resetStep(STEP_SELECT_FILE);
        resetStep(STEP_MAP);
        resetStep(STEP_REVIEW);

        // setDataSets([]);

        if (showDealerSelector) {
            setActiveStep(STEP_SELECT_DEALER);
        } else {
            setActiveStep(STEP_SELECT_FILE);
        }
        setExpanded(true);
        setSaveInProgress(false);
        setSetupCompleted(false);
    }

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    const handleStep = (step: number) => () => {
        if (activeStep === step) {
            setActiveStep(step);
            setExpanded((_expanded: boolean): boolean => !_expanded);
        } else {
            setActiveStep(step);
            setExpanded(true);
        }
    };

    return (
        <Box>
            {setupCompleted === false && (
                <>
                    {showHeading && (
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            New CRM Upload
                        </Typography>
                    )}

                    <Stepper activeStep={activeStep} orientation="vertical" connector={null}>
                        {filteredSteps.map((step, index) => (
                            <Step
                                active={isStepExpanded(step.number)}
                                key={step.label}
                                sx={{
                                    borderColor: 'divider',
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    px: 2,
                                    my: isStepExpanded(step.number) ? 2 : 0,

                                    '&:first-of-type': {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        mt: 0,
                                    },
                                    '&:last-of-type': {
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                    },
                                }}
                            >
                                <Stack direction="row">
                                    <StepButton
                                        color="inherit"
                                        onClick={handleStep(step.number)}
                                        sx={{
                                            '.Mui-completed .MuiSvgIcon-root': {
                                                color: (theme) => theme.palette.success.main,
                                            },
                                            '.Mui-active .MuiTypography-root': {
                                                color: (theme) => theme.palette.text.primary,
                                            },
                                        }}
                                    >
                                        <StepLabel
                                            StepIconProps={{
                                                active: isActiveStep(step.number),
                                            }}
                                            className={isActiveStep(step.number) ? 'Mui-active' : ''}
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {step.label}
                                            </Typography>
                                        </StepLabel>
                                    </StepButton>

                                    {isStepEditable(step.number) && (
                                        <Button
                                            onClick={handleStep(step.number)}
                                            sx={{ mr: 1 }}
                                            startIcon={<ModeOutlinedIcon />}
                                        >
                                            Edit
                                        </Button>
                                    )}

                                    {isStepEditable(step.number) === false && (
                                        <IconButton
                                            disabled={!isStepExpandable(step.number)}
                                            size="small"
                                            onClick={handleStep(step.number)}
                                        >
                                            <>
                                                {isStepExpanded(step.number) && <ExpandLessIcon />}
                                                {!isStepExpanded(step.number) && <ExpandMoreIcon />}
                                            </>
                                        </IconButton>
                                    )}
                                </Stack>
                                <StepContent
                                    sx={{
                                        borderLeft: 'none',
                                        mx: 0,
                                        px: 1,
                                    }}
                                >
                                    {step?.description && <Typography>{step.description}</Typography>}

                                    <Box sx={{ my: 2 }}>
                                        {activeStep === STEP_SELECT_DEALER && (
                                            <>
                                                <FormControl
                                                    sx={{
                                                        width: '350px',
                                                        my: 2,
                                                    }}
                                                >
                                                    <DealerSelector
                                                        label="Select Advertiser"
                                                        agencyId={agencyId}
                                                        dealerId={dealerId}
                                                        dealerSelected={(dealerId, dealer: Dealer | null) => {
                                                            // setDealerId(dealer ? dealer.id : 0);
                                                            setDealer(dealer);
                                                        }}
                                                    />
                                                </FormControl>

                                                {dealer && !dealer.amcInstanceId && (
                                                    <Alert
                                                        severity="warning"
                                                        sx={{
                                                            '& .MuiAlert-message': {
                                                                width: '100%',
                                                            },
                                                        }}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <AlertTitle sx={{ m: 0 }}>
                                                                AMC Instance not setup for this Advertiser. You can
                                                                upload the data but you won't be able to run an
                                                                Attribution report. Please contact{' '}
                                                                <a href="mailto:support@cognitionads.com">
                                                                    support@cognitionads.com
                                                                </a>{' '}
                                                                for assistance.
                                                            </AlertTitle>
                                                        </Stack>
                                                    </Alert>
                                                )}
                                            </>
                                        )}

                                        {activeStep === STEP_SELECT_FILE && (
                                            <Grid container spacing={3}>
                                                <Grid item md={4}>
                                                    <StyledInputLabel
                                                        required={true}
                                                        sx={{
                                                            color: theme.palette.text.primary,
                                                            marginBottom: theme.spacing(2),
                                                        }}
                                                    >
                                                        What type of data is in the CSV file?
                                                    </StyledInputLabel>

                                                    <FormControl style={{ width: '250px' }}>
                                                        <TextField
                                                            label="Select Data Type"
                                                            select
                                                            required={true}
                                                            size="small"
                                                            SelectProps={{ native: false }}
                                                            value={crmDataType}
                                                            onChange={(event) => {
                                                                setCrmDataType(event.target.value);

                                                                if (salesDataUpload) {
                                                                    setSalesDataUpload({
                                                                        ...salesDataUpload,
                                                                        ...{
                                                                            crmDataType: event.target.value,
                                                                        },
                                                                    });
                                                                }
                                                            }}
                                                            sx={{
                                                                '& .MuiInputLabel-asterisk': {
                                                                    display: 'none',
                                                                },
                                                            }}
                                                        >
                                                            {dataSets.map((dataSet) => {
                                                                return (
                                                                    <MenuItem key={dataSet.name} value={dataSet.name}>
                                                                        {dataSet.name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </TextField>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item md={8} />

                                                <Grid item md={12}>
                                                    <StyledInputLabel
                                                        required={true}
                                                        sx={{
                                                            color: theme.palette.text.primary,
                                                            marginBottom: theme.spacing(2.5),
                                                        }}
                                                    >
                                                        Specify the date range for this data.
                                                    </StyledInputLabel>

                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Stack direction="row" gap={2}>
                                                            <DatePicker
                                                                label="Start Date"
                                                                value={beginDate}
                                                                disableFuture={true}
                                                                onChange={(newValue) => {
                                                                    setBeginDate(newValue);

                                                                    if (newValue !== null) {
                                                                        if (salesDataUpload) {
                                                                            setSalesDataUpload({
                                                                                ...salesDataUpload,
                                                                                ...{
                                                                                    beginDate: newValue,
                                                                                },
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                                components={{
                                                                    OpenPickerIcon: ArrowDropDownOutlinedIcon,
                                                                }}
                                                                OpenPickerButtonProps={{
                                                                    sx: {
                                                                        '& .MuiSvgIcon-root': {
                                                                            color: theme.palette.action.active,
                                                                        },
                                                                    },
                                                                }}
                                                                PaperProps={{
                                                                    sx: {
                                                                        borderRadius: '8px',
                                                                    },
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField size="small" {...params} />
                                                                )}
                                                            />

                                                            <DatePicker
                                                                label="End Date"
                                                                value={endDate}
                                                                disableFuture={true}
                                                                onChange={(newValue) => {
                                                                    setEndDate(newValue);

                                                                    if (newValue !== null) {
                                                                        if (salesDataUpload) {
                                                                            setSalesDataUpload({
                                                                                ...salesDataUpload,
                                                                                ...{
                                                                                    endDate: newValue,
                                                                                },
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                                components={{
                                                                    OpenPickerIcon: ArrowDropDownOutlinedIcon,
                                                                }}
                                                                OpenPickerButtonProps={{
                                                                    sx: {
                                                                        '& .MuiSvgIcon-root': {
                                                                            color: theme.palette.action.active,
                                                                        },
                                                                    },
                                                                }}
                                                                PaperProps={{
                                                                    sx: {
                                                                        borderRadius: '8px',
                                                                    },
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField size="small" {...params} />
                                                                )}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                </Grid>

                                                <Grid item md={12}>
                                                    <Stack direction="column" spacing={2}>
                                                        <StyledInputLabel
                                                            required={true}
                                                            sx={{
                                                                color: theme.palette.text.primary,
                                                            }}
                                                        >
                                                            Select a file to upload.
                                                        </StyledInputLabel>

                                                        <Grid container>
                                                            <Grid item md={6}>
                                                                <Alert
                                                                    severity="info"
                                                                    sx={{
                                                                        '& .MuiAlert-message': {
                                                                            width: '100%',
                                                                        },
                                                                    }}
                                                                >
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <AlertTitle>
                                                                            CSV Data & Format Requirements
                                                                        </AlertTitle>
                                                                    </Stack>
                                                                    Please review these requirements before selecting
                                                                    your file. Both the quality and completeness of the
                                                                    data will significantly impact the rate at which
                                                                    events match back to an Amazon account.
                                                                    <Box sx={{ mt: 0.5 }}>
                                                                        <Button
                                                                            color="info"
                                                                            variant="text"
                                                                            onClick={() =>
                                                                                setRequirementDialogOpen(true)
                                                                            }
                                                                        >
                                                                            View Requirements
                                                                        </Button>

                                                                        <Button
                                                                            component={Link}
                                                                            color="info"
                                                                            variant="text"
                                                                            href="/resources/Cognition CSV Template.csv"
                                                                            target="new"
                                                                        >
                                                                            Download CSV Template
                                                                        </Button>
                                                                    </Box>
                                                                </Alert>

                                                                {requirementDialogOpen ? (
                                                                    <CRMDataUploadRequirementDialog
                                                                        open={requirementDialogOpen}
                                                                        onClose={() => setRequirementDialogOpen(false)}
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </Grid>
                                                        </Grid>

                                                        <Stack direction="row" alignItems="center" gap={2}>
                                                            <StyledInputLabel htmlFor="btn-uploadRequest">
                                                                <input
                                                                    ref={$file}
                                                                    id="btn-uploadRequest"
                                                                    name="btn-uploadRequest"
                                                                    style={{ display: 'none' }}
                                                                    type="file"
                                                                    accept=".csv"
                                                                    onChange={onFileChange}
                                                                    disabled={!crmDataType || !beginDate || !endDate}
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    component="span"
                                                                    disabled={!crmDataType || !beginDate || !endDate}
                                                                >
                                                                    Select File
                                                                </Button>
                                                            </StyledInputLabel>

                                                            <div>
                                                                {!fileName ? (
                                                                    <Typography
                                                                        variant="body2"
                                                                        component="span"
                                                                        color="text.disabled"
                                                                    >
                                                                        No file selected
                                                                    </Typography>
                                                                ) : (
                                                                    <>
                                                                        <Typography
                                                                            variant="body2"
                                                                            component="span"
                                                                            color="text.secondary"
                                                                        >
                                                                            {fileName}
                                                                        </Typography>

                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={handleDetachFile}
                                                                            sx={{ marginLeft: 0.5 }}
                                                                        >
                                                                            <DeleteOutlineIcon />
                                                                        </IconButton>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Stack>

                                                        {false && (
                                                            <Alert severity="warning">
                                                                Both the quality and completeness of the data will
                                                                significantly impact the rate at which events match back
                                                                to an Amazon account.
                                                            </Alert>
                                                        )}
                                                    </Stack>
                                                </Grid>

                                                {validationResponse && (
                                                    <>
                                                        {validationResponse?.lastUpload && (
                                                            <Grid item md={12}>
                                                                <Alert severity="info">
                                                                    <strong>
                                                                        {validationResponse.lastUpload.uploadedByName}
                                                                    </strong>{' '}
                                                                    last uploaded{' '}
                                                                    <strong>{Utils.formatDate(beginDate)}</strong>
                                                                    {' - '}
                                                                    <strong>{Utils.formatDate(endDate)}</strong>{' '}
                                                                    <strong>
                                                                        {validationResponse.lastUpload.crmDataType}
                                                                    </strong>{' '}
                                                                    data on{' '}
                                                                    <strong>
                                                                        {Utils.formatDate(
                                                                            validationResponse.lastUpload.dateUploaded
                                                                        )}
                                                                    </strong>
                                                                </Alert>
                                                            </Grid>
                                                        )}

                                                        {!validationResponse?.validCsv && (
                                                            <Grid item md={12}>
                                                                <Alert severity="error">
                                                                    {file?.name} does not appear to be a valid CSV file.
                                                                    Please select another file.
                                                                </Alert>
                                                            </Grid>
                                                        )}

                                                        {validationResponse?.duplicate && (
                                                            <Grid item md={12}>
                                                                <Alert severity="error">
                                                                    {file?.name} has already been uploaded and
                                                                    processed. Please select another file.
                                                                </Alert>
                                                            </Grid>
                                                        )}

                                                        {validationResponse?.validCsv &&
                                                            validationResponse?.dateRanges.length === 0 && (
                                                                <Grid item md={12}>
                                                                    <Alert severity="error">
                                                                        {file?.name} is missing the Sales or Activity
                                                                        Dates. Please select another file.
                                                                    </Alert>
                                                                </Grid>
                                                            )}
                                                    </>
                                                )}
                                            </Grid>
                                        )}

                                        {activeStep === STEP_MAP && (
                                            <>
                                                {salesDataUpload && (
                                                    <CRMDataUploadMapping
                                                        saveInProgress={saveInProgress}
                                                        onValidate={(validated: boolean) => {
                                                            setIsMapStepValidated(validated);
                                                        }}
                                                        onSave={handleOnSave}
                                                        salesDataUpload={salesDataUpload}
                                                    />
                                                )}
                                            </>
                                        )}

                                        {activeStep === STEP_REVIEW && (
                                            <>
                                                {salesDataUpload && (
                                                    <CRMDataUploadMappingReview
                                                        salesDataUpload={salesDataUpload}
                                                        salesDataMapping={salesDataMapping}
                                                        sampleMappedData={sampleMappedData}
                                                        mappedDataSummary={mappedDataSummary}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Box>

                                    {isEndStep(step.number) === false && (
                                        <Box sx={{ mb: 2 }}>
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Button
                                                    variant={isEndStep(step.number) ? 'outlined' : 'text'}
                                                    onClick={handleCancel}
                                                    disabled={!isStepCancellable(step.number)}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant={isEndStep(step.number) ? 'contained' : 'text'}
                                                    onClick={handleSave}
                                                    disabled={!isStepSaveable(step.number)}
                                                >
                                                    {isEndStep(step.number) ? 'Upload' : 'Save'}
                                                </Button>
                                            </Stack>
                                        </Box>
                                    )}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>

                    <Box sx={{ mt: 2 }}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                            <Button
                                variant="outlined"
                                onClick={handleCancelSetup}
                                disabled={!isStepCancellable(STEP_SELECT_FILE)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSave}
                                disabled={!isStepSaveable(STEP_REVIEW) || !isEndStep(activeStep)}
                            >
                                Upload
                            </Button>
                        </Stack>
                    </Box>
                </>
            )}
            {setupCompleted === true && <CRMDataUploadSetupSuccessful resetSetup={resetSetup} />}
        </Box>
    );
}
