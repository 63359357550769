import { Chip, CircularProgress, Typography } from '@mui/material';

import Row from '../../../components/Row';

export type CampaignDuplicateLoaderProps = {
    isLoading?: boolean;
};

export default function CampaignDuplicateLoader(props: CampaignDuplicateLoaderProps) {
    const { isLoading = false } = props;

    if (isLoading === false) {
        return <></>;
    }

    return (
        <Row alignItems="center" sx={{ height: 40 }}>
            <Chip
                icon={<CircularProgress size={16} />}
                label={<Typography variant="body2">Checking for duplicates…</Typography>}
            />
        </Row>
    );
}
