import Heading from '../../components/Heading';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ApiService from '../../ApiService';
import { AmcAttribution } from '../../types/AmcAttribution';
import { useParams } from 'react-router-dom';
import AmcAttributionForm from './AmcAttributionForm';

export default function AmcAttributionEdit() {
    const params = useParams();
    const id = params.id ? +params.id : null;
    const [amcAttribution, setAmcAttribution] = useState<AmcAttribution>(new AmcAttribution());

    useEffect(() => {
        if (id) {
            ApiService.getAmcAttribution(id).then((response) => {
                setAmcAttribution(response.data);
            });
        }
    }, [id]);

    return (
        <Box
            sx={{
                '& .row': {
                    cursor: 'pointer',
                },
            }}
        >
            <Heading>Edit AMC Offline Attribution</Heading>

            <AmcAttributionForm
                size="md"
                amcAttribution={amcAttribution}
                onSave={(_amcAttribution: AmcAttribution) => {}}
            />
        </Box>
    );
}
