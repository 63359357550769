import { useEffect, useState } from 'react';
// import { styled, tableCellClasses, tableRowClasses } from '@mui/material';
import {
    Box,
    // Table,
    TableBody,
    // TableCell,
    // TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Card } from './CampaignWizard/CampaignWizardStyles';

import { AudienceSource } from '../../../types/AudienceSource';
import { Campaign } from '../../../types/Campaign';
import { Location } from '../../../types/Location';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';
import { Table } from '../../../components/Table';
import { TableCell, TablePrimaryCell } from './CampaignWizard/CampaignWizardStyles';
import AudienceSourceField from '../../../components/Audience/AudienceSourceField';
import Column from '../../../components/Column';
import LocationList from '../../../components/Location/LocationList';

type CampaignSummaryTargetingTableProps = {
    campaign: Campaign;
};

export default function CampaignSummaryTargetingTable(props: CampaignSummaryTargetingTableProps) {
    const { campaign } = props;

    const locationCount = campaign?.adGroups?.[0]?.adGroupLocations?.length ?? 0;
    const geoLocationCount = campaign?.adGroups?.[0]?.adGroupGeoLocations?.length ?? 0;
    const audienceSourceCount = campaign?.adGroups?.[0]?.adGroupAudienceSources?.length ?? 0;

    // Audience Sources
    const { $audienceSources } = useCampaignWizardContext();
    const { audienceSources: selections = [], getCampaignAudienceSources = undefined } = $audienceSources || {};
    const [audienceSources, setAudienceSources] = useState<AudienceSource[]>([]);

    // Locations
    const { $locations } = useCampaignWizardContext();
    const {
        locations: locationSelections = [],
        getCampaignLocations = undefined,
        getCampaignGeoLocations = undefined,
    } = $locations || {};
    const [locations, setLocations] = useState<Location[]>([]);
    const [geoLocations, setGeoLocations] = useState<Location[]>([]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (getCampaignAudienceSources) {
                setAudienceSources(getCampaignAudienceSources(campaign));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, selections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (getCampaignLocations) {
                setLocations(getCampaignLocations(campaign));
            }
            if (getCampaignGeoLocations) {
                setGeoLocations(getCampaignGeoLocations(campaign));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, locationSelections]);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>Targeting</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <TableRow>
                    <TablePrimaryCell>Locations</TablePrimaryCell>
                    <TableCell>
                        <Column gap={2}>
                            <Typography variant="body2">
                                {locationCount > 1 ? `${locationCount} Locations` : `${locationCount} Location`}
                            </Typography>

                            {locations.length > 0 && (
                                <Card variant="outlined">
                                    <Box
                                        sx={{
                                            maxHeight: 200,
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <LocationList items={locations} />
                                    </Box>
                                </Card>
                            )}
                        </Column>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TablePrimaryCell>Zip Codes</TablePrimaryCell>
                    <TableCell>
                        <Column gap={2}>
                            <Typography variant="body2">
                                {geoLocationCount > 1
                                    ? `${geoLocationCount} Locations`
                                    : `${geoLocationCount} Location`}
                            </Typography>

                            {geoLocations.length > 0 && (
                                <Card variant="outlined">
                                    <Box
                                        sx={{
                                            maxHeight: 200,
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <LocationList items={geoLocations} />
                                    </Box>
                                </Card>
                            )}
                        </Column>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TablePrimaryCell>Audience</TablePrimaryCell>
                    <TableCell>
                        <Column gap={2}>
                            <Typography variant="body2">
                                {audienceSourceCount > 1
                                    ? `${audienceSourceCount} Audience Categories`
                                    : `${audienceSourceCount} Audience Category`}
                            </Typography>

                            {audienceSources.length > 0 && <AudienceSourceField items={audienceSources} />}
                        </Column>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
