import { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import Utils from '../../components/Utils';
import { IFilterItem } from './SalesInsights';
import CDDateRangePicker from '../../components/CDDateRangePicker';
import { isSameDay, isSameMonth } from 'date-fns';
import { Range, StaticRange } from 'react-date-range';
import { getDefaultDates } from './utils';

const SelectMonth = ({
    id,
    setFilters,
    filters,
}: {
    id: number;
    setFilters: (filters: IFilterItem[]) => void;
    filters: IFilterItem[];
}) => {
    const {
        minDate: defaultMinDate,
        maxDate: defaultMaxDate,
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    } = getDefaultDates();

    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const ranges = useMemo(() => {
        const _ranges: StaticRange[] = [
            {
                label: 'Last Month',
                range() {
                    return {
                        startDate: Utils.getMonthStart(-1),
                        endDate: Utils.getMonthEnd(-1),
                    };
                },
                isSelected: (range: Range) => {
                    if (range.startDate && range.endDate) {
                        return (
                            isSameDay(range.startDate, Utils.getMonthStart(-1)) &&
                            isSameDay(range.endDate, Utils.getMonthEnd(-1))
                        );
                    } else {
                        return false;
                    }
                },
            },
        ];

        if (isSameMonth(maxDate, new Date())) {
            _ranges.push({
                label: 'This Month',
                range() {
                    return {
                        startDate: Utils.getMonthStart(0),
                        endDate: new Date(),
                    };
                },
                isSelected: (range: Range) => {
                    if (range.startDate && range.endDate) {
                        return (
                            isSameDay(range.startDate, Utils.getMonthStart(0)) && isSameDay(range.endDate, new Date())
                        );
                    } else {
                        return false;
                    }
                },
            });
        }

        return _ranges;
    }, [maxDate]);

    useEffect(() => {
        setMinDate(defaultMinDate);
        setMaxDate(defaultMaxDate);
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let newStartDate: Date | null = defaultStartDate;
        let newEndDate: Date | null = defaultEndDate;

        if (filters?.[id]?.children?.length === 1) {
            const ids = filters?.[id]?.children?.[0]?.id?.split('@');
            if (ids?.length === 2) {
                newStartDate = Utils.formatStringToDate(ids[0]);
                newEndDate = Utils.formatStringToDate(ids[1]);
            }
        }

        if (newStartDate) {
            setStartDate(newStartDate);
        }
        if (newEndDate) {
            setEndDate(newEndDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, id, maxDate]);

    return (
        <CDDateRangePicker
            label="Date Range"
            ranges={ranges}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            disableFuture={true}
            sx={{ mb: 1 }}
            onChange={(startDate, endDate) => {
                if (startDate && endDate) {
                    const newFilters = [...filters];
                    newFilters[id]?.children?.splice(0, newFilters[id]?.children?.length);
                    newFilters[id]?.children?.push({
                        id: moment(startDate).format('YYYY-MM-DD') + '@' + moment(endDate).format('YYYY-MM-DD'),

                        label: moment(startDate).format('MMMM DD YYYY') + '-' + moment(endDate).format('MMMM DD YYYY'),
                    });
                    setFilters(newFilters);
                }
            }}
        />
    );
};

export default SelectMonth;
