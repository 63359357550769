import { Dealer } from '../../../types/Dealer';
import { ChangeEvent, useState } from 'react';
import {
    Alert,
    Box,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItem,
    Paper,
    Tooltip,
    tooltipClasses,
} from '@mui/material';
import Utils from '../../../components/Utils';
import Column from '../../../components/Column';
import { CDTextField } from '../../../components/CDTextField';
import CampaignType from '../../../components/CampaignType';
import CreativeAssetsComponent from './CreativeAssetsComponent';
import { AttachFile } from '@mui/icons-material';
import { CampaignCard } from './CampaignCard';
import { Campaign } from '../../../types/Campaign';
import SummaryText from '../SummaryText';
import AssignDspCampaignDialog from '../campaignDetailView/AssignDspCampaignDialog';
import ApiService from '../../../ApiService';
import theme from '../../../theme';
import { styled } from '@mui/styles';
import { TooltipProps } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 700,
    },
});
export default function CampaignSummary(props: {
    campaign: Campaign;
    reloadCampaign(): void;
    dealer: Dealer | null;
    files: File[];
    allowEdit: boolean;
    statusChange(event: ChangeEvent<HTMLInputElement>): void;
    onChange(value: Partial<Campaign>): void;
}) {
    const { campaign, dealer, files, onChange, reloadCampaign, allowEdit } = props;
    const dateRange = Utils.formatDate(campaign.startDate) + ' - ' + Utils.formatDate(campaign.endDate);

    const [assignDspCampaign, setAssignDspCampaign] = useState(false);

    return (
        <>
            {assignDspCampaign && (
                <AssignDspCampaignDialog
                    campaign={campaign}
                    onClose={(dspCampaignId: string | null) => {
                        setAssignDspCampaign(false);
                        if (dspCampaignId !== null) {
                            const updateCampaign = { ...campaign, dspCampaignId: dspCampaignId };
                            ApiService.updateCampaign(updateCampaign).then(() => {
                                reloadCampaign();
                            });
                        }
                    }}
                />
            )}

            <CampaignCard elevation={4}>
                {campaign.id === undefined && <CardHeader title="Campaign Summary" />}
                <CardContent>
                    <Column>
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                '& .MuiGrid-item': {
                                    paddingLeft: '8px',
                                },
                            }}
                        >
                            {campaign.id === undefined && (
                                <>
                                    <Grid item xs={4}>
                                        <SummaryText label="Advertiser" value={dealer?.dealerName} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SummaryText
                                            label="Media / Campaign / Strategy"
                                            value={
                                                <CampaignType
                                                    mediaType={campaign.mediaType}
                                                    campaignType={campaign.campaignType}
                                                    strategyType={campaign.strategyType}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SummaryText
                                            label="Start / End Date"
                                            value={campaign.startDate ? dateRange : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SummaryText
                                            label="Budget"
                                            value={
                                                campaign.budget ? '$ ' + Number(campaign.budget).toLocaleString() : ''
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SummaryText
                                            label="Impression Goal"
                                            value={
                                                campaign.impressionGoal
                                                    ? Number(campaign.impressionGoal).toLocaleString()
                                                    : 'None'
                                            }
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12}>
                                <SummaryText
                                    label="Geography Targeting"
                                    value={
                                        <span>
                                            {campaign.zipcodes && (
                                                <span>
                                                    {campaign.zipcodes?.split(',').length + ' Zip Codes'}
                                                    <br />
                                                </span>
                                            )}
                                            {campaign.geographyTargeting && (
                                                <>
                                                    <span>{campaign.geographyTargeting.substring(0, 230)}</span>
                                                    {campaign.geographyTargeting.length > 250 && (
                                                        <CustomWidthTooltip title={campaign.geographyTargeting}>
                                                            <span
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: theme.palette.primary.main,
                                                                }}
                                                            >
                                                                {' '}
                                                                more...
                                                            </span>
                                                        </CustomWidthTooltip>
                                                    )}
                                                </>
                                            )}
                                        </span>
                                    }
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <SummaryText
                                    label="Audience Targeting"
                                    value={
                                        <List sx={{ pt: 0, pb: 0 }} dense>
                                            <ListItem sx={{ p: 0 }}>In-Market Consumer</ListItem>
                                            {campaign.dataSets &&
                                                campaign.dataSets
                                                    .filter((ds) => ds.audienceTargeting !== 'X')
                                                    .map((dataSet) => (
                                                        <ListItem sx={{ p: 0 }}>
                                                            Custom Audience:{' '}
                                                            {dataSet.audienceTargeting === 'I'
                                                                ? 'Include '
                                                                : 'Exclude '}{' '}
                                                            {dataSet.dataSetName}
                                                        </ListItem>
                                                    ))}
                                            {campaign.audienceDemographic && (
                                                <ListItem sx={{ p: 0 }}>Demographic</ListItem>
                                            )}
                                            {campaign.audienceLifeStageEvents && (
                                                <ListItem sx={{ p: 0 }}>Life Stage</ListItem>
                                            )}
                                        </List>
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryText label="Targeting Keywords" value={campaign.audienceTargeting} />
                            </Grid>
                            <Grid item xs={4}></Grid>

                            {campaign.callToActions && (
                                <Grid item xs={12}>
                                    <SummaryText label="Call To Action" value={campaign.callToActions} />
                                </Grid>
                            )}

                            <Grid item xs={4}>
                                <SummaryText label="Email for Approval" value={campaign.emailForApproval} />
                            </Grid>
                            <Grid item xs={8}>
                                <SummaryText label="Inventory Provider" value={campaign.inventoryProvider} />
                            </Grid>

                            {campaign.landingPage && (
                                <Grid item xs={12}>
                                    <SummaryText label="Landing Page" value={campaign.landingPage} />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <SummaryText
                                    label="Creative Assets"
                                    value={
                                        <>
                                            {campaign.creativeAssetLink && (
                                                <a target="new" href={campaign.creativeAssetLink}>
                                                    {campaign.creativeAssetLink}
                                                </a>
                                            )}
                                            <CreativeAssetsComponent
                                                viewOnly={true}
                                                campaign={campaign}
                                                onFilesChanged={(files) => {}}
                                                maxFileSize={0}
                                            />
                                            {files.length > 0 && (
                                                <List dense>
                                                    {files.map((f) => (
                                                        <ListItem>
                                                            <AttachFile /> {f.name}
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            )}
                                            {campaign.id === undefined &&
                                                files.length === 0 &&
                                                campaign.creativeAssetLink === null && (
                                                    <Alert severity="info">
                                                        Step 2 - Creative Assets is incomplete.
                                                    </Alert>
                                                )}
                                        </>
                                    }
                                />
                            </Grid>
                            {!allowEdit && (
                                <Grid item xs={12}>
                                    <SummaryText label="Additional Comments" value={campaign.additionalComments} />
                                </Grid>
                            )}
                            <Grid item xs={12}></Grid>
                        </Grid>
                    </Column>
                </CardContent>
            </CampaignCard>

            {allowEdit && (
                <Paper elevation={4} sx={{ marginTop: '15px' }}>
                    <Box padding={3}>
                        <CDTextField
                            fullWidth
                            name="additionalComments"
                            label="Additional Comments"
                            value={campaign.additionalComments ?? ''}
                            required={false}
                            variant="outlined"
                            multiline
                            rows={5}
                            disabled={campaign.deliveryStatusId === 'ENDED'}
                            onChange={(event) => onChange({ additionalComments: event.target.value })}
                        />
                    </Box>
                </Paper>
            )}
        </>
    );
}
