import moment from 'moment-timezone';

export const PlatfromUsers = [
    {
        id: 'aws',
        label: 'Amazon User',
    },
    {
        id: 'no_aws',
        label: 'Non-Amazon User',
    },
];

export const PurchaseType = [
    {
        id: 'N',
        label: 'New Sales',
    },
    {
        id: 'U',
        label: 'Used Sales',
    },
];

export const States = [
    { label: 'Alabama', id: 'AL' },
    { label: 'Alaska', id: 'AK' },
    { label: 'Arizona', id: 'AZ' },
    { label: 'Arkansas', id: 'AR' },
    { label: 'California', id: 'CA' },
    { label: 'Colorado', id: 'CO' },
    { label: 'Connecticut', id: 'CT' },
    { label: 'Delaware', id: 'DE' },
    { label: 'District of Columbia', id: 'DC' },
    { label: 'Florida', id: 'FL' },
    { label: 'Georgia', id: 'GA' },
    { label: 'Hawaii', id: 'HI' },
    { label: 'Idaho', id: 'ID' },
    { label: 'Illinois', id: 'IL' },
    { label: 'Indiana', id: 'IN' },
    { label: 'Iowa', id: 'IA' },
    { label: 'Kansas', id: 'KS' },
    { label: 'Kentucky', id: 'KY' },
    { label: 'Louisiana', id: 'LA' },
    { label: 'Maine', id: 'ME' },
    { label: 'Maryland', id: 'MD' },
    { label: 'Massachusetts', id: 'MA' },
    { label: 'Michigan', id: 'MI' },
    { label: 'Minnesota', id: 'MN' },
    { label: 'Mississippi', id: 'MS' },
    { label: 'Missouri', id: 'MO' },
    { label: 'Montana', id: 'MT' },
    { label: 'Nebraska', id: 'NE' },
    { label: 'Nevada', id: 'NV' },
    { label: 'New Hampshire', id: 'NH' },
    { label: 'New Jersey', id: 'NJ' },
    { label: 'New Mexico', id: 'NM' },
    { label: 'New York', id: 'NY' },
    { label: 'North Carolina', id: 'NC' },
    { label: 'North Dakota', id: 'ND' },
    { label: 'Ohio', id: 'OH' },
    { label: 'Oklahoma', id: 'OK' },
    { label: 'Oregon', id: 'OR' },
    { label: 'Pennsylvania', id: 'PA' },
    { label: 'Puerto Rico', id: 'PR' },
    { label: 'Rhode Island', id: 'RI' },
    { label: 'South Carolina', id: 'SC' },
    { label: 'South Dakota', id: 'SD' },
    { label: 'Tennessee', id: 'TN' },
    { label: 'Texas', id: 'TX' },
    { label: 'Utah', id: 'UT' },
    { label: 'Vermont', id: 'VT' },
    { label: 'Virginia', id: 'VA' },
    { label: 'Washington', id: 'WA' },
    { label: 'West Virginia', id: 'WV' },
    { label: 'Wisconsin', id: 'WI' },
    { label: 'Wyoming', id: 'WY' },
];

export const removeItemAtIndex = (arr: any[], indices: number[]) => {
    const newData = JSON.parse(JSON.stringify(arr));
    let currentArray = newData;
    let lastIndex = indices.length - 1;

    for (let i = 0; i < lastIndex; i++) {
        currentArray = currentArray[indices[i]].children;
    }

    // Remove the item at the specified index
    currentArray.splice(indices[lastIndex], 1);

    // Return the new array
    return newData;
};

export const getDefaultDates = () => {
    let minDate = new Date(2024, 0, 1);
    let maxDate: Date = new Date();
    let startDate: Date = new Date();
    let endDate: Date = new Date();

    const today = moment();
    const currentDayOfMonth = today.date();

    if (currentDayOfMonth < 8) {
        // Before the 8th of the month: Start from the first day of the last month
        // at which Polk Sales data are scheduled we received.
        const lastMonthStart: Date = moment().subtract(1, 'month').startOf('month').toDate();
        const lastMonthEnd: Date = moment().subtract(1, 'month').endOf('month').toDate();

        startDate = lastMonthStart;
        endDate = lastMonthEnd;
        maxDate = lastMonthEnd;
    } else {
        // From the 8th of the month onwards: Last 30 days for start date and today for end date
        const last30Days: Date = moment().subtract(30, 'days').toDate();

        startDate = last30Days;
        endDate = new Date();
        maxDate = new Date();
    }

    return {
        minDate,
        maxDate,
        startDate,
        endDate,
    };
};
