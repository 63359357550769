import Heading from '../../components/Heading';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import {
    Alert,
    AlertColor,
    AppBar,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiService from '../../ApiService';
import { SalesDataMapping, SalesDataMappingField } from '../../types/SalesDataMapping';
import {
    CachedOutlined,
    CloseOutlined,
    DeleteOutlined,
    InfoOutlined,
    SaveOutlined,
    SendOutlined,
} from '@mui/icons-material';
import { DataSetValue } from '../../types/DataSetValue';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import Utils from '../../components/Utils';
import DateFormats from './DateFormats';
import { MappedDataSummary } from '../../types/MappedDataSummary';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { UserContext } from '../../App';
import SendToAMCDialog from './SendToAMCDialog';
import { DataSet } from '../../types/DataSet';
import FirstPartyDataGrid from './FirstPartyDataGrid';
import MAIDDataGrid from './MAIDDataGrid';

export default function SalesDataUploadMapping() {
    enum Fields {
        EMAIL = 'Email',
        SALES_DATE = 'Sale Date',
        FULL_NAME = 'Full Name',
        FIRST_NAME = 'First Name',
        LAST_NAME = 'Last Name',
        PHONE_NUMBER = 'Primary Phone',
        PHONE_NUMBER_2 = 'Secondary Phone',
        PHONE_NUMBER_3 = 'Alternate Phone',
        MAKE = 'Make',
        MODEL = 'Model',
        INT1 = 'Integer 1',
        INT2 = 'Integer 2',
        INT3 = 'Integer 3',
        DECIMAL1 = 'Decimal 1',
        DECIMAL2 = 'Decimal 2',
        DECIMAL3 = 'Decimal 3',
        STRING1 = 'String 1',
        STRING2 = 'String 2',
        STRING3 = 'String 3',

        MAID = 'MAID',
        SEGMENT_ID = 'Segment ID',
        DEVICE_TYPE = 'Device Type',
        ADDRESS = 'Address',
        CITY = 'City',
        STATE = 'State',
        ZIPCODE = 'Zip Code',
    }

    type FieldsType = typeof Fields;
    type FieldsKeyType = keyof FieldsType;

    const location = useLocation();
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [mappingDirty, setMappingDirty] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [salesDataMapping, setSalesDataMapping] = useState<SalesDataMapping>({
        dealerId: 0,
        dateFormat: '',
        fields: [],
    });
    const [mappedDataSummary, setMappedDataSummary] = useState<MappedDataSummary>();
    const [sampleMappedData, setSampleMappedData] = useState<DataSetValue[]>([]);
    const [dataSets, setDataSets] = useState<DataSet[]>([]);
    const [uploadedData, setUploadedData] = useState<string[][]>([]);
    const [salesDataUpload, setSalesDataUpload] = useState<SalesDataUpload>(location.state);
    const [showDialog, setShowDialog] = useState(false);
    const navigate = useNavigate();
    const { userContext } = useContext(UserContext);
    const isAdmin = userContext.isAdmin();

    // Data Set data contains Mobile Advertising ID data
    function isMAIDDataSet(): boolean {
        return salesDataUpload.crmDataType.indexOf('MAID') > -1;
    }

    // Only show the appropriate Field types based on the Data Set type
    function includeField(mappingField: SalesDataMappingField): boolean {
        const field = Fields[mappingField.fieldName as FieldsKeyType];
        if (isMAIDDataSet()) {
            return (
                field === Fields.SALES_DATE ||
                field === Fields.MAID ||
                field === Fields.SEGMENT_ID ||
                field === Fields.DEVICE_TYPE
            );
        } else {
            return field !== Fields.MAID && field !== Fields.SEGMENT_ID && field !== Fields.DEVICE_TYPE;
        }
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    const getMappedDataSummary = useCallback(
        (mapping: SalesDataMapping) => {
            ApiService.getMappedDataSummary(salesDataUpload.id, mapping)
                .then((response) => {
                    setMappedDataSummary(response.data);
                    let dataSetValues = response.data.dataSetValues;
                    if (dataSetValues.length > 0) {
                        let id = 0;
                        dataSetValues.forEach((r) => (r.id = id++));
                        setSampleMappedData(dataSetValues);
                    }
                    let uploadedData = response.data.uploadedData;
                    setUploadedData(uploadedData);
                })
                .catch(() => {});
        },
        [salesDataUpload.id]
    );

    const loadMapping = useCallback(() => {
        ApiService.getSalesDataMapping(salesDataUpload.id)
            .then((response) => {
                setSalesDataMapping(response.data);
                getMappedDataSummary(response.data);
            })
            .catch(() => {});
    }, [getMappedDataSummary, salesDataUpload.id]);

    useEffect(() => {
        loadMapping();
        ApiService.getDataSets()
            .then((response) => {
                setDataSets(response.data);
            })
            .catch(() => {});
    }, [loadMapping]);

    function handleDateFormat(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let mapping = { ...salesDataMapping, dateFormat: event.target.value };
        setMappingDirty(true);
        setSalesDataMapping(mapping);
        getMappedDataSummary(mapping);
    }

    function setCrmDataType(value: string) {
        setMappingDirty(true);
        setSalesDataUpload({ ...salesDataUpload, crmDataType: value });
    }

    function setDate(field: string, date: Date) {
        let newValue = { ...salesDataUpload, [field]: date };
        setMappingDirty(true);
        setSalesDataUpload(newValue);
    }

    function resetMapping() {
        salesDataMapping.fields.forEach((f) => (f.fieldPosition = null));
        setMappingDirty(true);
        ApiService.updateSalesDataMapping(salesDataMapping)
            .then(() => {
                loadMapping();
            })
            .catch(() => {});
    }

    function showAMCDialog() {
        setShowDialog(true);
    }

    function closeAMCDialog() {
        setShowDialog(false);
    }

    function handleSendToAMC(workflowId: number | null, campaignIds: number[]) {
        setShowDialog(false);
        salesDataUpload.workflowId = workflowId;
        salesDataUpload.campaignIds = campaignIds;
        ApiService.queueForAMC(salesDataUpload)
            .then(() => {
                showInfoMessage('success', 'CRM Data has been queued for AMC processing');
                setTimeout(() => {
                    navigate(-1);
                }, 5000);
            })
            .catch(() => {});
    }

    function deleteSalesData() {
        ApiService.deleteSalesData(salesDataUpload.id).then(() => {
            showInfoMessage('success', 'CRM Data has been deleted');
            setTimeout(() => {
                navigate(-1);
            }, 2000);
        });
    }

    function handleFieldPositionChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let fields = salesDataMapping.fields;
        fields.forEach((field) => {
            if (field.fieldPosition === +event.target.name) {
                field.fieldPosition = null;
            }
            if (field.fieldName === event.target.value) {
                if (event.target.value === '-1') {
                    field.fieldPosition = null;
                } else {
                    field.fieldPosition = +event.target.name;
                }
            }
        });
        let mapping = {
            ...salesDataMapping,
            dealerId: salesDataUpload.dealerId,
            fields: fields,
        };
        setMappingDirty(true);
        setSalesDataMapping(mapping);
        getMappedDataSummary(mapping);
    }

    function handleDownload(salesDataUploadId: number) {
        ApiService.downloadSalesData(salesDataUploadId)
            .then((response) => {
                Utils.downloadFile(response, 'text/csv');
            })
            .catch(() => {});
    }

    function handleSave() {
        setSaveInProgress(true);
        ApiService.updateSalesDataMapping(salesDataMapping)
            .then((response) => {
                showInfoMessage('success', 'Mapping successfully saved.');
                setSalesDataMapping(response.data);
                setMappingDirty(false);
                setSaveInProgress(false);
                loadMapping();
            })
            .catch(() => {});
        ApiService.updateSalesDataUpload(salesDataUpload)
            .then(() => {})
            .catch();
    }

    let outOfRange = false;

    mappedDataSummary?.validations.dateRanges.forEach((dr) => {
        salesDataMapping.fields.forEach((f) => {
            if (f.fieldName === 'SALES_DATE') {
                if (dr.fieldPosition === f.fieldPosition) {
                    outOfRange = dr.outOfRange;
                }
            }
        });
    });

    return (
        <Grid>
            <Heading>CRM Data Upload Mapping</Heading>
            {salesDataUpload.amcInstanceId === null && (
                <Alert severity="error">No AMC Instance assigned to this advertiser.</Alert>
            )}
            <Grid container>
                {salesDataUpload.errorMessage && (
                    <Grid item md={11} sx={{ pt: '10px' }}>
                        <Alert severity="error" sx={{ padding: '2px' }}>
                            Upload Status: <b>{Utils.convertCase(salesDataUpload.status)}</b> -{' '}
                            {salesDataUpload.errorMessage}
                        </Alert>
                    </Grid>
                )}

                <Grid item md={5}>
                    <b>File: </b>
                    {userContext.isAdmin() && (
                        <Button
                            sx={{ textDecoration: 'underline' }}
                            color="info"
                            onClick={() => {
                                handleDownload(salesDataUpload.id);
                            }}
                        >
                            {salesDataUpload.originalFileName}
                        </Button>
                    )}
                    {!userContext.isAdmin() && <span>{salesDataUpload.originalFileName}</span>}
                </Grid>
                <Grid item md={3}>
                    <b>Uploaded by:</b> {salesDataUpload.uploadedByName}
                </Grid>
                <Grid item md={4}>
                    <b>Advertiser: </b>
                    {salesDataUpload.dealerName}
                </Grid>
                <Grid item xs={5} sx={{ marginTop: '10px' }}>
                    <FormControl style={{ width: '250px' }}>
                        {dataSets && (
                            <TextField
                                label="Data Type"
                                select
                                required={true}
                                size="small"
                                SelectProps={{ native: false }}
                                variant="filled"
                                value={salesDataUpload.crmDataType}
                                onChange={(event) => {
                                    setCrmDataType(event.target.value);
                                }}
                            >
                                {dataSets.map((dataSet) => {
                                    return (
                                        <MenuItem key={dataSet.name} value={dataSet.name}>
                                            {dataSet.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                    </FormControl>
                </Grid>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={2} sx={{ marginTop: '10px' }}>
                        <DatePicker
                            label="Start Date"
                            value={salesDataUpload.beginDate}
                            onChange={(newValue) => {
                                if (newValue !== null) {
                                    setDate('beginDate', newValue);
                                }
                            }}
                            renderInput={(params) => <TextField variant="filled" size="small" {...params} />}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ marginLeft: '15px', marginTop: '10px' }}>
                        <DatePicker
                            label="End Date"
                            value={salesDataUpload.endDate}
                            onChange={(newValue) => {
                                if (newValue !== null) {
                                    setDate('endDate', newValue);
                                }
                            }}
                            renderInput={(params) => <TextField variant="filled" size="small" {...params} />}
                        />
                    </Grid>
                </LocalizationProvider>
                <Grid item xs={2} />

                {(salesDataUpload.status === 'Completed' || salesDataUpload.status === 'SUCCESS') && (
                    <>
                        <Grid item md={5} sx={{ mt: '10px' }}>
                            <b>Records Submitted: </b>
                            {salesDataUpload.recordsSubmitted}
                        </Grid>
                        <Grid item md={6} sx={{ mt: '10px' }}>
                            <b>Workflow: </b>
                            {salesDataUpload.amcWorkflowId}
                        </Grid>
                        <Grid item md={5} sx={{ mt: '5px' }}>
                            <b>Records Identified: </b>
                            {salesDataUpload.recordsIdentified}
                        </Grid>
                        <Grid item md={6} sx={{ mt: '5px' }}>
                            <b>Workflow Status: </b>
                            {salesDataUpload.workflowExecutionStatus}
                        </Grid>
                    </>
                )}

                {mappedDataSummary?.validations.lastUpload && (
                    <Grid item md={9} sx={{ pt: '10px' }}>
                        <Alert severity="info" sx={{ padding: '2px' }}>
                            {mappedDataSummary.validations.lastUpload.uploadedByName} last uploaded{' '}
                            <b>{mappedDataSummary.validations.lastUpload.crmDataType}</b> data on{' '}
                            <b>{Utils.formatDate(mappedDataSummary.validations.lastUpload.dateUploaded)}</b> for the
                            date range{' '}
                            <b>
                                {Utils.formatDate(mappedDataSummary.validations.lastUploadDateRange?.startDate)} -{' '}
                                {Utils.formatDate(mappedDataSummary.validations.lastUploadDateRange?.endDate)}
                            </b>
                        </Alert>
                    </Grid>
                )}
            </Grid>
            {isAdmin && (
                <>
                    <Divider sx={{ marginTop: '15px', marginBottom: '30px' }} />
                    <form>
                        <Grid container direction="row" spacing={2} sx={{ overflow: 'scroll' }}>
                            <Grid item md={6}>
                                <Typography variant="h6">Uploaded Data</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    size="small"
                                    label="Date Format"
                                    name="dateFormat"
                                    required
                                    onChange={handleDateFormat}
                                    value={salesDataMapping.dateFormat}
                                    variant="filled"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip placement="right" title={<DateFormats />}>
                                                    <IconButton>
                                                        <InfoOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item md={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {uploadedData.length > 0 &&
                                                    uploadedData[0].map((_header, index) => {
                                                        let value = salesDataMapping.fields.find(
                                                            (f) => f.fieldPosition === index
                                                        );
                                                        return (
                                                            <TableCell key={'header' + index}>
                                                                <TextField
                                                                    variant="filled"
                                                                    fullWidth
                                                                    size="small"
                                                                    select
                                                                    name={'' + index}
                                                                    onChange={handleFieldPositionChange}
                                                                    disabled={false}
                                                                    value={value?.fieldName ? value?.fieldName : '-1'}
                                                                    SelectProps={{
                                                                        native: false,
                                                                    }}
                                                                >
                                                                    <MenuItem key={'-1'} value={'-1'}>
                                                                        N/A
                                                                    </MenuItem>
                                                                    {salesDataMapping.fields
                                                                        .filter((f) => includeField(f))
                                                                        .map((field, col) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={col + ''}
                                                                                    value={field.fieldName}
                                                                                >
                                                                                    {
                                                                                        Fields[
                                                                                            field.fieldName as FieldsKeyType
                                                                                        ]
                                                                                    }
                                                                                </MenuItem>
                                                                            );
                                                                        })}
                                                                </TextField>
                                                            </TableCell>
                                                        );
                                                    })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {uploadedData.map((row, rowIndex) => (
                                                <TableRow key={'row' + rowIndex}>
                                                    {row.map((field, fieldIndex) => (
                                                        <TableCell
                                                            key={'' + rowIndex + fieldIndex}
                                                            className={rowIndex === 0 ? 'grid-header' : ''}
                                                        >
                                                            {field}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item md={4}>
                                <Typography variant="h6">Mapped to AMC Data Set</Typography>
                            </Grid>
                            <Grid item md={8}></Grid>

                            {mappedDataSummary && mappedDataSummary?.excludedRows > 0 && (
                                <Grid item md={8}>
                                    <Alert severity="error">
                                        {mappedDataSummary.excludedRows} out of {mappedDataSummary.totalRows} entries
                                        will be excluded due to missing identifier or Date
                                    </Alert>
                                </Grid>
                            )}
                            {outOfRange && (
                                <Grid item md={8}>
                                    <Alert severity="error">
                                        Some uploaded data falls outside of the specified range.
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item md={12}>
                                <Box>
                                    {!isMAIDDataSet() && <FirstPartyDataGrid rows={sampleMappedData} />}
                                    {isMAIDDataSet() && <MAIDDataGrid rows={sampleMappedData} />}
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                    <InfoMessage {...infoMessage}></InfoMessage>
                </>
            )}

            <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0, paddingLeft: '50px' }}>
                <Toolbar>
                    <Grid container gap={2}>
                        <Grid item md={2}></Grid>
                        {isAdmin && (
                            <>
                                <Grid item md={1}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSave}
                                        disabled={saveInProgress || salesDataUpload.status !== 'Uploaded'}
                                        startIcon={<SaveOutlined />}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item md={1}>
                                    <Tooltip title="Clear current mappings and auto-detect fields.">
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={resetMapping || salesDataUpload.status === 'Completed'}
                                            disabled={saveInProgress || salesDataUpload.status !== 'Uploaded'}
                                            startIcon={<CachedOutlined />}
                                        >
                                            Reset
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </>
                        )}

                        <Grid item md={1}>
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<CloseOutlined />}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item md={1}></Grid>
                        {isAdmin && (
                            <Grid item md={4}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={
                                        mappingDirty ||
                                        (salesDataUpload.status !== 'Uploaded' &&
                                            salesDataUpload.status !== 'Failed') ||
                                        salesDataUpload.amcInstanceId === null
                                    }
                                    onClick={showAMCDialog}
                                    startIcon={<SendOutlined />}
                                >
                                    Send to AMC
                                </Button>
                            </Grid>
                        )}
                        <Grid item md={1}>
                            {salesDataUpload.status === 'Uploaded' && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteOutlined />}
                                    onClick={() => deleteSalesData()}
                                >
                                    Delete
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            {showDialog && (
                <SendToAMCDialog
                    dealerId={salesDataUpload.dealerId}
                    open={showDialog}
                    onCancel={closeAMCDialog}
                    onClose={handleSendToAMC}
                />
            )}
        </Grid>
    );
}
