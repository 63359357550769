import { ChangeEvent, FormEvent, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Agency } from '../../types/Agency';
import ApiService from '../../ApiService';
import Heading from '../../components/Heading';
import {
    AlertColor,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link as MuiLink,
    MenuItem,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';

import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import {
    CancelOutlined,
    CloseOutlined,
    CloudUploadOutlined,
    InfoOutlined,
    KeyOutlined,
    SaveOutlined,
} from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { timezones } from '../../types/Date';
import { Dealer } from '../../types/Dealer';
import QuickSightDashboardSelector from '../../components/QuickSightDashboardSelector';
import TabPanel from '../../components/TabPanel';
import { DspEntity } from '../../types/DspEntity';
import { SasAccount } from '../../types/SasAccount';
import Utils from '../../components/Utils';
import AccessGuard from '../../components/AccessGuard';
import AgencyGatewayAPI from './AgencyGatewayAPI';
import { USER_GROUP_ADMIN } from '../../types/User';
import ActiveStatus from '../../components/ActiveStatus';
import CDGrid from '../../components/CDGrid';
import CampaignFeesComponent from '../campaignManagerV2/campaignDetailView/CampaignFeesComponent';
import { CampaignFee } from '../../types/Campaign';
import FirstPartyDataIntegration from './FirstPartyDataIntegrationTab';
import { makeStyles } from '@mui/styles';

export type AgencyUpdateCallback = (name: string, changes: Partial<Agency>) => void;

export default function AgencyEditPage() {
    const params = useParams();
    const id = params.id ? +params.id : null;
    const [agency, setAgency] = useState<Agency>(new Agency());
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [selectedDealers, setSelectedDealers] = useState<Dealer[]>([]);
    const [dealerIds, setDealerIds] = useState<number[]>([]);
    const [dspEntities, setDspEntities] = useState<DspEntity[]>([]);
    const [dspEntityIds, setDspEntityIds] = useState<number[]>([]);
    const [sasAccounts, setSasAccounts] = useState<SasAccount[]>([]);
    const [sasAccountIds, setSasAccountIds] = useState<number[]>([]);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [isTermAccepted, setIsTermAccepted] = useState<boolean>(false);

    const useStyles = makeStyles({
        section: {
            padding: '15px',
            borderRadius: '6px',
            border: '1px solid #e0e0e0',
            position: 'relative',
            '& h6': {
                color: '#777',
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '13px',
                position: 'absolute',
                top: '-11px',
                background: '#fff',
                left: '6px',
                padding: '0 5px',
                backgroundColor: '#fff',
            },
        },
    });
    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState(0);

    const TABS = {
        AGENCY: 0,
        ADVERTISERS: 1,
        CAMPAIGN_PARAMETERS: 2,
        FEES: 3,
        FIRST_PARTY_DATA: 4,
    };
    useEffect(() => {
        if (id) {
            ApiService.getAgencyAvailableDealers(id).then((response) => {
                setDealers(response.data);
            });
            ApiService.getAgency(id)
                .then((response) => {
                    let agency = response.data;
                    setAgency(agency);

                    setDealerIds((dealerIds) => {
                        const updatedDealerIds = [...dealerIds];
                        agency.dealers.forEach((dealer) => {
                            updatedDealerIds.push(dealer.id as number);
                        });
                        return updatedDealerIds;
                    });
                    const dspEntityIds: number[] = [];
                    agency.dspEntities.forEach((dspEntity) => {
                        dspEntityIds.push(dspEntity.id);
                    });
                    setDspEntityIds(dspEntityIds);

                    const sasAccountIds: number[] = [];
                    agency.sasAccounts.forEach((sasAccount) => {
                        sasAccountIds.push(sasAccount.id);
                    });
                    setSasAccountIds(sasAccountIds);
                })
                .catch(() => {});
        } else {
            ApiService.getAgencyAvailableDealers(-1).then((response) => {
                setDealers(response.data);
            });
        }
        ApiService.getDspEntities().then((response) => {
            setDspEntities(response.data);
        });
        ApiService.getSasAccounts().then((response) => {
            setSasAccounts(response.data);
        });
    }, [id]);

    useEffect(() => {
        let selectedDealers = dealers.filter((d: Dealer) => dealerIds.includes(d.id ? d.id : -1));
        setSelectedDealers(selectedDealers);
    }, [dealerIds, dealers]);

    function selectTab(_event: SyntheticEvent, value: any) {
        setSelectedTab(value);
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        agency.dealers = [];
        dealerIds.forEach((id) => {
            let dealer = dealers.find((dealer) => dealer.id === id);
            if (dealer) {
                agency.dealers.push(dealer);
            }
        });
        agency.dspEntities = [];
        dspEntityIds.forEach((id) => {
            agency.dspEntities.push({ id: id, entityId: '', entityName: '' });
        });

        agency.sasAccounts = [];
        sasAccountIds.forEach((id) => {
            agency.sasAccounts.push({ id: id, accountName: '' });
        });

        ApiService.updateAgency(agency)
            .then((response) => {
                setAgency(response.data);
                showInfoMessage('success', 'Agency has been updated.');
            })
            .catch(() => {});
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const field = event.target.name;
        if (
            [
                'activeStatus',
                'activeCampaigns',
                'activeReporting',
                'activeAttribution',
                'activeData',
                'activeApiDocs',
                'activeAdvertisers',
                'activeCrmUpload',
                'activeInsights',
                'apiIncludeCostMetrics',
                'needsPolkAudiences',
                'polkSalesIsActive',
            ].includes(field)
        ) {
            setAgency({
                ...agency,
                [field]: event.target.checked,
            });
        } else {
            setAgency({
                ...agency,
                [field]: event.target.value,
            });
        }
    }

    function onFileChange(fileType: string, event: any) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('fileType', fileType);
        formData.append('file', file, file.name);
        ApiService.uploadAgencyImage(formData)
            .then((response) => {
                setAgency({ ...agency, [fileType]: response.data });
            })
            .catch(() => {});
    }

    function handleDealerChange(event: ChangeEvent<HTMLInputElement>) {
        if (Array.isArray(event.target.value)) {
            setDealerIds(event.target.value);
        }
    }

    function renderSelectedDealers(value: unknown): ReactNode {
        let selected: string[] = [];
        let values = value as number[];
        values.forEach((id) => {
            let dealer = dealers.find((dealer) => dealer.id === id);
            if (dealer) {
                selected.push(dealer.dealerName);
            }
        });
        return <span>{selected.join(', ')}</span>;
    }

    function handleDspEntityChange(event: ChangeEvent<HTMLInputElement>) {
        if (Array.isArray(event.target.value)) {
            setDspEntityIds(event.target.value);
        }
    }

    function renderSelectedDspEntities(value: unknown): ReactNode {
        let selected: string[] = [];
        let values = value as number[];
        values.forEach((id) => {
            let dspEntity = dspEntities.find((dspEntity) => dspEntity.id === id);
            if (dspEntity) {
                selected.push(dspEntity.entityName);
            }
        });
        return <span>{selected.join(', ')}</span>;
    }

    function handleSasAccountChange(event: ChangeEvent<HTMLInputElement>) {
        if (Array.isArray(event.target.value)) {
            setSasAccountIds(event.target.value);
        }
    }

    const handleIsTermAccepted = useCallback(
        (event: any | ChangeEvent): void => {
            setIsTermAccepted(event?.target?.checked);

            if (!event?.target?.checked) {
                setAgency({
                    ...agency,
                    polkSalesIsActive: event.target.checked,
                });
            }
        },
        [agency]
    );

    function renderSelectedSasAccounts(value: unknown): ReactNode {
        let selected: string[] = [];
        let values = value as number[];
        values.forEach((id) => {
            let sasAccount = sasAccounts.find((sasAccount) => sasAccount.id === id);
            if (sasAccount) {
                selected.push(sasAccount.accountName);
            }
        });
        return <span>{selected.join(', ')}</span>;
    }

    function generateApiKey() {
        const guid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        setApiKey(guid);
    }

    function setApiKey(value: string | null) {
        setAgency({ ...agency, apiKey: value });
    }

    function modifyAgency(update: Partial<Agency>) {
        setAgency({ ...agency, ...update });
    }

    const handleAgencyDetailsUpdate: AgencyUpdateCallback = (name, update) => {
        modifyAgency(update);
    };

    let action = agency.apiKey ? (
        <Tooltip title="Remove API Key">
            <IconButton onClick={() => setApiKey('')}>
                <CancelOutlined />
            </IconButton>
        </Tooltip>
    ) : (
        <Tooltip title="Generate API Key">
            <IconButton onClick={generateApiKey}>
                <KeyOutlined />
            </IconButton>
        </Tooltip>
    );
    return (
        <>
            <Heading>{Utils.includeSeparator('Agency', ':', agency.name)}</Heading>
            <Box sx={{ minHeight: '100%', p: 1 }}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={selectTab}>
                            <Tab value={TABS.AGENCY} label="Settings" />
                            <Tab value={TABS.ADVERTISERS} label="Advertisers" />
                            <Tab value={TABS.FEES} label="Fees" />
                            <Tab value={TABS.CAMPAIGN_PARAMETERS} label="Campaign UTM Parameters" />
                            <Tab value={TABS.FIRST_PARTY_DATA} label="CRM FTP Setup" />
                        </Tabs>
                    </Box>

                    <TabPanel value={selectedTab} index={TABS.AGENCY}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField
                                    name="name"
                                    label="Agency Name"
                                    value={agency.name}
                                    required={true}
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    name="hostname"
                                    label="Portal Hostname"
                                    value={agency.hostname}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={2} />

                            <Grid item xs={7}>
                                <TextField
                                    name="crmDataNotifications"
                                    label="CRM Data Notifications"
                                    value={agency.crmDataNotifications}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Tooltip title="Send CRM Data notifications to these recipients instead of the Advertiser.  Separate multiple email addresses with a comma.">
                                                    <InfoOutlined color="primary" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={3} />

                            <Grid item xs={7}>
                                <TextField
                                    name="logoUrl"
                                    fullWidth={true}
                                    size="small"
                                    label="Logo URL"
                                    onChange={handleChange}
                                    variant="outlined"
                                    value={agency.logoUrl}
                                    helperText="Preferred horizontal format similar to size = 800px wide by 100 px tall"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <InputLabel htmlFor="btn-uploadLogo">
                                                    <input
                                                        id="btn-uploadLogo"
                                                        name="btn-uploadLogo"
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                        type="file"
                                                        accept=".png,.jpg"
                                                        onChange={(event) => onFileChange('logoUrl', event)}
                                                    />
                                                    <Tooltip title="Upload Agency Logo image">
                                                        <CloudUploadOutlined
                                                            color="primary"
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </InputLabel>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <a href={agency.logoUrl} target="new">
                                    <img style={{ height: '40px' }} src={agency.logoUrl} alt="" />
                                </a>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    name="iconUrl"
                                    fullWidth={true}
                                    size="small"
                                    label="Favorite Icon"
                                    onChange={handleChange}
                                    variant="outlined"
                                    value={agency.iconUrl}
                                    helperText="Preferred size = 32x32px"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <InputLabel htmlFor="btn-uploadBadge">
                                                    <input
                                                        id="btn-uploadBadge"
                                                        name="btn-uploadBadge"
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                        type="file"
                                                        accept=".png,.jpg"
                                                        onChange={(event) => onFileChange('iconUrl', event)}
                                                    />
                                                    <Tooltip title="Upload Favorite Icon">
                                                        <CloudUploadOutlined
                                                            color="primary"
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </InputLabel>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <a href={agency.iconUrl} target="new">
                                    <img style={{ height: '40px' }} src={agency.iconUrl} alt="" />
                                </a>
                            </Grid>

                            <Grid item xs={7}>
                                <TextField
                                    name="apiKey"
                                    fullWidth={true}
                                    size="small"
                                    label="API Key"
                                    variant="outlined"
                                    value={agency.apiKey}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{action}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={agency.apiIncludeCostMetrics}
                                            name="apiIncludeCostMetrics"
                                            onChange={handleChange}
                                        />
                                    }
                                    label="Include Cost Metrics"
                                />
                            </Grid>

                            <Grid item xs={7} />

                            <Grid item md={7}>
                                <QuickSightDashboardSelector
                                    label="Select a Dashboard"
                                    dashboardId={agency.dashboardId}
                                    onChange={(value) =>
                                        setAgency({
                                            ...agency,
                                            dashboardId: value,
                                        })
                                    }
                                />
                            </Grid>

                            <Grid item xs={7} />

                            <Grid item xs={7}>
                                <TextField
                                    fullWidth
                                    label="Available DSP Entities"
                                    name="dspEntities"
                                    value={dspEntityIds}
                                    variant="outlined"
                                    required={true}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    select
                                    SelectProps={{
                                        multiple: true,
                                        renderValue: renderSelectedDspEntities,
                                    }}
                                    onChange={handleDspEntityChange}
                                >
                                    {dspEntities.map((dspEntity) => {
                                        return (
                                            <MenuItem key={dspEntity.id} value={dspEntity.id}>
                                                <Checkbox
                                                    checked={dspEntityIds.find((d) => d === dspEntity.id) !== undefined}
                                                />
                                                {dspEntity.entityName}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={7} />

                            <Grid item xs={7}>
                                <TextField
                                    fullWidth
                                    label="Available AAS Accounts"
                                    name="sasAccounts"
                                    value={sasAccountIds}
                                    variant="outlined"
                                    required={true}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    select
                                    SelectProps={{
                                        multiple: true,
                                        renderValue: renderSelectedSasAccounts,
                                    }}
                                    onChange={handleSasAccountChange}
                                >
                                    {sasAccounts.map((sasAccount) => {
                                        return (
                                            <MenuItem key={sasAccount.id} value={sasAccount.id}>
                                                <Checkbox
                                                    checked={
                                                        sasAccountIds.find((d) => d === sasAccount.id) !== undefined
                                                    }
                                                />
                                                {sasAccount.accountName}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Grid>

                            <Grid item xs={7} />

                            <Grid item md={7}>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        name="timezone"
                                        label="Amazon DSP Timezone"
                                        select
                                        size="small"
                                        SelectProps={{ native: false }}
                                        variant="outlined"
                                        value={agency.timezone || ''}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleChange}
                                    >
                                        {timezones.map((timezone) => {
                                            return (
                                                <MenuItem key={timezone} value={timezone}>
                                                    {timezone.replaceAll('_', ' ')}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </FormControl>
                            </Grid>

                            <Grid item xs={7} />

                            <Grid item xs={7}>
                                <Grid item className={classes.section}>
                                    <Typography variant="h6">Polk Audiences</Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={agency.needsPolkAudiences}
                                                name="needsPolkAudiences"
                                                onChange={handleChange}
                                            />
                                        }
                                        label={<ActiveStatus activeStatus={agency.needsPolkAudiences} />}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid item className={classes.section}>
                                    <Typography variant="h6">Polk Sales</Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={agency.polkSalesIsActive}
                                                name="polkSalesIsActive"
                                                onChange={handleChange}
                                                disabled={!isTermAccepted && !agency.polkSalesIsActive}
                                            />
                                        }
                                        label={<ActiveStatus activeStatus={agency.polkSalesIsActive} />}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handleIsTermAccepted}
                                                defaultChecked={agency.polkSalesIsActive}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2">
                                                By using Cognition's platform you are agreeing to our{' '}
                                                <MuiLink
                                                    href="//www.cognitionads.com/terms-of-service"
                                                    target="new"
                                                    color="primary"
                                                >
                                                    terms of service
                                                </MuiLink>
                                                .
                                            </Typography>
                                        }
                                        sx={{ mx: 0, px: 2 }}
                                    />
                                </Grid>
                            </Grid>
                            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                <Grid item xs={7}>
                                    <Grid item className={classes.section}>
                                        <Typography variant="h6">Access Permissions</Typography>
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Campaigns">
                                                        <Switch
                                                            checked={agency.activeCampaigns}
                                                            onChange={handleChange}
                                                            name={'activeCampaigns'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Campaigns"
                                        />
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Reporting">
                                                        <Switch
                                                            checked={agency.activeReporting}
                                                            onChange={handleChange}
                                                            name={'activeReporting'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Reporting"
                                        />
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Attribution">
                                                        <Switch
                                                            checked={agency.activeAttribution}
                                                            onChange={handleChange}
                                                            name={'activeAttribution'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Attribution"
                                        />
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Advertisers">
                                                        <Switch
                                                            checked={agency.activeAdvertisers}
                                                            onChange={handleChange}
                                                            name={'activeAdvertisers'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Advertisers"
                                        />
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="CRM Upload">
                                                        <Switch
                                                            checked={agency.activeCrmUpload}
                                                            onChange={handleChange}
                                                            name={'activeCrmUpload'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="CRM Upload"
                                        />
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Data">
                                                        <Switch
                                                            checked={agency.activeData}
                                                            onChange={handleChange}
                                                            name={'activeData'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Data"
                                        />
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="API Docs">
                                                        <Switch
                                                            checked={agency.activeApiDocs}
                                                            onChange={handleChange}
                                                            name={'activeApiDocs'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="API Docs"
                                        />
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Insights">
                                                        <Switch
                                                            checked={agency.activeInsights}
                                                            onChange={handleChange}
                                                            name={'activeInsights'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Insights"
                                        />
                                    </Grid>
                                </Grid>
                            </AccessGuard>
                            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                <Grid item xs={7}>
                                    <Grid item className={classes.section}>
                                        <Typography variant="h6">Status </Typography>
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Set the Active Status for this Agency.  Inactivating hides this Agency from selection for Advertiser assignment, Pacing Report, etc.">
                                                        <Switch
                                                            checked={agency.activeStatus}
                                                            onChange={handleChange}
                                                            name={'activeStatus'}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label={<ActiveStatus activeStatus={agency.activeStatus} />}
                                        />
                                    </Grid>
                                </Grid>
                            </AccessGuard>
                            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                <Grid item xs={7}>
                                    <Grid item className={classes.section}>
                                        <AgencyGatewayAPI agency={agency} onAgencyUpdate={handleAgencyDetailsUpdate} />
                                    </Grid>
                                </Grid>
                            </AccessGuard>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={selectedTab} index={TABS.ADVERTISERS}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                {dealers.length > 0 && (
                                    <>
                                        <TextField
                                            fullWidth
                                            label="Advertisers"
                                            name="dealers"
                                            value={dealerIds}
                                            variant="outlined"
                                            required={true}
                                            size="small"
                                            select
                                            SelectProps={{
                                                multiple: true,
                                                renderValue: renderSelectedDealers,
                                            }}
                                            onChange={handleDealerChange}
                                        >
                                            {dealers.map((dealer) => {
                                                return (
                                                    <MenuItem key={dealer.id} value={dealer.id}>
                                                        <Checkbox
                                                            checked={
                                                                dealerIds.find((d) => d === dealer.id) !== undefined
                                                            }
                                                        />
                                                        {dealer.dealerName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </>
                                )}
                            </Grid>
                            <Grid item md={6} />

                            <Grid item md={6}>
                                <CDGrid
                                    columns={[
                                        {
                                            field: 'dealerName',
                                            headerName: 'Advertiser',
                                            width: 200,
                                        },
                                    ]}
                                    rows={selectedDealers}
                                />
                            </Grid>
                            <Grid item md={6} />
                        </Grid>
                    </TabPanel>

                    <TabPanel index={TABS.FEES} value={selectedTab}>
                        <CampaignFeesComponent
                            agencyView={true}
                            editMode={true}
                            allowEditToggle={false}
                            campaignFee={agency.campaignFee ? agency.campaignFee : new CampaignFee()}
                            onChange={(campaignFee: CampaignFee) => {
                                setAgency((prev) => {
                                    return { ...prev, campaignFee: campaignFee };
                                });
                            }}
                        />
                    </TabPanel>

                    <TabPanel value={selectedTab} index={TABS.CAMPAIGN_PARAMETERS}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="cpTemplate"
                                    label="Campaign Parameter URL"
                                    value={agency.cpTemplate}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    helperText="Only required for Cognition Digital Agency"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    name="cpSource"
                                    label="Source"
                                    value={agency.cpSource}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="cpMedium"
                                    label="Medium"
                                    value={agency.cpMedium}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="cpCampaign"
                                    label="Campaign"
                                    value={agency.cpCampaign}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="cpTerm"
                                    label="Term"
                                    value={agency.cpTerm}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="cpContent"
                                    label="Content"
                                    value={agency.cpContent}
                                    required={false}
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={4} />
                        </Grid>
                    </TabPanel>

                    <TabPanel value={selectedTab} index={TABS.FIRST_PARTY_DATA}>
                        <FirstPartyDataIntegration
                            agency={agency}
                            onChange={(value) => setAgency({ ...agency, ...value })}
                        />
                    </TabPanel>

                    <Grid container spacing={2}>
                        <Grid item md={12} style={{ marginTop: '30px' }}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disableFocusRipple
                                    variant="contained"
                                    startIcon={<SaveOutlined />}
                                >
                                    Save
                                </Button>
                                <Link to="/agencies">
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        disableFocusRipple
                                        startIcon={<CloseOutlined />}
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <InfoMessage {...infoMessage} />
        </>
    );
}
