import { Dealer } from '../../types/Dealer';
import { useEffect, useMemo, useState } from 'react';
import { PolkDealer, PolkDealerDealer } from '../../types/Polk';
import ApiService from '../../ApiService';
import { gridClasses, GridColumns, GridValueGetterParams, GridToolbarContainer } from '@mui/x-data-grid';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import Column from '../../components/Column';
import PolkDealerMappingDrawer from './PolkDealerMappingDrawer';
import CDGrid from '../../components/CDGrid';
import Utils from '../../components/Utils';
import theme from '../../theme';
import useGridColumnVisibilityModel from '../../hooks/useGridColumnVisibilityModel';

export default function PolkDealerMapping(props: { dealer: Dealer }) {
    const { dealer } = props;

    const [polkDealerDealers, setPolkDealerDealers] = useState<PolkDealerDealer[]>([]);
    const [showAdvertiserDealerMapping, setShowAdvertiserDealerMapping] = useState(false);
    const [showCompetitorDealerMapping, setShowCompetitorDealerMapping] = useState(false);
    const polkDealerAdvertiserDealers = useMemo(() => {
        return polkDealerDealers.filter((_dealer) => _dealer.isCompetitor === false);
    }, [polkDealerDealers]);
    const polkDealerCompetitorDealers = useMemo(() => {
        return polkDealerDealers.filter((_dealer) => _dealer.isCompetitor === true);
    }, [polkDealerDealers]);

    useEffect(() => {
        if (dealer.id) {
            ApiService.getPolkDealerDealers(dealer.id).then((response) => {
                setPolkDealerDealers(response.data);
            });
        }
    }, [dealer]);

    function saveDealers(polkDealers: PolkDealerDealer[]) {
        if (dealer.id) {
            ApiService.updatePolkDealerDealers(dealer.id, polkDealers).then(() => {});
        }
    }

    const dealerColumns: GridColumns = [
        {
            headerName: 'Dealer',
            field: 'dealerName',
            width: 200,
            flex: 2,
            valueGetter: (params: GridValueGetterParams) => params.row?.polkDealer?.dealerName,
        },
        {
            headerName: 'Address',
            field: 'address',
            width: 200,
            flex: 2,
            valueGetter: (params: GridValueGetterParams) => params.row?.polkDealer?.address,
        },
        {
            headerName: 'City',
            field: 'city',
            width: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => params.row?.polkDealer?.city,
        },
        {
            headerName: 'State',
            field: 'state',
            width: 50,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => params.row?.polkDealer?.state,
        },
        {
            hide: true,
            headerName: Utils.formatDate(Utils.getMonthStart(-2), 'MMMM'),
            field: 'priorMonthSales',
            align: 'right',
            headerAlign: 'right',
            valueGetter: (params: GridValueGetterParams) => params.row?.polkDealer?.priorMonthSales,
        },
        {
            hide: true,
            headerName: Utils.formatDate(Utils.getMonthStart(-1), 'MMMM'),
            field: 'lastMonthSales',
            align: 'right',
            headerAlign: 'right',
            valueGetter: (params: GridValueGetterParams) => params.row?.polkDealer?.lastMonthSales,
        },
    ];

    function AdvertiserToolbar() {
        return (
            <GridToolbarContainer sx={{ padding: 2 }} className={gridClasses.toolbarContainer}>
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1">Advertisers</Typography>
                    <Typography variant="subtitle2">
                        Add Polk Advertiser name(s) that match the Cognition Advertiser name.
                    </Typography>
                </div>

                <Tooltip title={polkDealerDealers.length > 0 ? 'Update Advertiser(s)' : 'Add Advertiser(s)'}>
                    <Button onClick={() => setShowAdvertiserDealerMapping(true)} variant="contained" color="primary">
                        {polkDealerAdvertiserDealers.length > 0 ? 'Update Advertiser(s)' : 'Add Advertiser(s)'}
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    function CompetitorToolbar() {
        return (
            <GridToolbarContainer sx={{ padding: 2 }} className={gridClasses.toolbarContainer}>
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1">Competitors</Typography>
                    <Typography variant="subtitle2">
                        Add Polk Advertiser name(s) that match the Cognition Advertiser's Competitor name(s).
                    </Typography>
                </div>

                <Tooltip title={polkDealerCompetitorDealers.length > 0 ? 'Update Competitor(s)' : 'Add Competitor(s)'}>
                    <Button onClick={() => setShowCompetitorDealerMapping(true)} variant="contained" color="primary">
                        {polkDealerCompetitorDealers.length > 0 ? 'Update Competitor(s)' : 'Add Competitor(s)'}
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    function AdvertiserNoRowsOverlay() {
        return (
            <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '144px', padding: 2 }}>
                <Typography variant="subtitle1">No Advertiser(s) assigned</Typography>
                <Typography variant="subtitle2">Click Add Advertiser(s) button to setup.</Typography>
            </Stack>
        );
    }

    function CompetitorNoRowsOverlay() {
        return (
            <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '144px', padding: 2 }}>
                <Typography variant="subtitle1">No Competitor(s) assigned</Typography>
                <Typography variant="subtitle2">Click Add Competitor(s) button to setup.</Typography>
            </Stack>
        );
    }

    const advertiserGridColumnVisibility = useGridColumnVisibilityModel({
        id: 'polkDealer.advertiserGrid',
    });

    const competitorGridColumnVisibility = useGridColumnVisibilityModel({
        id: 'polkDealer.competitorGrid',
    });

    return (
        <Stack direction="column" gap={4}>
            <Column
                sx={{
                    height: polkDealerAdvertiserDealers.length > 0 ? 'auto' : '35vh',
                    width: '100%',
                    flexGrow: 1,
                }}
            >
                <PolkDealerMappingDrawer
                    mappingType="advertiser"
                    dealer={dealer}
                    defaultSelectedDealers={polkDealerAdvertiserDealers.map(
                        (_polkDealerDealer: PolkDealerDealer) => _polkDealerDealer.polkDealer
                    )}
                    onClose={(items, action) => {
                        setShowAdvertiserDealerMapping(false);

                        if (action === 'save') {
                            let polkDealers = [...polkDealerDealers];

                            items.forEach((item) => {
                                const duplicate = polkDealers.find((pd) => pd.polkDealer.id === item.id);

                                if (!duplicate) {
                                    polkDealers.push({
                                        dealer: dealer,
                                        isCompetitor: false,
                                        polkDealer: item,
                                    });
                                }
                            });

                            polkDealers = polkDealers.filter((polkDealer) => {
                                return (
                                    polkDealer.isCompetitor === true ||
                                    items.find((_item) => _item.id === polkDealer.polkDealer.id) !== undefined
                                );
                            });

                            setPolkDealerDealers(polkDealers);
                            saveDealers(polkDealers);
                        }
                    }}
                    onSearch={(_polkDealers: PolkDealer[], setPolkDealers: Function) => {
                        setPolkDealers(
                            _polkDealers.filter((_polkDealer) => {
                                const _polkDealerCompetitorDealer = polkDealerCompetitorDealers.find(
                                    (pd) => _polkDealer.id === pd.polkDealer.id
                                );

                                return _polkDealerCompetitorDealer === undefined;
                            })
                        );
                    }}
                    open={showAdvertiserDealerMapping}
                />

                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <CDGrid
                        columnVisibilityModel={advertiserGridColumnVisibility.columnVisibilityModel}
                        onColumnVisibilityModelChange={advertiserGridColumnVisibility.onColumnVisibilityModelChange}
                        autoHeight={polkDealerAdvertiserDealers.length > 0 ? true : false}
                        autoPageSize={polkDealerAdvertiserDealers.length > 0 ? false : true}
                        pageSize={10}
                        getRowId={(row) => row.polkDealer.id}
                        columns={dealerColumns}
                        rows={polkDealerAdvertiserDealers}
                        components={{
                            Toolbar: AdvertiserToolbar,
                            NoRowsOverlay: AdvertiserNoRowsOverlay,
                        }}
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                px: 2,
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: `1px solid ${theme.palette.divider}`,
                                px: 2,
                            },
                        }}
                    />
                </Box>
            </Column>

            <Column
                sx={{
                    height: polkDealerCompetitorDealers.length > 0 ? 'auto' : '35vh',
                    width: '100%',
                    flexGrow: 1,
                }}
            >
                <PolkDealerMappingDrawer
                    mappingType="competitor"
                    dealer={dealer}
                    defaultSelectedDealers={polkDealerCompetitorDealers.map(
                        (_polkDealerDealer: PolkDealerDealer) => _polkDealerDealer.polkDealer
                    )}
                    onClose={(items, action) => {
                        setShowCompetitorDealerMapping(false);

                        if (action === 'save') {
                            let polkDealers = [...polkDealerDealers];

                            items.forEach((item) => {
                                const polkDealer = polkDealers.find((pd) => pd.polkDealer.id === item.id);

                                if (polkDealer !== undefined) {
                                    polkDealer.isCompetitor = true;
                                } else {
                                    polkDealers.push({
                                        dealer: dealer,
                                        isCompetitor: true,
                                        polkDealer: item,
                                    });
                                }
                            });

                            polkDealers = polkDealers.filter((polkDealer) => {
                                return (
                                    polkDealer.isCompetitor === false ||
                                    items.find((_item) => _item.id === polkDealer.polkDealer.id) !== undefined
                                );
                            });

                            setPolkDealerDealers(polkDealers);
                            saveDealers(polkDealers);
                        }
                    }}
                    onSearch={(_polkDealers: PolkDealer[], setPolkDealers: Function) => {
                        setPolkDealers(
                            _polkDealers.filter((_polkDealer) => {
                                const _polkDealerAdvertiserDealer = polkDealerAdvertiserDealers.find(
                                    (pd) => _polkDealer.id === pd.polkDealer.id
                                );

                                return _polkDealerAdvertiserDealer === undefined;
                            })
                        );
                    }}
                    open={showCompetitorDealerMapping}
                />

                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <CDGrid
                        columnVisibilityModel={competitorGridColumnVisibility.columnVisibilityModel}
                        onColumnVisibilityModelChange={competitorGridColumnVisibility.onColumnVisibilityModelChange}
                        autoHeight={polkDealerCompetitorDealers.length > 0 ? true : false}
                        autoPageSize={polkDealerCompetitorDealers.length > 0 ? false : true}
                        pageSize={10}
                        getRowId={(row) => row.polkDealer.id}
                        columns={dealerColumns}
                        rows={polkDealerCompetitorDealers}
                        components={{
                            Toolbar: CompetitorToolbar,
                            NoRowsOverlay: CompetitorNoRowsOverlay,
                        }}
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                px: 2,
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: `1px solid ${theme.palette.divider}`,
                                px: 2,
                            },
                        }}
                    />
                </Box>
            </Column>
        </Stack>
    );
}
