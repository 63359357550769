import Heading from '../../components/Heading';
import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, FormControlLabel, InputAdornment, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AddOutlined, ClearOutlined, SearchOutlined } from '@mui/icons-material';
import ApiService from '../../ApiService';
import { Agency } from '../../types/Agency';
import ActiveStatus from '../../components/ActiveStatus';
import CDGrid from '../../components/CDGrid';

export default function AgencyListPage() {
    const [agencies, setAgencies] = useState<Agency[]>([]);
    const [allAgencies, setAllAgencies] = useState<Agency[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [activeStatusFilter, setActiveStatusFilter] = useState('active');
    const navigate = useNavigate();

    useEffect(() => {
        ApiService.getAgencies()
            .then(function (response) {
                setAllAgencies(response.data);
                setAgencies(response.data);
                filterRows(response.data, '', 'active');
            })
            .catch(() => {
                setAgencies([]);
            });
    }, []);

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
        const status = event.target.value;
        setActiveStatusFilter(status);
        filterRows(allAgencies, searchText, status);
    };

    function clearSearch() {
        setSearchText('');
        filterRows(allAgencies, '', activeStatusFilter);
    }

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        filterRows(allAgencies, event.target.value, activeStatusFilter);
    }

    function filterRows(agencies: Agency[], searchText: string, activeStatus: string) {
        const filteredRows = agencies.filter((row: Agency) => {
            return (
                row.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 &&
                (activeStatus === 'all' ? true : row.activeStatus)
            );
        });
        setAgencies(filteredRows);
    }

    function CustomToolbar() {
        return (
            <Stack spacing={2} direction="row" sx={{ marginBottom: '16px' }}>
                <TextField
                    inputRef={(input) => input && input.focus()}
                    id="searchText"
                    size="small"
                    value={searchText}
                    label="Search"
                    onChange={search}
                    InputProps={{
                        startAdornment: <SearchOutlined fontSize="small" />,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button startIcon={<ClearOutlined fontSize="small" />} onClick={() => clearSearch()} />
                            </InputAdornment>
                        ),
                    }}
                />

                <RadioGroup
                    aria-label="status"
                    name="status"
                    value={activeStatusFilter}
                    onChange={handleStatusChange}
                    row
                >
                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                    <FormControlLabel value="all" control={<Radio />} label="All" />
                </RadioGroup>

                <div style={{ flexGrow: 1 }} />
                <Link to="/agencies/new">
                    <Button variant="contained" color="primary" startIcon={<AddOutlined />}>
                        Add
                    </Button>
                </Link>
            </Stack>
        );
    }

    return (
        <Box
            id="box"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Heading>Agency Partners</Heading>
            <CustomToolbar />
            <CDGrid
                autoHeight={false}
                autoPageSize={true}
                density="compact"
                columns={[
                    {
                        width: 95,
                        field: 'id',
                        type: 'number',
                        headerName: 'Id',
                        headerClassName: 'grid-header',
                    },
                    {
                        width: 250,
                        field: 'name',
                        headerName: 'Agency Name',
                        headerClassName: 'grid-header',
                    },
                    {
                        width: 200,
                        field: 'hostname',
                        headerName: 'Portal Hostname',
                        headerClassName: 'grid-header',
                        flex: 1,
                    },
                    {
                        width: 80,
                        field: 'activeStatus',
                        headerName: 'Status',
                        headerClassName: 'grid-header',
                        flex: 0.5,
                        renderCell: (params) => {
                            let activeStatus = params.value as boolean;
                            return <ActiveStatus activeStatus={activeStatus} />;
                        },
                    },
                ]}
                onRowClick={(params) => {
                    navigate(String(params.row.id));
                }}
                rows={agencies === null ? [] : agencies}
            />
        </Box>
    );
}
