import { useEffect, useRef } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Campaign } from '../../../../types/Campaign';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import ApiService from '../../../../ApiService';
import Column from '../../../../components/Column';
import Utils from '../../../../components/Utils';

type CampaignWizardSubmissionLoadingConfirmationProps = {
    campaign: Campaign;
    onLoaded?: (campaign: Campaign) => void;
};

export default function CampaignWizardSubmissionLoadingConfirmation(
    props: CampaignWizardSubmissionLoadingConfirmationProps
) {
    const { campaign, onLoaded } = props;
    const navigate = useNavigate();
    const { setCampaign, setWasRecentlyLoaded, wasRecentlyCreated, wasRecentlyUpdated } = useCampaignWizardContext();

    const intervalSeconds: number = 5;
    const maxIntervalSeconds: number = 30;
    const $currentIntervalSeconds = useRef<number>(0);
    const $hasTimedOut = useRef<boolean>(false);
    const $hasError = useRef<boolean>(false);

    const debouncedFetchCampaign = Utils.debounce(async (callback: any): Promise<void> => {
        let updatedCampaign: Campaign = campaign;

        if (campaign?.id && !campaign?.dspCampaignId) {
            const response = await ApiService.getCampaign(campaign.id);

            if (response) {
                updatedCampaign = response.data;
            }
        }

        callback(updatedCampaign);
    }, 2000);

    useEffect(() => {
        const intervalId = setInterval(async (): Promise<void> => {
            $currentIntervalSeconds.current += intervalSeconds;

            debouncedFetchCampaign((updatedCampaign: Campaign) => {
                if (updatedCampaign?.error) {
                    $hasError.current = true;
                }

                if (updatedCampaign?.dspCampaignId) {
                    clearInterval(intervalId);

                    setCampaign((prev: Campaign) => {
                        return { ...prev, ...{ dspCampaignId: updatedCampaign.dspCampaignId } };
                    });

                    setWasRecentlyLoaded(true);

                    if (onLoaded) {
                        onLoaded(updatedCampaign);
                    }
                }
            });

            const campaignId: number | undefined = campaign.campaignId ?? campaign.id;

            if ($hasError.current === true || campaign.gatewayAmazonDSPIsActive !== 2) {
                setTimeout(() => {
                    navigate(`/campaignManager/${campaignId}`, {
                        state: {
                            wasRecentlyCreated: wasRecentlyCreated,
                            wasRecentlyUpdated: wasRecentlyUpdated,
                        },
                    });
                }, 500);
            } else {
                if ($currentIntervalSeconds.current >= maxIntervalSeconds) {
                    if ($hasTimedOut.current === false) {
                        $hasTimedOut.current = true;

                        if (campaignId) {
                            await ApiService.notifyGatewayDelay(campaignId);
                        }
                    }

                    if (wasRecentlyCreated) {
                        setWasRecentlyLoaded(true);
                    } else {
                        navigate(`/campaignManager/${campaignId}`, {
                            state: {
                                wasRecentlyCreated: wasRecentlyCreated,
                                wasRecentlyUpdated: wasRecentlyUpdated,
                            },
                        });
                    }
                }
            }
        }, intervalSeconds * 1000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign, onLoaded]);

    return (
        <Box sx={{ mx: 3, my: 9 }}>
            <Column gap={3} justifyContent="center" alignItems="center" sx={{ p: 5 }}>
                <CircularProgress />

                <Column gap={1} justifyContent="center" alignItems="center">
                    <Typography variant="h6" sx={{ fontSize: 20 }}>
                        {campaign.deliveryStatusId === 'DRAFT'
                            ? 'Please wait while we create the campaign...'
                            : 'Please wait while we update the campaign...'}
                    </Typography>
                </Column>
            </Column>
        </Box>
    );
}
