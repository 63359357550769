import { Box } from '@mui/material';
import Heading from '../../components/Heading';
import { useContext, useEffect, useState } from 'react';

// import { Agency } from '../../types/Agency';
import { Dealer } from '../../types/Dealer';
import { AdvertiserContext } from '../../AdvertiserContext';

import { TABS } from '../../pages/dealers/CRMDataTab';
import CRMDataTab from '../../pages/dealers/CRMDataTab';

export default function SalesDataUploadPage() {
    const { advertiserContext } = useContext(AdvertiserContext);

    const [agencyId, setAgencyId] = useState<number | null>(null);
    const [dealer, setDealer] = useState<Dealer | null | undefined>(null);

    useEffect(() => {
        if (advertiserContext?.dealer) {
            setDealer(advertiserContext.dealer);
        }

        if (advertiserContext?.agencyId) {
            setAgencyId(advertiserContext.agencyId);
        }
    }, [advertiserContext]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Heading>CRM Data Upload</Heading>

            <CRMDataTab
                role="primary"
                agencyId={agencyId}
                dealer={dealer}
                defaultSelectedTab={TABS.UPLOAD_DATA}
                dealerDefaultSelect={false}
                showDealerSelector={true}
                showHeading={true}
            />
        </Box>
    );
}
