import { useCallback, useMemo, useRef, useState } from 'react';

import { Code } from '../types/Code';
import ApiService from '../ApiService';

export interface Codes {
    codeGroup: string;
    codes: Code[] | null;
    defaultCode: Code | null;
    fetchCodes: () => void;
    setCodes: (codes: Code[]) => void;
    getCodeDescription: (code: string | undefined) => string;
    isFetchingCodes: boolean;
    setIsFetchingCodes: (isFetchingCodes: boolean) => void;
}

export type useCodeProps = {
    codeGroup: string;
};

export default function useCodes(props: useCodeProps): Codes {
    const codeGroup = props.codeGroup;
    const [codes, setCodes] = useState<Code[] | null>(null);
    const [isFetchingCodes, setIsFetchingCodes] = useState<boolean>(false);
    const isFetched = useRef<boolean>(false);

    const fetchCodes = useCallback(() => {
        if (isFetched.current === false) {
            setIsFetchingCodes(true);

            ApiService.getCodes(codeGroup)
                .then((response) => {
                    setCodes(response.data);
                })
                .finally(() => {
                    setIsFetchingCodes(false);
                });

            isFetched.current = true;
        }
    }, [codeGroup]);

    const defaultCode = useMemo((): Code | null => {
        let code: Code | undefined;

        if (codes && codes.length > 0) {
            code = codes.find((o: Code) => o.defaultOption === true);
        }

        return code ? code : null;
    }, [codes]);

    // useEffect(() => {
    //     isFetched.current = false;
    // }, [codeGroup]);

    const getCodeDescription = (codeCode: string | undefined): string => {
        let code: Code | undefined;

        if (codes && codes.length > 0) {
            code = codes.find((o: Code) => o.code === codeCode);
        }

        return code?.description ?? '';
    };

    return {
        codeGroup,
        codes,
        defaultCode,
        fetchCodes,
        setCodes,
        getCodeDescription,
        isFetchingCodes,
        setIsFetchingCodes,
    };
}
