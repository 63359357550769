import { useCallback, useEffect, useState } from 'react';

export interface LoaderState {
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;

    loading: (requestName: string) => void;
    loaded: (requestName: string) => void;
}

export function useLoader(): LoaderState {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [loadingRequests, setLoadingRequests] = useState<Record<string, boolean>>({});

    const loading = useCallback((requestName: string): void => {
        setLoadingRequests((prev) => ({ ...prev, [requestName]: true }));
    }, []);

    const loaded = useCallback((requestName: string): void => {
        setLoadingRequests((prev) => ({ ...prev, [requestName]: false }));
    }, []);

    useEffect(() => {
        if (Object.values(loadingRequests).every((_isLoading: boolean) => _isLoading === false)) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [loadingRequests]);

    return { isLoading, setIsLoading, loading, loaded };
}
