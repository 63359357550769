import { useMemo } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';

import { ReportingCriteria } from '../../../../../types/Analytics/Reporting/ReportingCriteria';
import ReportingPerformanceConversionTable from '../Tables/ReportingPerformanceConversionTable';
import ReportingPerformanceSiteImpressionTable from '../Tables/ReportingPerformanceSiteImpressionTable';
import ReportingPerformanceSpendByApplicationTable from '../Tables/ReportingPerformanceSpendByApplicationTable';

type ReportingPerformanceOtherTabProps = {
    reportingCriteria: ReportingCriteria;
};

export default function ReportingPerformanceOtherTab(props: ReportingPerformanceOtherTabProps) {
    const { reportingCriteria } = props;

    const columnSize = useMemo((): number => {
        switch (reportingCriteria.mediaType) {
            case 'Video':
                return 4;
        }
        return 6;
    }, [reportingCriteria.mediaType]);

    return (
        <Grid container spacing={4}>
            <Grid item md={columnSize}>
                <Card variant="outlined">
                    <CardHeader
                        title={
                            <Typography variant="body2" color="text.primary">
                                Total Exposures By Top 10 Domains
                            </Typography>
                        }
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    />
                    <CardContent
                        sx={{
                            padding: 0,
                            '&:last-child': { padding: 0 },
                        }}
                    >
                        <ReportingPerformanceSiteImpressionTable reportingCriteria={reportingCriteria} />
                    </CardContent>
                </Card>
            </Grid>

            {reportingCriteria.mediaType === 'Video' && (
                <Grid item md={columnSize}>
                    <Card variant="outlined">
                        <CardHeader
                            title={
                                <Typography variant="body2" color="text.primary">
                                    Spend By Application
                                </Typography>
                            }
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        />
                        <CardContent
                            sx={{
                                padding: 0,
                                '&:last-child': { padding: 0 },
                            }}
                        >
                            <ReportingPerformanceSpendByApplicationTable reportingCriteria={reportingCriteria} />
                        </CardContent>
                    </Card>
                </Grid>
            )}

            <Grid item md={columnSize}>
                <Card variant="outlined">
                    <CardHeader
                        title={
                            <Typography variant="body2" color="text.primary">
                                On-Site Interactions
                            </Typography>
                        }
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    />
                    <CardContent
                        sx={{
                            padding: 0,
                            '&:last-child': { padding: 0 },
                        }}
                    >
                        <ReportingPerformanceConversionTable reportingCriteria={reportingCriteria} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
