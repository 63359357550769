import Heading from '../../components/Heading';
import CDGrid from '../../components/CDGrid';
import { useEffect, useState } from 'react';
import { ReleaseNote } from '../../types/ReleaseNote';
import ApiService from '../../ApiService';
import { AddOutlined, CheckCircleOutlined } from '@mui/icons-material';
import Utils from '../../components/Utils';
import { gridClasses, GridToolbarContainer } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function ReleaseNoteListPage() {
    const navigate = useNavigate();
    const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[] | null>(null);

    useEffect(() => {
        ApiService.getReleaseNotes()
            .then((response) => {
                setReleaseNotes(response.data);
            })
            .catch(() => {});
    }, []);

    function handleAdd() {
        navigate('new');
    }

    function CustomToolbar() {
        return (
            <Box sx={{ padding: '10px' }}>
                <GridToolbarContainer className={gridClasses.toolbarContainer}>
                    <div style={{ flexGrow: 1 }} />
                    <Button variant="contained" color="primary" onClick={handleAdd} startIcon={<AddOutlined />}>
                        Add
                    </Button>
                </GridToolbarContainer>
            </Box>
        );
    }

    return (
        <>
            <Heading>Release Notes</Heading>
            <CDGrid
                components={{ Toolbar: CustomToolbar }}
                columns={[
                    {
                        width: 120,
                        field: 'published',
                        headerName: 'Released',
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: (params) => {
                            return params.value ? <CheckCircleOutlined color="success" /> : <></>;
                        },
                    },
                    {
                        width: 200,
                        field: 'description',
                        headerName: 'Description',
                        flex: 1,
                    },
                    {
                        width: 150,
                        field: 'releaseDate',
                        headerName: 'Release Date',
                        valueGetter: (params) => {
                            return Utils.formatDate(params.value as Date);
                        },
                    },
                    {
                        width: 150,
                        field: 'appVersion',
                        headerName: 'App Version',
                    },
                ]}
                rows={releaseNotes ? releaseNotes : []}
                onRowClick={(params) => {
                    navigate('' + params.row.id);
                }}
            ></CDGrid>
        </>
    );
}
