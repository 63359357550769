import Heading from '../../components/Heading';
import { Box } from '@mui/material';
import DealerImportSites from '../dealers/DealerImportSites';

export default function ImportListPage() {
    return (
        <Box
            sx={{
                '& .row': {
                    cursor: 'pointer',
                },
            }}
        >
            <Heading>Import Manager</Heading>
            <DealerImportSites />
        </Box>
    );
}
