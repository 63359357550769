import { DataGridProps, GridColDef, GridValidRowModel, GridValueGetterParams } from '@mui/x-data-grid';
import { DataSetValue } from '../../types/DataSetValue';
import { SalesDataMapping } from '../../types/SalesDataMapping';
import CDGrid from '../../components/CDGrid';
import Utils from '../../components/Utils';

export const columns: any[] = [
    {
        width: 110,
        field: 'firstName',
        fieldName: 'FIRST_NAME',
        headerName: 'First Name',
        headerClassName: 'grid-header',
    },
    {
        width: 120,
        field: 'lastName',
        fieldName: 'LAST_NAME',
        headerName: 'Last Name',
        headerClassName: 'grid-header',
    },
    {
        width: 220,
        field: 'email',
        fieldName: 'EMAIL',
        headerName: 'Email',
        headerClassName: 'grid-header',
    },
    {
        width: 120,
        field: 'phoneNumber',
        fieldName: 'PHONE_NUMBER',
        headerName: 'Phone',
        headerClassName: 'grid-header',
    },
    {
        width: 120,
        field: 'saleDate',
        fieldName: 'SALES_DATE',
        headerName: 'Sale Date',
        headerClassName: 'grid-header',
        valueGetter: (params: GridValueGetterParams) => {
            return Utils.formatDate(params.value);
        },
    },
    {
        width: 150,
        field: 'address',
        fieldName: 'ADDRESS',
        headerName: 'Address',
        headerClassName: 'grid-header',
    },
    {
        width: 150,
        field: 'city',
        fieldName: 'CITY',
        headerName: 'City',
        headerClassName: 'grid-header',
    },
    {
        width: 150,
        field: 'state',
        fieldName: 'STATE',
        headerName: 'State',
        headerClassName: 'grid-header',
    },
    {
        width: 150,
        field: 'zipcode',
        fieldName: 'ZIPCODE',
        headerName: 'Zip Code',
        headerClassName: 'grid-header',
    },
    {
        width: 130,
        field: 'make',
        fieldName: 'MAKE',
        headerName: 'Make',
        headerClassName: 'grid-header',
    },
    {
        width: 150,
        field: 'model',
        fieldName: 'MODEL',
        headerName: 'Model',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'int1',
        fieldName: 'INT1',
        headerName: 'Integer 1',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'int2',
        fieldName: 'INT2',
        headerName: 'Integer 2',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'int3',
        fieldName: 'INT3',
        headerName: 'Integer 3',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'decimal1',
        fieldName: 'DECIMAL1',
        headerName: 'Decimal 1',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'decimal2',
        fieldName: 'DECIMAL2',
        headerName: 'Decimal 2',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'decimal3',
        fieldName: 'DECIMAL3',
        headerName: 'Decimal 3',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'string1',
        fieldName: 'STRING1',
        headerName: 'String 1',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'string2',
        fieldName: 'STRING2',
        headerName: 'String 2',
        headerClassName: 'grid-header',
    },
    {
        width: 100,
        field: 'string3',
        fieldName: 'STRING3',
        headerName: 'String 3',
        headerClassName: 'grid-header',
    },
];

export function getDefaultSalesDataFirstPartyDataGridColumns(): GridColDef[] {
    const _columns: GridColDef[] = [];

    const defaultColumnFields = [
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'saleDate',
        'address',
        'city',
        'state',
        'zipcode',
        'make',
        'model',
    ];

    columns.forEach((_column) => {
        const { fieldName, ...column } = _column;

        if (defaultColumnFields.includes(_column.field)) {
            _columns.push({
                ...column,
                ...{ flex: 1 },
            });
        }
    });

    return _columns;
}

export function getSalesDataFirstPartyDataGridColumns(salesDataMapping: SalesDataMapping): GridColDef[] {
    const _columns: GridColDef[] = [];

    const isSalesDataMappingColumn = (column: any): boolean => {
        const salesDataMappingField = salesDataMapping.fields.find(
            (_field) => _field.fieldPosition !== null && _field.fieldName === column.fieldName
        );

        return salesDataMappingField !== undefined;
    };

    if (salesDataMapping?.fields?.length > 0) {
        columns.forEach((_column) => {
            const { fieldName, ...column } = _column;

            if (isSalesDataMappingColumn(_column)) {
                _columns.push({
                    ...column,
                    ...{ flex: 1 },
                });
            }
        });
    }

    return _columns;
}

type SalesDataFirstPartyDataGridProps<T extends GridValidRowModel> = Omit<DataGridProps<T>, 'columns'> & {
    rows: DataSetValue[];
    columns?: GridColDef[];
} & React.RefAttributes<HTMLDivElement>;

export default function SalesDataFirstPartyDataGrid<T extends GridValidRowModel>(
    props: SalesDataFirstPartyDataGridProps<T>
) {
    const { rows, ...GridProps } = props;

    let columns: GridColDef[] = props?.columns ?? [];

    if (!columns || columns.length === 0) {
        columns = getDefaultSalesDataFirstPartyDataGridColumns();
    }

    return (
        <CDGrid
            variant="outlined"
            autoHeight={true}
            headerHeight={75}
            rowHeight={75}
            columns={columns}
            rows={rows}
            {...GridProps}
        />
    );
}
