import QuickSightDashboardSelector from '../../components/QuickSightDashboardSelector';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import ApiService from '../../ApiService';
import { Dashboard } from '../../types/Dashboard';
import { Alert, AlertColor, Button, Grid, Stack } from '@mui/material';
import { CloseOutlined, SaveOutlined } from '@mui/icons-material';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import { Link } from 'react-router-dom';
import Heading from '../../components/Heading';

export default function DefaultDashboardPage() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setDashboards] = useState<Dashboard[]>([]);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [dashboardId, setDashboardId] = useState<string | null>(null);
    const { userContext } = useContext(UserContext);
    const isAdmin = userContext.isAdmin();

    useEffect(() => {
        if (isAdmin) {
            ApiService.getQuickSightDashboards().then((response) => {
                setDashboards(response.data);
            });
        }
        ApiService.getQuickSightDashboard().then((response) => {
            setDashboardId(response.data.dashboardId);
        });
    }, [isAdmin]);

    function updateDefaultDashboard(dashboardId: string | null): void {
        setDashboardId(dashboardId);
    }

    function save() {
        ApiService.updateDefaultDashboard(dashboardId)
            .then(() => {
                showInfoMessage('success', 'Default Dashboard has been updated.');
            })
            .catch(() => {});
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    return (
        <>
            <Heading>Default QuickSight Dashboard</Heading>
            <InfoMessage {...infoMessage} />
            <Grid container>
                <Grid item xs={8}>
                    <Alert variant="filled" severity="warning" sx={{ mb: '30px' }}>
                        Warning: Changing this setting will change the dashboard shown to all users globally.
                    </Alert>
                </Grid>
                <Grid item xs={4} />

                <Grid item xs={3}>
                    <div
                        style={{
                            position: 'relative',
                            top: '-20px',
                            marginTop: '15px',
                            marginBottom: '30px',
                        }}
                    >
                        <QuickSightDashboardSelector
                            label="Default Dashboard"
                            dashboardId={dashboardId}
                            onChange={(value) => updateDefaultDashboard(value)}
                        />
                    </div>
                </Grid>

                <Grid item xs={9} />

                <Grid item xs={4}>
                    <Stack direction="row" spacing={2}>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            startIcon={<SaveOutlined />}
                            onClick={() => save()}
                        >
                            Save
                        </Button>
                        <Link to="/">
                            <Button variant="outlined" color="error" startIcon={<CloseOutlined />}>
                                Cancel
                            </Button>
                        </Link>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}
